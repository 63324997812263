import React from "react";
import {
    Modal,
    FormSelect,
    FormInput,
    Button,
} from "ui-kit-ck-consultant";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";

export default class ModalAgency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Form entries
            groupId: null,
            departmentId: 1,
            agencyName: "",
            year3: "",
            year2: "",
            year1: "",
            companyId: 1,
            // Form data
            groups: [],
            groupsSelect: [],
            departments: [],
            departmentsSelect: [],
            companiesSelect: [],
            isError: false
        }
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.getCompanies();
        this.getGroups();
        this.getDepartments();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show !== this.props.show && this.props.id) {
            this.setState({
                departmentId: this.props.departmentId,
                agencyName: this.props.agencyName,
                year3: this.props.year3,
                year2: this.props.year2,
                year1: this.props.year1,
                companyId: this.props.companyId,
                isError: false
            });
            this.getGroups();
        } else if (prevProps.show !== this.props.show && !this.props.id) {
            this.setState({
                departmentId: 1,
                agencyName: "",
                year3: "",
                year2: "",
                year1: "",
                companyId: this.state.companiesSelect[0].value,
                isError: false,
            });
            this.getGroups();
        } else if (prevState.companyId !== this.state.companyId) {
            this.getGroups();
        }
    }

    getCompanies = () => {
        axiosConfig("admin/general/get-all-companies").then(res => {
            if (res.data.success === true) {
                let companiesSelect = res.data.data.map(element => {
                    return { value: Number(element.id), text: element.name }
                });
                this.setState({ companiesSelect: companiesSelect });
            } else {
                this.setState({ companiesSelect: [] });
            }
        });
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleAddAgencyClick = () => {
        if (this.state.agencyName && this.state.groupId) {
            let data = [];
            for (let i = 0; i < 3; i++) {
                if (this.state[`year${i + 1}`] !== "") {
                  data.push({ year: `${new Date().getFullYear() - i}`, value: this.state[`year${i + 1}`] });
                }
            }
            axiosConfig({
                url: "admin/agency/add-agency",
                data: {
                    groupId: this.state.groupId,
                    name: this.state.agencyName,
                    color: "",  // to complete with new agency color
                    data: JSON.stringify(data),
                    departmentId: this.state.departmentId
                }
            }).then(res => {
                if (res.data.success === true) {
                    alert("L'agence a bien été ajoutée");
                    this.props.onClose();
                } else {
                    alert("Erreur lors de la sauvegarde");
                    this.props.onClose();
                }
            })
        } else {
            this.setState({ isError: true });
        }
    }

    handleEditAgencyClick = () => {
        if (this.state.agencyName) {
            let data = [];
            for (let i = 0; i < 3; i++) {
                if (this.state[`year${i + 1}`] !== "") {
                  data.push({ year: `${new Date().getFullYear() - i}`, value: this.state[`year${i + 1}`] });
                }
            }
            axiosConfig({
                url: "admin/agency/edit-agency",
                data: {
                    id: this.props.id,
                    name: this.state.agencyName,
                    data: JSON.stringify(data),
                    departmentId: this.state.departmentId
                }
            }).then(res => {
                if (res.data.success) {
                    alert("L'agence a bien été mise à jour");
                    this.props.onClose();
                } else {
                    alert("Erreur lors de la sauvegarde");
                    this.props.onClose();
                }
            });
        } else {
            this.setState({ isError: true });
        }
    }

    getGroups = () => {
        axiosConfig({
            url: "admin/agency/get-all-groups",
            data: {
              companyId: this.context.companyId ? this.context.companyId : this.state.companyId
            }
        }).then(res => {
            if (res.data.success === true) {
                let groups = res.data.data; 
                let groupsSelect = res.data.data.map(element => {
                    return { value: Number(element.id), text: element.name }
                });
                this.setState({ groups: groups, groupsSelect: groupsSelect }, () => {
                    if (this.props.groupId) {
                        this.setState({ groupId: this.props.groupId });
                    } else {
                        this.setState({ groupId: this.state.groups.length > 0 ? this.state.groups[0].id : null });
                    }
                });
            } else {
                this.setState({ groups: [], groupsSelect: [], groupId: null });
            }
        });
    }

    getDepartments = () => {
        axiosConfig({
            url: "admin/agency/get-all-departments"
        }).then(res => {
            if (res.data.success === true) {
                let departments = res.data.data;
                let departmentsSelect = res.data.data.map(element => {
                    return { value: Number(element.id), text: `${element.zip} - ${element.name}`}
                });
                this.setState({ departments: departments, departmentsSelect: departmentsSelect });
            } else {
                this.setState({ departments: [], departmentsSelect: [] });
            }
        });
    }

    render () {
        return (
            <Modal
                title={this.props.isEdit ? `Modifier ${this.props.agencyName}` : "Ajouter une agence"}
                show={this.props.show}
                onClose={this.props.onClose}
            >
                {this.context.companyId === null ? (
                    <FormSelect
                        className="mb-20"
                        name="companyId"
                        value={this.state.companyId}
                        options={this.state.companiesSelect} 
                        title="Compagnie"
                        onChange={this.handleInputChange}
                        ignore={true}
                        init="Veuillez sélectionner une option"
                        disabled={this.props.isEdit}
                    />
                ) : null}
                <FormSelect
                    className="mb-20"
                    name="groupId"
                    value={this.state.groupId}
                    options={this.state.groupsSelect} 
                    title="Groupe"
                    onChange={this.handleInputChange}
                    ignore={true}
                    error={this.state.isError === true && !this.state.groupId ? "Ajoutez d'abord un groupe à cette compagnie" : ""}
                    disabled={this.props.isEdit}
                />
                <FormSelect
                    className="mb-20"
                    name="departmentId"
                    value={this.state.departmentId}
                    options={this.state.departmentsSelect} 
                    title="Département"
                    onChange={this.handleInputChange}
                    ignore={true}
                />
                <FormInput
                    className="mb-20"
                    type="text"
                    name="agencyName"
                    value={this.state.agencyName}
                    title="Agence"
                    onChange={this.handleInputChange}
                    error={this.state.isError === true && !this.state.agencyName ? "Veuillez remplir ce champ" : ""}
                />
                <FormInput
                    className="mb-20"
                    type="number"
                    name="year3"
                    value={this.state.year3}
                    title={`Missions ${new Date().getFullYear() - 2}`}
                    onChange={this.handleInputChange}
                />
                <FormInput
                    className="mb-20"
                    type="number"
                    name="year2"
                    value={this.state.year2}
                    title={`Missions ${new Date().getFullYear() - 1}`}
                    onChange={this.handleInputChange}
                />
                <FormInput
                    className="mb-20"
                    type="number"
                    name="year1"
                    value={this.state.year1}
                    title={`Missions ${new Date().getFullYear()}`}
                    onChange={this.handleInputChange}
                />
                <Button
                    className="mb-4"
                    text={this.props.isEdit ? "Modifier" : "Ajouter"}
                    onClick={this.props.isEdit ? this.handleEditAgencyClick : this.handleAddAgencyClick}
                />
            </Modal>
        );
    }
}