import React from "react";
import {
  Card,
  FormSelect,
  Calendar,
  Button,
  FormSelectMultiples,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import axiosConfig from "../../../utils/axiosConfig";

export default class ShowOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yearSelect: [
        {
          value: new Date().getFullYear(),
          text: `${new Date().getFullYear()}`,
        },
        {
          value: new Date().getFullYear() - 1,
          text: `${new Date().getFullYear() - 1}`,
        },
      ],
      networkSelect: [{ value: 0, text: "Tous les réseaux" }],
      groupSelect: [{ value: 0, text: "Tous les groupes" }],
      regionSelect: [{ value: 0, text: "Toutes les régions" }],
      agencySelect: [{ value: 0, text: "Toutes les agences" }],
      kindOptions: [
        "VP",
        "CTTE",
        "4X4",
        "AC",
        "AG",
        "AGRI",
        "AGRICOLE",
        "AMBULANCE",
        "AUTO CARAV",
        "BA",
        "BC",
        "BEN AMO",
        "BENNE",
        "BOM",
        "BROYEUR",
        "CAM",
        "CAMION",
        "CARA",
        "CARAVANE",
        "CHARGEUR",
        "CITROEN",
        "CL",
        "CO",
        "CP",
        "CTE",
        "CTTT",
        "CY",
        "CYC1",
        "CYCL",
        "CYCLE",
        "CYCLO",
        "CYCLOMOTEU",
        "DI",
        "DIV",
        "DIVERS",
        "DL",
        "EDPM",
        "ENGIN",
        "EPAREUSE",
        "FAUCHEUSE",
        "FE",
        "FIAT",
        "FO",
        "FTRO",
        "FVVP",
        "GRUE",
        "IN",
        "JA",
        "KTM",
        "M1",
        "MA",
        "MAGA",
        "MAT AG",
        "MAT AGR",
        "MAT AGRE",
        "MAT FOREST",
        "MAT LEVAGE",
        "MAT NEIGE",
        "MAT TP",
        "MAT TRACT",
        "MATA GR",
        "MIA",
        "MIAR",
        "MOIS BATT",
        "MOT3",
        "MOTO",
        "MTL",
        "1,00 MTL",
        "2,00 MTL",
        "3,00 MTL",
        "MTSP",
        "MTT1",
        "MTT2",
        "MTTE",
        "MTTL",
        "NACELLE TÉ",
        "PICK UP",
        "PL",
        "QLEM",
        "QLM",
        "QM",
        "RE",
        "REA",
        "REM",
        "REMORQIE",
        "REMORQUE",
        "RESP",
        "RETC",
        "RM",
        "SANS",
        "SEMOIR",
        "SERA",
        "SERM",
        "SOLO",
        "SRAT",
        "SREA",
        "SREM",
        "SRSP",
        "SRTC",
        "TCP",
        "TM",
        "TP",
        "TQM",
        "TR",
        "TRA",
        "TRAC",
        "TRACT 2",
        "TRACTEUR",
        "TRAM",
        "TRAMWAY",
        "TROTINETTE",
        "TRR",
        "TRTE",
        "VAE",
        "VAPS",
        "VASP",
        "VDVP",
        "VELO",
        "VELO ELECT",
        "VG",
        "VL",
        "VO",
        "VOIERIE",
        "VOIRIE",
        "VT",
        "VVP",
        "VYYR",
        "X",
      ],
      kind: this.props.defaultKind || [],
      isCalendar: false,
      nameCalendar: null,
    };
  }

  componentDidMount() {
    this.getNetworkSelect();
    this.getGroupSelect();
    this.getRegionSelect();
    this.getAgencySelect();
  }

  componentDidUpdate(prevProps) {
    if (this.props.group !== prevProps.group && this.props.group !== 0) {
      this.getRegionSelect();
    } else if (
      this.props.region !== prevProps.region &&
      this.props.region !== 0
    ) {
      this.getAgencySelect();
    }
  }

  getNetworkSelect = () => {
    // TO DO
    this.setState({ networkSelect: [{ value: 1, text: "Réseau 1" }] });
  };

  getGroupSelect = () => {
    axiosConfig({
      url: "statistics/selfcare/get-groups-options",
      data: {
        networkId: this.props.network,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({
          groupSelect: [
            { value: 0, text: "Tous les groupes" },
            ...res.data.data,
          ],
        });
      } else {
        this.setState({
          groupSelect: [{ value: 0, text: "Tous les groupes" }],
        });
      }
    });
  };

  getRegionSelect = () => {
    axiosConfig({
      url: "statistics/selfcare/get-regions-options",
      data: {
        networkId: this.props.network,
        groupId: this.props.group,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({
          regionSelect: [
            { value: 0, text: "Toutes les régions" },
            ...res.data.data,
          ],
        });
      } else {
        this.setState({
          regionSelect: [{ value: 0, text: "Toutes les régions" }],
        });
      }
    });
  };

  getAgencySelect = () => {
    axiosConfig({
      url: "statistics/selfcare/get-agencies-options",
      data: {
        networkId: this.props.network,
        groupId: this.props.group,
        regionId: this.props.region,
      },
    }).then((res) => {
      if (res.data.success) {
        if (this.props.onlyBDG) {
          res.data.data = res.data.data.filter(
            (element) => element.value === 120
          );
        }
        this.setState({
          agencySelect: [
            { value: 0, text: "Toutes les agences" },
            ...res.data.data,
          ],
        });
      } else {
        this.setState({
          agencySelect: [{ value: 0, text: "Toutes les agences" }],
        });
      }
    });
  };

  getInputCalendar = (title, name) => {
    return (
      <>
        <span
          className="mt-auto"
          style={{
            display: "inline-block",
            fontSize: "14px",
            fontWeight: "bold",
            color: "var(--black)",
            marginBottom: "8px",
          }}
        >
          {title}
        </span>
        <Button
          className="mb-10"
          outline
          text={moment(this.props[name]).format("DD/MM/YYYY")}
          onClick={() => {
            this.setState({
              isCalendar: true,
              nameCalendar: name,
            });
          }}
        />
      </>
    );
  };

  handleSelectMultipleChange = (name, value) => {
    this.props.handleSelectMultipleChange(name, value);
  };

  render() {
    return (
      <>
        <Card className={this.props.className}>
          <Row>
            {this.props.isYear ? (
              <Col xs={12} md={4} lg={2}>
                <FormSelect
                  title="Année"
                  name="year"
                  value={this.props.year}
                  ignore={true}
                  options={this.state.yearSelect}
                  onChange={this.props.handleSelectChange}
                />
              </Col>
            ) : null}
            {this.props.isDateSinisterStart ? (
              <Col xs={12} md={4} lg={2}>
                {this.getInputCalendar(
                  "Date de début sinistre",
                  "dateSinisterStart"
                )}
              </Col>
            ) : null}
            {this.props.isDateSinisterEnd ? (
              <Col xs={12} md={4} lg={2}>
                {this.getInputCalendar(
                  "Date de fin sinistre",
                  "dateSinisterEnd"
                )}
              </Col>
            ) : null}
            {this.props.isDateStart ? (
              <Col xs={12} md={4} lg={2}>
                {this.getInputCalendar(
                  this.props.dateStartText || "Date de début mission",
                  "dateStart"
                )}
              </Col>
            ) : null}
            {this.props.isDateEnd ? (
              <Col xs={12} md={4} lg={2}>
                {this.getInputCalendar(
                  this.props.dateEndText || "Date de fin mission",
                  "dateEnd"
                )}
              </Col>
            ) : null}
            <Col xs={12} md={4} lg={2}>
              <FormSelect
                title="Réseau"
                name="network"
                value={this.props.network}
                ignore={true}
                options={this.state.networkSelect}
                onChange={this.props.handleSelectChange}
                className="mb-10"
              />
            </Col>
            <Col xs={12} md={4} lg={2}>
              <FormSelect
                title="Groupe"
                name="group"
                value={this.props.group}
                ignore={true}
                options={this.state.groupSelect}
                onChange={this.props.handleSelectChange}
                disabled={!this.props.network}
                className="mb-10"
              />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <FormSelect
                title="Région"
                name="region"
                value={this.props.region}
                ignore={true}
                options={this.state.regionSelect}
                onChange={this.props.handleSelectChange}
                disabled={!this.props.group}
                className="mb-10"
              />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <FormSelect
                title="Agence"
                name="agency"
                value={this.props.agency}
                ignore={true}
                options={this.state.agencySelect}
                onChange={this.props.handleSelectChange}
                disabled={!this.props.region}
                className="mb-10"
              />
            </Col>
            {this.props.isKind ? (
              <Col xs={12} md={4} lg={2}>
                <FormSelectMultiples
                  title="Genre(s) véhicule"
                  value={this.state.kind}
                  default="Tous les genres"
                  options={this.state.kindOptions.map((element) => ({
                    value: element,
                    text: element,
                  }))}
                  onChange={(value) =>
                    this.setState(
                      {
                        kind: [...value],
                      },
                      () => {
                        this.handleSelectMultipleChange("kind", value);
                      }
                    )
                  }
                />
              </Col>
            ) : null}
            <Col xs={12} md={4} lg={3} className="d-flex">
              <Button
                text="Rechercher"
                className={"mt-auto mb-10"}
                onClick={() => this.props.getData()}
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
            </Col>
          </Row>
          {this.props.footerData}
        </Card>
        <Calendar
          show={this.state.isCalendar}
          valueDate={this.props[this.state.nameCalendar]}
          isWeek
          onClose={() =>
            this.setState({
              isCalendar: false,
              nameCalendar: "",
            })
          }
          onValid={(valueDate) => {
            const nameCalendar = this.state.nameCalendar;
            this.setState(
              {
                isCalendar: false,
                nameCalendar: "",
              },
              () =>
                this.props.handleInputChange({
                  target: {
                    value: valueDate,
                    name: nameCalendar,
                  },
                })
            );
          }}
        />
      </>
    );
  }
}
