import React, { Fragment } from "react";
import {
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
  Card,
  BarChart,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";

import ContactRequest from "../../../components/general/ContactRequest";
import CardPodiumTable from "../../../components/general/CardPodiumTable";

import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";
import {
  getCurrencyValue,
  getPercentageValue,
  getValue,
} from "../../../utils/general";
import { getColors } from "../../../utils/colors";
import checkRights from "../../../utils/checkRights";

export default class Ranking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      isLoad: false,
      // filters
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 1,
      group: 1,
      region: 0,
      agency: 0,
      // data
      companiesData: [],
      // Tabs state
      tabsType: [
        { start: 0, name: "Réparable" },
        { start: 8, name: "Non réparable" },
        { start: 12, name: "RSE" },
        { start: 13, name: "Honoraire moyen" },
      ],
      tabsDetail: [
        {
          name: "Coût moyen",
          type: 0,
          key: "costReport20",
          count: "countReport20",
          format: "currency",
        },
        { name: "M.O.", type: 0, key: "hourWorkforce", format: "" },
        { name: "Pièces", type: 0, key: "costParts", format: "currency" },
        {
          name: "Tx PRE",
          type: 0,
          key: "percentagePRE",
          format: "percentage",
          reverse: true,
        },
        {
          name: "Tx EAD",
          type: 0,
          key: "percentageEAD",
          format: "percentage",
          reverse: true,
        },
        {
          name: "Tx SELFCARE",
          type: 0,
          key: "percentageSelfcare",
          format: "percentage",
          reverse: true,
        },
        {
          name: "délai mission-dépot",
          type: 0,
          key: "delayMissionClosing",
          count: "countMissionClosing",
          format: "",
        },
        {
          name: "Qualité client",
          type: 0,
          key: "satisfaction",
          count: "countSatisfaction",
          format: "",
          reverse: true,
        },
        {
          name: "VRADE",
          type: 1,
          key: "costVRADE20",
          count: "countVRADE20",
          format: "currency",
        },
        {
          name: "délai mission-SD29",
          type: 1,
          key: "delayMissionSD29",
          count: "countMissionSD29",
          format: "",
        },
        {
          name: "délai mission-dépot",
          type: 1,
          key: "delayMissionClosing",
          count: "countMissionClosing",
          format: "",
        },
        {
          name: "Qualité client",
          type: 1,
          key: "satisfaction",
          count: "countSatisfaction",
          format: "",
          reverse: true,
        },
        {
          name: "RSE",
          type: 2,
          key: "RSE",
          format: "percentage",
          reverse: true,
        },
        {
          name: "Honoraire moyen",
          type: 3,
          key: "averageHonorary",
          format: "currency",
          reverse: true,
        },
      ],
      tabsIndexType: 0,
      tabsIndexDetail: 0,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        dateSinisterEnd:
          name === "dateSinisterStart" ? value : this.state.dateSinisterEnd,
        dateStart: name === "dateSinisterStart" ? value : this.state.dateStart,
        [name]: value,
      },
    );
  };

  handleInputBlur = () => {};

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: Number(value) }, () => {
      if (name === "network") {
        this.setState({ group: 0, region: 0, agency: 0 });
      } else if (name === "group") {
        this.setState({ region: 0, agency: 0 });
      } else if (name === "region") {
        this.setState({ agency: 0 });
      }
    });
  };

  componentDidMount() {
    this.setState(
      { accessAllowed: checkRights("Ranking", this.context) },
      () => {
        if (this.state.accessAllowed) {
        }
      }
    );
  }

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("statistics/ranking/get-data", {
          data: {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
          },
        }).then((res) => {
          if (res.data.success) {
            this.setState({
              isLoad: false,
              companiesData: res.data.companies,
            });
          } else {
            this.setState({ isLoad: false, companiesData: [] });
          }
        });
      }
    );
  };

  getValue = (value, format) => {
    switch (format) {
      case "currency":
        return getCurrencyValue(value);
      case "percentage":
        return getPercentageValue(value);
      default:
        return getValue(value);
    }
  };

  getType = () => {
    if (this.state.tabsIndexType === 0) {
      return "dataR";
    } else if (this.state.tabsIndexType === 1) {
      return "dataNR";
    } else if (this.state.tabsIndexType === 2) {
      return "dataRSE";
    } else if (this.state.tabsIndexType === 3) {
      return "dataHonorary";
    }
  };

  render() {
    let now = moment(this.state.dateEnd).subtract(11, "months").clone();
    let months = [];
    while (now.isSameOrBefore(moment(this.state.dateEnd))) {
      months.push(now.format("MM/YYYY"));
      now.add(1, "months");
    }

    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Classement" className="mb-20" />
          <ContactRequest page="Classement" />
        </FadeIn>
      ) : (
        <>
          <Nav title="Classement" className="mb-20" />
          <ShowOptions
            className="mb-20"
            handleInputChange={this.handleInputChange}
            handleInputBlur={this.handleInputBlur}
            handleSelectChange={this.handleSelectChange}
            getData={this.getData}
            isDateStart={true}
            isDateEnd={true}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
            network={this.state.network}
            group={this.state.group}
            region={this.state.region}
            agency={this.state.agency}
            dateStartText="Date de début"
            dateEndText="Date de fin"
          />
          <TabsCustom>
            <TabsCustomHeader>
              {this.state.tabsType.map((element, idxType) => (
                <TabsCustomMenu
                  key={idxType}
                  title={element.name}
                  active={this.state.tabsIndexType === idxType}
                  onClick={(e) => {
                    if (this.state.tabsIndexType !== idxType) {
                      this.setState({
                        tabsIndexType: idxType,
                        tabsIndexDetail: element.start,
                      });
                    }
                  }}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              <TabsCustomItem active={true}>
                <TabsCustom>
                  <TabsCustomHeader>
                    {this.state.tabsDetail.map((element, idxDetail) => (
                      <Fragment key={idxDetail}>
                        {element.type === this.state.tabsIndexType ? (
                          <TabsCustomMenu
                            title={element.name}
                            active={this.state.tabsIndexDetail === idxDetail}
                            onClick={(e) => {
                              if (this.state.tabsIndexDetail !== idxDetail) {
                                this.setState({
                                  tabsIndexDetail: idxDetail,
                                });
                              }
                            }}
                          />
                        ) : null}
                      </Fragment>
                    ))}
                  </TabsCustomHeader>
                  <TabsCustomBody noCard>
                    {this.state.tabsDetail.map((element, idxDetail) => (
                      <TabsCustomItem
                        key={idxDetail}
                        active={this.state.tabsIndexDetail === idxDetail}
                      >
                        <Card title="Graphique" className="mb-10 mb-20-xl">
                          <p style={{ fontSize: "10px" }}>Genre : VP et CTTE</p>
                          <BarChart
                            isLegend
                            type={"line"}
                            digits={2}
                            title={"Évolution"}
                            labels={months}
                            format={
                              this.state.tabsDetail[this.state.tabsIndexDetail]
                                .format
                            }
                            dataSet={Object.keys(this.state.companiesData).map(
                              (key) => {
                                return {
                                  type: "line",
                                  label: this.state.companiesData[key].name,
                                  data: this.state.companiesData[
                                    key
                                  ].months.map(
                                    (element) =>
                                      element[this.getType()][
                                        this.state.tabsDetail[
                                          this.state.tabsIndexDetail
                                        ].key
                                      ]
                                  ),
                                  color:
                                    this.state.companiesData[key].color ||
                                    getColors()[0],
                                };
                              }
                            )}
                            height={380}
                          />
                        </Card>
                        <CardPodiumTable
                          title={"Classement"}
                          isLoad={false}
                          other={"Genre : VP et CTTE"}
                          isSpecial={true}
                          data={Object.keys(this.state.companiesData)
                            .sort((a, b) =>
                              this.state.tabsDetail[this.state.tabsIndexDetail]
                                .reverse
                                ? this.state.companiesData[b][this.getType()][
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].key
                                  ] -
                                  this.state.companiesData[a][this.getType()][
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].key
                                  ]
                                : this.state.companiesData[a][this.getType()][
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].key
                                  ] -
                                  this.state.companiesData[b][this.getType()][
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].key
                                  ]
                            )
                            .filter((key) => {
                              if (
                                this.state.companiesData[key][this.getType()]
                                  .total &&
                                this.state.companiesData[key][this.getType()][
                                  this.state.tabsDetail[
                                    this.state.tabsIndexDetail
                                  ].key
                                ]
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            })
                            .map((key) => {
                              return {
                                name: this.state.companiesData[key].name,
                                value: this.getValue(
                                  this.state.companiesData[key][this.getType()][
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].key
                                  ],
                                  this.state.tabsDetail[
                                    this.state.tabsIndexDetail
                                  ].format
                                ),
                                total:
                                  this.state.companiesData[key][this.getType()][
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].count
                                      ? this.state.tabsDetail[
                                          this.state.tabsIndexDetail
                                        ].count
                                      : "total"
                                  ],
                                year:
                                  (this.state.companiesData[key][
                                    this.getType()
                                  ][
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].key
                                  ] /
                                    this.state.companiesData[key][
                                      `${this.getType()}Year`
                                    ][
                                      this.state.tabsDetail[
                                        this.state.tabsIndexDetail
                                      ].key
                                    ]) *
                                    100 -
                                  100,
                                reverse:
                                  this.state.tabsDetail[
                                    this.state.tabsIndexDetail
                                  ].reverse,
                              };
                            })}
                        />
                      </TabsCustomItem>
                    ))}
                  </TabsCustomBody>
                </TabsCustom>
              </TabsCustomItem>
            </TabsCustomBody>
          </TabsCustom>
          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
