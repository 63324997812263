import React from "react";
import { 
    Loader,
    Button,
    FullModal,
    FormInput,
    FormTextarea,
    FormSelect,
    FormRadio,
    Table, 
    Thead, 
    Tbody, 
    Tr, 
    Td, 
    Th,
    DoughnutChart,
} from "ui-kit-ck-consultant";

import AuthContext from "../../context/AuthContext";

import axiosConfig from "../../utils/axiosConfig";

import "./audit-modal.css";

export default class AuditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            page: 1,
            isError: false,
            dataModal: {},
            auditTypeId: "",
            // Identification page data
            caseRef: "",
            bpce: 0,
            group: 0,
            expert: 0,
            bpceSelect: [
                { value: "DEFAULT", text: "Sélectionner une sous-compagnie", disabled: true },
                { value: "BP", text: "BP" },
                { value: "BPCE", text: "BPCE" }
            ],
            groupSelect: [{ value: "DEFAULT", text: "Sélectionner un groupe", disabled: true }],
            expertSelect: [{ value: "DEFAULT", text: "Sélectionner un expert", disabled: true }],
            // Analysis page data
            financialImpact: "",
            comment: "",
            totalQuestions: 0,
            answeredQuestions: 0,
            // Result page data
            auditScore: 0
        }
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.getGroups();
    }

    componentDidUpdate(prevProps) {
        if (this.props.showModal !== prevProps.showModal && this.props.showModal && !this.props.answers) {
            this.getAuditId(this.props.auditType)
            this.handleNewAuditClick(this.props.auditType);
        } else if (this.props.showModal !== prevProps.showModal && this.props.showModal && this.props.answers) {
            this.setState({
                page: 2,
                dataModal: this.props.answers,
                auditTypeId: this.props.auditTypeId,
                caseRef: this.props.folder,
                group: this.props.groupId,
                expert: this.props.expertId,
                comment: this.props.comment ? this.props.comment : "",
                financialImpact: this.props.financialImpact === 0 || this.props.financialImpact ? this.props.financialImpact : ""
            }, () => {
                let totalQuestions = 0;
                this.state.dataModal.group.forEach(element => {
                    element.data.forEach(el => {
                        totalQuestions++;
                    })
                });
                this.setState({ totalQuestions: totalQuestions, answeredQuestions: totalQuestions });
            })
        }
    }

    getGroups = () => {
        axiosConfig("audit/get-groups-options").then(res => {
            if (res.data.success) {
                this.setState({ 
                    groupSelect: [
                        { value: "DEFAULT", text: "Sélectionner un groupe", disabled: true },
                        ...res.data.data
                    ]
                });
            } else {
                this.setState({ 
                    groupSelect: [{ value: "DEFAULT", text: "Sélectionner un groupe", disabled: true }]
                });
            }
        })
    }

    getAgencies = () => {
        axiosConfig({
            url: "audit/get-agencies-options",
            data: {
                groupId: this.state.group
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({ 
                    expertSelect: [
                        { value: "DEFAULT", text: "Sélectionner un expert", disabled: true },
                        ...res.data.data
                    ]
                });
            } else {
                this.setState({ 
                    expertSelect: [{ value: "DEFAULT", text: "Sélectionner un expert", disabled: true }]
                });
            }
        })
    }

    getAuditId = auditType => {
        let auditTypeId;

        switch (auditType) {
            case "vvr":
                auditTypeId = 1;
                break;
            case "ve":
                auditTypeId = 2;
                break;   
            case "fire":
                auditTypeId = 3;
                break;
            case "field-expert":
                auditTypeId = 4;
                break;
            case "ead":
                auditTypeId = 5;
                break;
            case "inconsistency":
                auditTypeId = 6;
                break;
            case "riv-vei":
                auditTypeId = 7;
                break;
            default:
                auditTypeId = null;
                break;
        }

        this.setState({ auditTypeId: auditTypeId });
    }

    handleNewAuditClick = auditType => {
        axiosConfig(`audit/get-audit-${auditType}`).then(res => {
          if (res.data.success) {
            this.setState({ dataModal: res.data.data }, () => {
              let totalQuestions = 0;
              this.state.dataModal.group.forEach(element => {
                element.data.forEach(el => {
                  totalQuestions++;
                })
              });
              this.setState({ totalQuestions: totalQuestions });
            });
          }
        });
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            if (name === "group" ) {
                this.setState({ expert: 0 }, value ? this.getAgencies() : null);
            }
        });
    }
    
    handleIdentificationClick = () => {
        if ((this.context.companyId === 24 && (
            !this.state.bpce ||
            this.state.bpce === "DEFAULT"
        )) 
            || !this.state.caseRef 
            || !this.state.group 
            || !this.state.expert
            || this.state.group === "DEFAULT"
            || this.state.expert === "DEFAULT"
        ) {
            this.setState({ isError: true });
        } else {
            this.setState({ page: 2, isError: false });
        }
    }
    
    handleRadioChange = (event, idx1, idx2) => {
        const { value, checked } = event.target;
        let dataModal = this.state.dataModal;
        let answeredQuestions = this.state.answeredQuestions;
    
        if (checked) {
            if (dataModal.group[idx1].data[idx2].value) {
                dataModal.group[idx1].data[idx2].value = value;
            } else {
                dataModal.group[idx1].data[idx2] = {
                    ...dataModal.group[idx1].data[idx2],
                    value: value
                };
                answeredQuestions++;
            }
        }
    
        this.setState({ dataModal: dataModal, answeredQuestions: answeredQuestions });
    }
    
    handleAnalysisAddClick = () => {
        if (this.state.answeredQuestions !== this.state.totalQuestions) {
            if (
                window.confirm("Mettre les questions non complétées à N.C. ?")
            ) {
                let dataModal = this.state.dataModal;
                dataModal.group.forEach(element => {
                    element.data.forEach(el => {
                        if (!el.value) {
                            el.value = "nc";
                        }
                    });
                });
                this.handleSaveNewAudit();
            } else {
                this.setState({ isError: true });
            }
        } else {
            this.handleSaveNewAudit();
        } 
    }

    handleSaveNewAudit = () => {
        this.setState({ isLoad: true }, () => {
            const answers = this.state.dataModal.group;
            let score = 0;
            let totalPoints = 0;

            answers.forEach(element => {
                element.data.forEach(el => {
                    if (el.value === "yes") {
                        score += el.score;
                        totalPoints += el.score;
                    } else if (el.value === "no") {
                        totalPoints += el.score;
                    }
                });
            });
        
            let result = totalPoints ? (score / totalPoints) * 100 : 0;

            axiosConfig({
                url: "audit/save-audit",
                data: {
                    folderNb: this.state.caseRef, 
                    groupId: Number(this.state.group), 
                    agencyId: Number(this.state.expert), 
                    auditType: this.state.auditTypeId, 
                    value: Number(Math.round(result + "e+2") + "e-2"),
                    financialImpact: this.state.financialImpact ? Number(this.state.financialImpact) : null,
                    answers: JSON.stringify(this.state.dataModal),
                    comment: this.state.comment,
                    bpce: this.state.bpce ? this.state.bpce : null
                }
            }).then(res => {
                if (res.data.success) {
                    this.setState({ auditScore: Math.round(result), page: 3, isLoad: false });
                } else {
                    this.setState({ isLoad: false }, this.clearData);
                    alert("Erreur lors du calcul du score");
                }
            });
        });
    }

    handleUpdateAudit = () => {
        this.setState({ isLoad: true }, () => {
            const answers = this.state.dataModal.group;
            let score = 0;
            let totalPoints = 0;

            answers.forEach(element => {
                element.data.forEach(el => {
                    if (el.value === "yes") {
                        score += el.score;
                        totalPoints += el.score;
                    } else if (el.value === "no") {
                        totalPoints += el.score;
                    }
                });
            });
        
            let result = totalPoints ? (score / totalPoints) * 100 : 0;

            axiosConfig({
                url: "audit/update-audit",
                data: {
                    id: this.props.id, 
                    value: Number(Math.round(result + "e+2") + "e-2"),
                    financialImpact: this.state.financialImpact ? Number(this.state.financialImpact) : null,
                    answers: JSON.stringify(this.state.dataModal),
                    comment: this.state.comment
                }
            }).then(res => {
                if (res.data.success) {
                    this.setState({ auditScore: Math.round(result), page: 3, isLoad: false });
                } else {
                    this.setState({ isLoad: false }, this.clearData);
                    alert("Erreur lors du calcul du score");
                }
            });
        });
    }
    
    handleResultClick = () => {
        this.clearData();
    }

    clearData = () => {
        this.setState({
            page: 1,
            isError: false,
            dataModal: {},
            caseRef: "",
            group: 0,
            expert: 0,
            bpce: 0,
            financialImpact: "",
            comment: "",
            totalQuestions: 0,
            answeredQuestions: 0,
            auditScore: 0
        }, this.props.closeModal);
    }
  
    render() {
        console.log(this.state)
        return (
            <>
                <FullModal
                    show={this.props.showModal} 
                    onClose={this.clearData} 
                    title={`${this.state.dataModal.title}
                        ${this.state.page === 2 || this.state.page === 3 
                            ? this.props.isEdit 
                                ? ` - Edition du dossier n°${this.state.caseRef}` 
                                : ` - Dossier n°${this.state.caseRef}` 
                            : ""}`
                    }
                >
                    {this.state.page === 1 ? (
                    <div className="d-flex h-100">
                        <div className="m-auto">
                            {this.context.companyId === 24 ? (
                                <FormSelect
                                    className="mb-20"
                                    name="bpce"
                                    value={this.state.bpce}
                                    options={this.state.bpceSelect} 
                                    title="Sous-compagnie"
                                    onChange={this.handleInputChange}
                                    error={this.state.isError === true && !this.state.bpce ? "Veuillez sélectionner une option" : ""}
                                    ignore={true}
                                />
                            ) : null}
                            <FormInput
                                className="mb-20"
                                type="text"
                                name="caseRef"
                                value={this.state.caseRef}
                                title="Référence du sinistre"
                                onChange={this.handleInputChange}
                                error={this.state.isError === true && !this.state.caseRef ? "Veuillez remplir ce champ" : ""}
                            />
                            <FormSelect
                                className="mb-20"
                                name="group"
                                value={this.state.group}
                                options={this.state.groupSelect} 
                                title="Groupe d'expert"
                                onChange={this.handleInputChange}
                                error={this.state.isError === true && !this.state.group ? "Veuillez sélectionner une option" : ""}
                                ignore={true}
                            />
                            <FormSelect
                                className="mb-20"
                                name="expert"
                                value={this.state.expert}
                                options={this.state.expertSelect} 
                                title="Expert"
                                onChange={this.handleInputChange}
                                error={this.state.isError === true && !this.state.expert ? "Veuillez sélectionner une option" : ""}
                                disabled={!this.state.group}
                                ignore={true}
                            />
                            <Button
                                className="mb-20"
                                text="Suivant"
                                onClick={this.handleIdentificationClick}
                            />
                        </div>
                    </div>
                    ) : this.state.page === 2 ? (
                    <>
                        {this.state.dataModal.group.map((element, idx1) => (
                            <div key={element.title}>
                                <h2>{element.title}</h2>
                                <Table className="mb-30">
                                    <Thead>
                                        <Tr>
                                            <Th>Description</Th>
                                            <Th>Oui</Th>
                                            <Th>Non</Th>
                                            <Th>N.C.</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {element.data.map((el, idx2) => (
                                            <Tr key={`${element.title}-${idx2}`}>
                                                <Td className={this.state.isError && !el.value ? "red" : ""}>
                                                    {el.label} ({el.score} point(s))
                                                </Td>
                                                <Td className="fixed-width-col">
                                                    <FormRadio 
                                                        name={`${element.title}-${idx2}`} 
                                                        value="yes"
                                                        checked={el.value === "yes" ? true : false}
                                                        onChange={event => this.handleRadioChange(event, idx1, idx2)}
                                                    />
                                                </Td>
                                                <Td className="fixed-width-col">
                                                    <FormRadio
                                                        name={`${element.title}-${idx2}`} 
                                                        value="no"
                                                        checked={el.value === "no" ? true : false}
                                                        onChange={event => this.handleRadioChange(event, idx1, idx2)}
                                                    />
                                                </Td>
                                                <Td className="fixed-width-col">
                                                    <FormRadio 
                                                        name={`${element.title}-${idx2}`} 
                                                        value="nc"
                                                        checked={el.value === "nc" ? true : false}
                                                        onChange={event => this.handleRadioChange(event, idx1, idx2)}
                                                    />
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </div>
                        ))}
                        <div>
                            <h2>Autres</h2>
                            <Table className="mb-30">
                                <Thead>
                                    <Tr>
                                        <Th>Description</Th>
                                        <Th colSpan={2}>Réponse</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>Impact financier</Td>
                                        <Td>
                                            <FormInput
                                                type="text"
                                                name="financialImpact"
                                                value={this.state.financialImpact}
                                                onChange={this.handleInputChange}
                                            />
                                            
                                        </Td>
                                        <Td className="fixed-width-col"><p>€ HT</p></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Commentaire</Td>
                                        <Td colSpan={2}>
                                            <FormTextarea
                                                type="text"
                                                name="comment"
                                                value={this.state.comment}
                                                rows={3}
                                                onChange={this.handleInputChange}
                                            />
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </div>
                        <Button
                            className="mb-4"
                            text="Envoyer"
                            onClick={
                                this.props.isEdit 
                                ? this.handleUpdateAudit 
                                : this.handleAnalysisAddClick
                            }
                        />
                    </>
                    ) : this.state.page === 3 ? (
                    <div className="d-flex h-100">
                        <div className="m-auto">
                            <DoughnutChart
                                className="m-auto mb-30"
                                number={this.state.auditScore}
                                digits={0}
                                text="score"
                            />
                            
                            <Button
                                className="mb-20"
                                text="Fermer"
                                onClick={this.handleResultClick}
                            />
                        </div>
                    </div>
                    ) : null}
                </FullModal>
                
                <Loader show={this.state.isLoad} />
            </>
        );
    }
}