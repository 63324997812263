import React from "react";
import { Nav, Card, Loader, FormSelect } from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../../context/AuthContext";
import ContactRequest from "../../../components/general/ContactRequest";
import DataTable from "../../../components/statistics/statistics-general/DataTable";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

import {
  repairTimeTable,
  totalLossTimeTable,
  techniqueTable,
  avgCostTable,
} from "./dataAbeille";

export default class StatisticsAbeille extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      isLoad: false,
      year: new Date().getFullYear(),
      yearSelect: [
        {
          value: new Date().getFullYear(),
          text: `${new Date().getFullYear() - 1} - ${new Date().getFullYear()}`,
        },
      ],
      group: "DEFAULT",
      groupSelect: [{ value: "DEFAULT", text: "Tous les groupes" }],
      data: null,
      search: null,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({
      accessAllowed: checkRights("StatisticsAbeille", this.context),
    });
    this.getYearSelect();
    this.getGroupSelect();
    this.getPerformance();
  }

  getYearSelect = () => {
    axiosConfig("statistics/general/get-years-options").then((res) => {
      if (res.data.success) {
        this.setState({ yearSelect: res.data.data });
      } else {
        const year = new Date().getFullYear();
        this.setState({
          yearSelect: [{ value: year, text: `${year - 1} - ${year}` }],
        });
      }
    });
  };

  getGroupSelect = () => {
    this.setState({
      group: "DEFAULT",
      groupSelect: [
        { value: "DEFAULT", text: "Tous les groupes" },
        { value: 22, text: "LANG" },
      ],
    });
  };

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: Number(value) }, () => {
      if (name !== "group") this.getPerformance();
    });
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig({
        url: "pilotage-cabinet/company-performance/statistics-abeille",
        data: {
          year: this.state.year,
          // groupId: this.state.group && this.state.group !== "DEFAULT" ? this.state.group : null
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.getTables(res.data.data, () => {
              this.setState({
                search: res.data.search,
                isLoad: false,
              });
            });
          } else {
            this.setState({ data: null, isLoad: false });
          }
        })
        .catch(() => {
          this.setState({ isLoad: false }, () =>
            alert("Une erreur est survenue")
          );
        });
    });
  };

  getTables = (data, callback) => {
    const tables = [
      repairTimeTable,
      totalLossTimeTable,
      techniqueTable,
      avgCostTable,
    ];

    const tablesData = tables.map((table) => {
      const tableData = table.map((row) => ({
        id: row.id,
        name: row.name,
        data: this.getData(data[row.id]),
        unit: row.unit,
        title: row.title,
        evolReversed: row.evolReversed,
      }));
      return tableData;
    });

    this.setState({ data: tablesData }, () => callback());
  };

  getData = (rawData) => {
    return rawData.map((el, idx) => {
      if ((idx + 2) % 3 === 0) {
        return this.getEvolution(rawData[idx - 1], rawData[idx + 1]);
      } else {
        return el;
      }
    });
  };

  getEvolution = (current, old) => {
    let diff = 0;

    if (!old && !current) {
      diff = 0;
    } else if (!old) {
      diff = 100;
    } else if (!current) {
      diff = 100;
    } else {
      diff = parseInt(((old - current) / current) * 100);
    }

    if (diff > 0) {
      return "+" + diff;
    } else {
      return diff;
    }
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Statistiques spécifiques" className="mb-20" />
          <ContactRequest page="Statistiques - Générales" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Statistiques spécifiques" className="mb-20" />

            <Card className="mb-20">
              <Row>
                <Col sm={12} md={3}>
                  <FormSelect
                    name="year"
                    value={this.state.year}
                    options={this.state.yearSelect}
                    title="Années"
                    onChange={this.handleSelectChange}
                    ignore={true}
                  />
                </Col>
                <Col sm={12} md={3}>
                  <FormSelect
                    name="group"
                    value={this.state.group}
                    options={this.state.groupSelect}
                    title="Expert"
                    onChange={this.handleSelectChange}
                    ignore={true}
                  />
                </Col>
              </Row>
            </Card>

            <Card
              title="Qualité"
              className="mb-20"
              classNameChildren="overflow-x-auto"
            >
              <DataTable
                name="repairTime"
                data={
                  this.state.data &&
                  this.state.data.length > 0 &&
                  this.state.data[0]
                    ? this.state.data[0]
                    : null
                }
                year={this.state.year}
              />
              <DataTable
                name="totalLossTime"
                data={
                  this.state.data &&
                  this.state.data.length > 0 &&
                  this.state.data[1]
                    ? this.state.data[1]
                    : null
                }
                year={this.state.year}
              />
            </Card>

            {/* TECHNIQUE CARD */}
            <Card
              title="Technique (EAD + REP)"
              className="mb-20"
              classNameChildren="overflow-x-auto"
            >
              <DataTable
                name="technique"
                data={
                  this.state.data &&
                  this.state.data.length > 0 &&
                  this.state.data[2]
                    ? this.state.data[2]
                    : null
                }
                year={this.state.year}
              />
            </Card>

            {/* AVERAGE COST CARD */}
            <Card
              title="Coût Moyen"
              className="mb-20"
              classNameChildren="overflow-x-auto"
            >
              <DataTable
                name="avgCost"
                data={
                  this.state.data &&
                  this.state.data.length > 0 &&
                  this.state.data[3]
                    ? this.state.data[3]
                    : null
                }
                year={this.state.year}
              />
            </Card>
          </FadeIn>

          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
