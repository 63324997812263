import React from "react";
import {
  Button,
} from "ui-kit-ck-consultant";

import ContactModal from "./ContactModal";

export default class ContactRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isContactModal: false
        }
    }

    render() {
        return (
            <>
                <h3 className="mb-30">
                    Oups... Vous n'avez pas les autorisations nécessaires pour accéder à cette page...
                </h3>
                <p className="mb-30">
                    Vous souhaitez bénéficier de ce module complémentaire : contactez-nous.
                </p>
                <Button 
                    text="Faire une demande"
                    className="w-auto"
                    onClick={() => this.setState({ isContactModal: true })}
                />
                
                <ContactModal
                    show={this.state.isContactModal}
                    page={this.props.page}
                    onClose={() => this.setState({ isContactModal: false })}
                />
            </>
        );
    }
}
