const checkRights = (page, context) => {
  const admin = context.admin;
  const companyId = context.companyId;
  const access = context.access;

  let accessRights = false;

  switch (page) {
    case "Administration":
      if (admin || !companyId) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "BrokenGlass":
      if (access.clickable.glass) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "StatisticsGeneral":
      if (access.clickable.statisticsGeneral) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "StatisticsAbeille":
      if (access.clickable.statisticsAbeille) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "StatisticsAllianz":
      if (access.clickable.statisticsAllianz) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "StatisticsAllianzOM":
      if (access.clickable.statisticsAllianzOM) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "StatisticsGarage":
      if (access.clickable.statisticsGarage) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "StatisticsVVNR":
      if (access.clickable.statisticsVVNR) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "StatisticsIRSA":
      if (access.clickable.statisticsIRSA) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "StatisticsAxa":
      if (access.clickable.statisticsAxa) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "Selfcare":
      if (access.clickable.selfcare) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "Audit":
      if (access.clickable.audit) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "Anomaly":
      if (access.clickable.anomaly) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "Missions":
      if (access.clickable.missions) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "EGA":
      if (access.clickable.ega) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "Caravaning":
      if (access.clickable.caravaning) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "Electric":
      if (access.clickable.electric) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "Ranking":
      if (access.clickable.ranking) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    case "StatisticsCovea":
      if (access.clickable.statisticsCovea) {
        accessRights = true;
      } else {
        accessRights = false;
      }
      break;
    default:
      accessRights = false;
      break;
  }

  return accessRights;
};

export default checkRights;
