import React, { Component } from "react";
import FadeIn from "react-fade-in";
import {
  Nav,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
  PaginationClass,
  Loader,
} from "ui-kit-ck-consultant";
import moment from "moment";

import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";

import GlobalMonitoring from "../../../components/reparator/GlobalMonitoring";
import TargetedMonitoring from "../../../components/reparator/TargetedMonitoring";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";

export class ReparatorPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIndex: 0,
      tabsMenu: ["Suivi Global", "Suivi Ciblé"],
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 1,
      group: 1,
      region: 0,
      agency: 0,
      company: null,
      user: null,
      garageApproved: "all",
      natureEvent: null,
      garage: null,
      garageIds: {},
      // pagination
      tabLength: 20,
      total: 0,
      start: 0,
      end: 20,
      // LOADER
      isLoad: false,
    };
  }

  static contextType = AuthContext;

  initSearch = () => {
    this.setState({ activePage: 1, isLoad: true }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState(
            { total: result.total ? result.total : 0, isLoad: true },
            () => {
              this.PaginationClass.get(
                axiosConfig,
                this.getData(),
                (result) => {
                  this.setData(result);
                }
              );
            }
          );
        }
      });
    });
  };

  setData = (result) => {
    if (result.success === true) {
      this.setState({ dataList: result.tabData, isLoad: false });
    } else {
      this.setState({ dataList: [], isLoad: false });
    }
  };

  getData = () => {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      user: this.state.user,
      userType:
        this.context.type === 5 || this.context.role === 2 ? [5] : [2, 3],
      dateStart: this.state.dateStart,
      dateEnd: this.state.dateEnd,
      company: this.state.company,
      garageApproved: this.state.garageApproved,
      garage: this.state.garage,
      natureEvent: this.state.natureEvent,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  };

  handlePageChange = (activePage) => {
    let end = activePage * this.state.tabLength;
    let start = activePage * this.state.tabLength - this.state.tabLength;

    this.PaginationClass.page(activePage, () =>
      this.setState(
        { activePage: activePage, isLoad: true, start, end },
        () => {
          this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
            if (result.success === true) {
              this.setData(result);
            }
          });
        }
      )
    );
  };

  componentDidMount = () => {
    this.PaginationClass = new PaginationClass(
      "pilotage-cabinet/performances/reparatorData/count",
      "pilotage-cabinet/performances/reparatorData/pagination",
      this.state.tabLength
    );
  };

  handleDataChange = () => {
    this.getFollowedGaragesIds();
    this.initSearch();
  };

  getFollowedGaragesIds = () => {
    axiosConfig(
      "pilotage-cabinet/performances/reparatorData/get-followed-ids",
      {
        data: {
          agencies: this.state.agency,
        },
      }
    ).then((res) => {
      if (res.data.success) {
        this.setState({ garageIds: res.data.garageIds }, () => {
          if (
            this.state.garageIds &&
            this.state.garageIds[this.context.companyId] &&
            this.state.garageIds[this.context.companyId].length
          ) {
            this.getFollowedGaragesData(
              this.state.garageIds[this.context.companyId],
              this.context.companyId
            );
          }
        });
      }
    });
  };

  getFollowedGaragesData = (ids) => {
    let data = this.getData();
    data.ids = ids;

    axiosConfig(
      "pilotage-cabinet/performances/reparatorData/get-followed-data",
      {
        data,
      }
    ).then((res) => {
      if (res.data.success) {
        this.setState({ followedData: res.data.data });
      } else {
        this.setState({ followedData: null });
      }
    });
  };

  handleSortClick = (order) => {
    this.setState({ order }, () => {
      this.initSearch();
    });
  };

  handleFollowGarage = (id, group) => {
    console.log(group, this.context);
    this.setState({ isLoad: true }, () => {
      axiosConfig(
        "pilotage-cabinet/performances/reparatorData/update-followed-garages",
        {
          data: {
            id: id.toString(),
            group,
            agencies: this.state.agency,
          },
        }
      ).then((res) => {
        if (res.data.success) {
          this.setState({ garageIds: res.data.garageIds, isLoad: false });
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  };

  getWidthPrint() {
    return {
      width: this.state.isPrint ? "2800px" : "auto",
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    let dateStart = this.state.dateStart;
    let dateEnd = this.state.dateEnd;

    if (
      name === "dateEnd" &&
      moment(value).format("YYYY") !== moment(dateStart).format("YYYY")
    ) {
      dateStart = moment(`${moment(value).format("YYYY")}-01-01`).format(
        "YYYY-MM-DD"
      );
    } else if (
      name === "dateStart" &&
      moment(value).format("YYYY") !== moment(dateEnd).format("YYYY")
    ) {
      dateEnd = moment(`${moment(value).format("YYYY")}-12-31`).format(
        "YYYY-MM-DD"
      );
    }

    this.setState({
      dateStart,
      dateEnd,
      [name]: value,
    });
  };

  handleInputBlur = () => {};

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: Number(value) }, () => {
      if (name === "network") {
        this.setState({ group: 0, region: 0, agency: 0 });
      } else if (name === "group") {
        this.setState({ region: 0, agency: 0 });
      } else if (name === "region") {
        this.setState({ agency: 0 });
      }
    });
  };

  render() {
    return (
      <FadeIn>
        <Nav title="Suivi réparateurs" className="mb-10 mb-20-xl" />
        <ShowOptions
          className="mb-20"
          handleInputChange={this.handleInputChange}
          handleInputBlur={this.handleInputBlur}
          handleSelectChange={this.handleSelectChange}
          getData={this.handleDataChange}
          isDateStart={true}
          isDateEnd={true}
          network={this.state.network}
          group={this.state.group}
          region={this.state.region}
          agency={this.state.agency}
          dateStart={this.state.dateStart}
          dateEnd={this.state.dateEnd}
          dateStartText="Date de début"
          dateEndText="Date de fin"
        />
        <TabsCustom className={"mt-10 mt-20-xl"}>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((element, idx) => (
              <TabsCustomMenu
                key={idx}
                title={element}
                active={this.state.tabsIndex === idx}
                disabled={idx === 1 && !this.state.agency}
                onClick={() => {
                  this.setState({
                    tabsIndex: idx === 1 && this.state.agency ? idx : 0,
                  });
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              <GlobalMonitoring
                data={
                  this.state.dataList &&
                  this.state.dataList.slice(this.state.start, this.state.end)
                }
                activePage={this.state.activePage}
                tabLength={this.state.tabLength}
                total={this.state.total}
                onSortClick={this.handleSortClick}
                onPageChange={this.handlePageChange}
                onFollowGarage={this.handleFollowGarage}
                garageIds={this.state.garageIds}
                isPrint={this.state.isPrint}
                agency={this.state.agency}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              <div
                ref={this.refPrintReparatorTarget}
                style={this.getWidthPrint()}
              >
                <TargetedMonitoring followedData={this.state.followedData} />
              </div>
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        <Loader show={this.state.isLoad} />
      </FadeIn>
    );
  }
}

export default ReparatorPerformance;
