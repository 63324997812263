import React, { Component } from "react";
import { Card, BarChart } from "ui-kit-ck-consultant";

import {colors} from "../../../utils/colors";

export default class Evolution extends Component {
  render() {
    return (
      <Card title="Graphiques" className="mb-10 mb-20-xl">
        <BarChart
          isLegend
          type={"line"}
          digits={0}
          title={"État des dossiers"}
          labels={this.props.data ? this.props.data.labels : []}
          dataSet={[
            {
              type: "line",
              label: "En attente de RDV",
              data: this.props.data ? this.props.data.waitAppointment : [],
              color: colors.red[1],
            },
            {
              type: "line",
              label: "Expertise à venir",
              data: this.props.data ? this.props.data.waitVisit : [],
              color: colors.orange[1],
            },
            {
              type: "line",
              label: "Expertisé",
              data: this.props.data ? this.props.data.visited : [],
              color: colors.blue[1],
            },
            {
              type: "line",
              label: "Clôturé sans expertise",
              data: this.props.data ? this.props.data.noVisited : [],
              color: colors.yellow[1],
            },
            {
              type: "line",
              label: "Clôturé avec expertise",
              data: this.props.data ? this.props.data.closing : [],
              color: colors.green[1],
            },
          ]}
          height={280}
        />
      </Card>
    );
  }
}
