import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
    Table, 
    Thead, 
    Tbody, 
    Tr, 
    Td, 
    Th,
    ButtonIcon,
} from "ui-kit-ck-consultant";

import "./data-table.css"

export default class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          titles: [],
          cells: [],
          values: [],
          subtitles: [],
          totals: []
        };
    }

    componentDidMount() {
        this.setData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.tableType !== this.props.tableType 
            || prevProps.data.data !== this.props.data.data) {
            this.setData();
        }
    }
    
    setData = () => {
        if (this.props.data.data.length > 0) {
            let values = this.props.data.data.map(line => {
                let valuesLine = [];
                for (let i = 0; i < 3; i++) {
                    let filteredData = line.data.filter(element => Number(element.year) === new Date().getFullYear() - i);
                    if (filteredData.length > 0) {
                        valuesLine.push(filteredData[0].value);
                    } else {
                        valuesLine.push("");
                    }
                }
                return valuesLine;
            });

            switch(this.props.tableType) {
                case 1:
                    this.setState({
                        titles: ["Groupe", "Agence", `${new Date().getFullYear() - 2}`, `${new Date().getFullYear() - 1}`, `${new Date().getFullYear()}`, "Total", "Action"],
                        cells: ["group", "agencyName", "year3", "year2", "year1", "total", "action"],
                        values: values,
                        subtitles: [null, null, `Total ${new Date().getFullYear() - 2}`, `Total ${new Date().getFullYear() - 1}`, `Total ${new Date().getFullYear()}`, "Total général", null],
                        totals: [
                            null, 
                            null,
                            this.calculateTotal(new Date().getFullYear() - 2), 
                            this.calculateTotal(new Date().getFullYear() - 1), 
                            this.calculateTotal(new Date().getFullYear()), 
                            this.calculateGeneralTotal(), 
                            null
                        ]
                    });
                    break;
                case 2:
                    this.setState({
                        titles: ["Départements", `${new Date().getFullYear() - 2}`, `${new Date().getFullYear() - 1}`, `${new Date().getFullYear()}`, "Total"],
                        cells: ["department", "year3", "year2", "year1", "total"],
                        values: values,
                        subtitles: [null, `Total ${new Date().getFullYear() - 2}`, `Total ${new Date().getFullYear() - 1}`, `Total ${new Date().getFullYear()}`, "Total général"],
                        totals: [
                            null, 
                            this.calculateTotal(new Date().getFullYear() - 2), 
                            this.calculateTotal(new Date().getFullYear() - 1), 
                            this.calculateTotal(new Date().getFullYear()), 
                            this.calculateGeneralTotal()
                        ]
                    });
                    break;
                case 3:
                    this.setState({
                        titles: ["Groupes", `${new Date().getFullYear() - 2}`, `${new Date().getFullYear() - 1}`, `${new Date().getFullYear()}`, "Total", "Action"],
                        cells: ["groupName", "year3", "year2", "year1", "total", "action"],
                        values: values,
                        subtitles: [null, `Total ${new Date().getFullYear() - 2}`, `Total ${new Date().getFullYear() - 1}`, `Total ${new Date().getFullYear()}`, "Total général"],
                        totals: [
                            null, 
                            this.calculateTotal(new Date().getFullYear() - 2), 
                            this.calculateTotal(new Date().getFullYear() - 1), 
                            this.calculateTotal(new Date().getFullYear()), 
                            this.calculateGeneralTotal()
                        ]
                    });
                    break;
                default: 
                    break;
            }
        } else {
            this.setState({
                titles: [],
                cells: [],
                values: [],
                subtitles: [],
                totals: []
            });
        }
    }

    calculateTotal = year => {
        let totalYear = 0;
        this.props.data.data.forEach(line => {
            let filteredData = line.data.filter(element => Number(element.year) === year);
            if (filteredData.length > 0) {
                totalYear += Number(filteredData[0].value);
            }
        });
        return totalYear;
    }

    calculateGeneralTotal = () => {
        return (
            this.calculateTotal(new Date().getFullYear()) 
            + this.calculateTotal(new Date().getFullYear() - 1) 
            + this.calculateTotal(new Date().getFullYear() - 2)
        );

    }

    render() {
        return (
            <>
                {this.props.data.data.length > 0 ? (
                    <Table className="data-table">
                        <Thead>
                            <Tr>
                                {this.state.titles.map((title, idx) => <Th key={idx}>{title}</Th> )}
                            </Tr>
                        </Thead>

                        <Tbody>
                            {/* MAIN CONTENT */}
                            {this.props.data.data.map((line,lineIdx) => (
                                <Tr key={lineIdx}>
                                    {this.state.cells.map((cell, cellIdx) => (
                                        <Td key={cellIdx}>
                                            {cell === "department" ? `${line.departmentZip} - ${line.department}`
                                            : cell === "year1" ? this.state.values[lineIdx][0] 
                                            : cell === "year2" ? this.state.values[lineIdx][1]
                                            : cell === "year3" ? this.state.values[lineIdx][2] 
                                            : cell === "total" ? (
                                                Number(this.state.values[lineIdx][0]) 
                                                + Number(this.state.values[lineIdx][1]) 
                                                + Number(this.state.values[lineIdx][2])
                                            ) : cell === "action" && this.props.tableType === 1 ? (
                                                    <div style={{display: "flex"}}>
                                                        <ButtonIcon 
                                                            small 
                                                            style={{color: "var(--primary-color"}}
                                                            onClick={() => this.props.onEditAgency(line, this.props.data.departmentId)}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </ButtonIcon>
                                                        <ButtonIcon 
                                                            small 
                                                            style={{color: "var(--primary-color"}}
                                                            onClick={() => this.props.onDeleteAgency(line, this.props.data.departmentId)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </ButtonIcon>
                                                    </div>
                                                ) : cell === "action" && this.props.tableType === 3 ? (
                                                        <div style={{display: "flex"}}>
                                                            <ButtonIcon 
                                                                small 
                                                                style={{color: "var(--primary-color"}}
                                                                onClick={() => this.props.onEditGroup(line)}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </ButtonIcon>
                                                        </div>
                                                ) : line[cell]
                                            }
                                        </Td>
                                    ))}
                                </Tr>
                            ))}

                            {/* TOTALS */}
                            <Tr>
                                {this.state.subtitles.map((subtitle, subtitleIdx) => (
                                    subtitle ? (
                                        <Th key={subtitleIdx}>
                                            {subtitle}
                                        </Th>
                                    ) : (
                                        <Td key={subtitleIdx}></Td>
                                    )
                                ))}
                            </Tr>
                            <Tr>
                                {this.state.totals.map((total, totalIdx) => (
                                    <Td key={totalIdx}>
                                        {total}
                                    </Td>
                                ))}
                            </Tr>
                        </Tbody>
                    </Table>
                ) : (
                    <div className="data-table data-table-empty">
                        Aucun expert
                    </div>
                )}
            </>
            
        )
    }
}