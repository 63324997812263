import React from "react";
import { Redirect } from "react-router-dom";

export default class AuthV2Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: this.props.children,
    };
  }

  render() {
    if (this.props.connected === true) {
      return <Redirect to="/home" />;
    } else {
      return window.innerWidth > 540 ? (
        <div className="Auth">
          <div className={"background-signin-v2"}>
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                zIndex: -1,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              position: "relative",
              height: "100%",
              width: "100%",
            }}
          >
            {this.props.children}
          </div>
        </div>
      ) : (
        this.props.children
      );
    }
  }
}
