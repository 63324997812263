import React from "react";
import { FormInput, Button } from "ui-kit-ck-consultant";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import logo from "../../images/logo-primaire.svg";

import axiosConfig from "../../utils/axiosConfig";

export default class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.location.state ? this.props.location.state.email : "",
      password: "",
      isError: false,
    };
  }

  handleSignInClick = (event) => {
    event.preventDefault();
    if (this.state.email && this.state.password) {
      axiosConfig({
        url: "/auth/signin",
        data: {
          email: this.state.email,
          password: this.state.password,
        },
      }).then((res) => {
        if (res.data.success === true) {
          document.location.reload();
        } else {
          this.setState({ isError: true });
        }
      });
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      isError: false,
    });
  };

  render() {
    return (
      <Row className="w-100 overflow-y-auto">
        {" "}
        {window.innerWidth > 540 ? (
          <Col xs={12} md={6} className="d-flex">
            <div className="background-test">
              <img
                src={logo}
                alt="logo ixperience"
                style={{ maxWidth: "100%", maxHeight: "54px" }}
              />
              <h3>
                Bienvenue sur votre espace dédié au suivi et au pilotage des
                Experts en automobile.
              </h3>
              <p>
                Cette plateforme vous permet de suivre en temps réel l'évolution
                des résultats en fonction de vos critères, du type de véhicule
                ou du type de sinistre.
              </p>
              <p>
                Statistiques générales, Bris de Glace, Selfcare, Reporting EGA,
                Caravaning, Electrique, ... peuvent être mis à votre disposition
                et adaptés à vos besoins.
              </p>
              <p>
                Vous pouvez également piloter vos prestataires grâce à nos
                modules d'audit, de relevé d'anomalies, de répartition des
                missions ou tout autre module spécifique que nous développerons
                pour vous.
              </p>
            </div>
          </Col>
        ) : (
          <Col xs={12} md={6} className="d-flex">
            <div className="mt-auto ml-auto mr-auto text-center">
              <img
                src={logo}
                alt="logo ixperience"
                style={{ maxWidth: "100%", maxHeight: "54px" }}
              />
              <h4>
                Bienvenue sur votre espace dédié au suivi et au pilotage des
                Experts en automobile.
              </h4>
            </div>
          </Col>
        )}
        <Col xs={12} md={6} className="mt-20 mb-20 d-flex">
          <div
            className="m-auto bg-white"
            style={{
              borderRadius: "15px",
              padding: "40px",
            }}
          >
            <h2 className="m-0 mb-10">Connexion</h2>
            <FormInput
              className="mb-10"
              title="Identifiant"
              placeholder="example@pilotage-reseau"
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              required
            />

            <FormInput
              className="mb-20"
              title="Mot de passe"
              placeholder="*********"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleInputChange}
              required
              error={
                this.state.isError
                  ? "Identifiant ou mot de passe incorrects"
                  : ""
              }
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.handleSignInClick(e);
                }
              }}
            />

            <div className="d-flex">
              <Button
                className="mb-20 w-auto ml-auto"
                text="Se connecter"
                onClick={this.handleSignInClick}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
            <Link
              to={{ pathname: "/forgot" }}
              style={{ color: "var(--primary-color)" }}
            >
              Mot de passe oublié ?
            </Link>
          </div>
        </Col>
      </Row>
    );
  }
}
