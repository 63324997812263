import React from "react";
import {
  Nav,
  Card,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
  FormSelect,
  Calendar,
  Button,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import FadeIn from "react-fade-in";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../context/AuthContext";
import RankingCard from "../../components/audit/RankingCard";
import StatisticsCard from "../../components/audit/StatisticsCard";
import AuditListCard from "../../components/audit/AuditListCard";
import Declare from "../../components/audit/Declare";
import ContactRequest from "../../components/general/ContactRequest";

import checkRights from "../../utils/checkRights";
import axiosConfig from "../../utils/axiosConfig";

export default class Audit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      // Loader
      isLoad: false,
      // Tabs
      tabsMenu: ["Classement", "Technique"],
      tabsIndex: 0,
      // Filters
      bpce: 0,
      bpceSelect: [
        { value: 0, text: "Toute la compagnie" },
        { value: 1, text: "BP" },
        { value: 2, text: "BPCE" },
      ],
      group: 0,
      groupSelect: [{ value: 0, text: "Tous les groupes" }],
      agency: 0,
      agencySelect: [{ value: 0, text: "Toutes les agences" }],
      // Data
      groupsRanking: [],
      agenciesRanking: [],
      folderTypeAudit: [],
      folderTypeScore: [],
      groupScore: [],
      groupCost: [],
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      isCalendar: false,
      nameCalendar: null,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getGroups();
    this.setData();
    this.setState({ accessAllowed: checkRights("Audit", this.context) });
  }

  getGroups = () => {
    axiosConfig("audit/get-groups-options").then((res) => {
      if (res.data.success) {
        this.setState({
          groupSelect: [
            { value: 0, text: "Tous les groupes" },
            ...res.data.data,
          ],
        });
      } else {
        this.setState({
          groupSelect: [{ value: 0, text: "Tous les groupes" }],
        });
      }
    });
  };

  getAgencies = () => {
    axiosConfig({
      url: "audit/get-agencies-options",
      data: {
        groupId: this.state.group,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({
          agencySelect: [
            { value: 0, text: "Toutes les agences" },
            ...res.data.data,
          ],
        });
      } else {
        this.setState({
          agencySelect: [{ value: 0, text: "Toutes les agences" }],
        });
      }
    });
  };

  setData = () => {
    this.getAgenciesRanking();
    this.getGroupsRanking();
    this.getAuditCountChart();
    this.getAuditScoreChart();
    this.getGroupScoreChart();
    this.getGroupCostTable();
  };

  getGroupsRanking = () => {
    axiosConfig({
      url: "audit/get-groups-ranking",
      data: {
        dateStart: this.state.dateStart,
        dateEnd: this.state.dateEnd,
        bpce: this.state.bpce
          ? this.state.bpceSelect[this.state.bpce].text
          : null,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({ groupsRanking: res.data.data });
      } else {
        this.setState({ groupsRanking: [] });
      }
    });
  };

  getAgenciesRanking = () => {
    axiosConfig({
      url: "audit/get-agencies-ranking",
      data: {
        dateStart: this.state.dateStart,
        dateEnd: this.state.dateEnd,
        bpce: this.state.bpce
          ? this.state.bpceSelect[this.state.bpce].text
          : null,
        groupId: this.state.group ? this.state.group : null,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({ agenciesRanking: res.data.data });
      } else {
        this.setState({ agenciesRanking: [] });
      }
    });
  };

  getAuditCountChart = () => {
    axiosConfig({
      url: "audit/get-audit-count",
      data: {
        dateStart: this.state.dateStart,
        dateEnd: this.state.dateEnd,
        bpce: this.state.bpce
          ? this.state.bpceSelect[this.state.bpce].text
          : null,
        groupId: this.state.group ? this.state.group : null,
        agencyId: this.state.agency ? this.state.agency : null,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({ folderTypeAudit: res.data.data });
      } else {
        this.setState({ folderTypeAudit: [] });
      }
    });
  };

  getAuditScoreChart = () => {
    axiosConfig({
      url: "audit/get-audit-score",
      data: {
        dateStart: this.state.dateStart,
        dateEnd: this.state.dateEnd,
        bpce: this.state.bpce
          ? this.state.bpceSelect[this.state.bpce].text
          : null,
        groupId: this.state.group ? this.state.group : null,
        agencyId: this.state.agency ? this.state.agency : null,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({ folderTypeScore: res.data.data });
      } else {
        this.setState({ folderTypeScore: [] });
      }
    });
  };

  getGroupScoreChart = () => {
    axiosConfig({
      url: "audit/get-group-score",
      data: {
        dateStart: this.state.dateStart,
        dateEnd: this.state.dateEnd,
        bpce: this.state.bpce
          ? this.state.bpceSelect[this.state.bpce].text
          : null,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({ groupScore: res.data.data });
      } else {
        this.setState({ groupScore: [] });
      }
    });
  };

  getGroupCostTable = () => {
    axiosConfig({
      url: "audit/get-group-cost",
      data: {
        dateStart: this.state.dateStart,
        dateEnd: this.state.dateEnd,
        bpce: this.state.bpce
          ? this.state.bpceSelect[this.state.bpce].text
          : null,
        groupId: this.state.group ? this.state.group : null,
        agencyId: this.state.agency ? this.state.agency : null,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({ groupCost: res.data.data });
      } else {
        this.setState({ groupCost: [] });
      }
    });
  };

  extractCSV = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig({
        url: "audit/extract-CSV",
        data: {
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          bpce: this.state.bpce
            ? this.state.bpceSelect[this.state.bpce].text
            : null,
          groupId: this.state.group ? this.state.group : null,
          agencyId: this.state.agency ? this.state.agency : null,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({ isLoad: false }, () => {
            this.downloadFile(
              res.data.base64,
              `Extraction Audit ${moment().format("DD-MM-YYYY")}.xlsx`
            );
          });
        } else {
          alert("Une erreur est survenue");
          this.setState({ isLoad: false });
        }
      });
    });
  };

  downloadFile = (data, filename) => {
    const element = document.createElement("a");
    // eslint-disable-next-line no-undef
    element.href = data;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    element.remove();
  };

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: Number(value) }, () => {
      if (name === "group") {
        this.setState({ agency: 0 }, () => {
          if (value) {
            this.getAgencies();
          }
          this.setData();
        });
      } else {
        this.setData();
      }
    });
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Audit" className="mb-20" />
          <ContactRequest page="Audit" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Audit" className="mb-20" />

            <Declare className="mb-10 mb-20-xl" refreshData={this.setData} />

            <Card className="mb-20">
              <Row>
                <Col sm={12} md={3}>
                  <span
                    className="mt-auto"
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "var(--black)",
                      marginBottom: "8px",
                    }}
                  >
                    Date de début
                  </span>
                  <Button
                    outline
                    text={moment(this.state.dateStart).format("DD/MM/YYYY")}
                    onClick={() => {
                      this.setState({
                        isCalendar: true,
                        nameCalendar: "dateStart",
                      });
                    }}
                  />
                </Col>
                <Col sm={12} md={3}>
                  <span
                    className="mt-auto"
                    style={{
                      display: "inline-block",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "var(--black)",
                      marginBottom: "8px",
                    }}
                  >
                    Date de fin
                  </span>
                  <Button
                    outline
                    text={moment(this.state.dateEnd).format("DD/MM/YYYY")}
                    onClick={() => {
                      this.setState({
                        isCalendar: true,
                        nameCalendar: "dateEnd",
                      });
                    }}
                  />
                </Col>
                {this.context.companyId === 24 ? (
                  <Col sm={12} md={3}>
                    <FormSelect
                      name="bpce"
                      value={this.state.bpce}
                      options={this.state.bpceSelect}
                      title="Sous-compagnie"
                      onChange={this.handleSelectChange}
                      ignore={true}
                    />
                  </Col>
                ) : null}
                <Col sm={12} md={3}>
                  <FormSelect
                    name="group"
                    value={this.state.group}
                    options={this.state.groupSelect}
                    title="Groupe"
                    onChange={this.handleSelectChange}
                    ignore={true}
                  />
                </Col>
                <Col sm={12} md={3}>
                  <FormSelect
                    name="agency"
                    value={this.state.agency}
                    options={this.state.agencySelect}
                    title="Agence"
                    onChange={this.handleSelectChange}
                    ignore={true}
                    disabled={!this.state.group}
                  />
                </Col>
                <Col sm={12} md={3} className="d-flex">
                  <Button
                    className="mt-auto"
                    text="Télécharger les données"
                    onClick={this.extractCSV}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <TabsCustom>
              <TabsCustomHeader>
                {this.state.tabsMenu.map((menu, index) => (
                  <TabsCustomMenu
                    key={index}
                    title={menu}
                    active={this.state.tabsIndex === index}
                    onClick={() => this.setState({ tabsIndex: index })}
                  />
                ))}
              </TabsCustomHeader>

              <TabsCustomBody noCard>
                {this.state.tabsMenu.map((element, tabsIndex) => (
                  <TabsCustomItem
                    key={tabsIndex}
                    active={this.state.tabsIndex === tabsIndex}
                  >
                    {tabsIndex === 0 ? (
                      // CLASSEMENT
                      <>
                        <RankingCard
                          title="Groupes"
                          data={this.state.groupsRanking}
                        />
                        <RankingCard
                          title="Agences"
                          data={this.state.agenciesRanking}
                          bpce={
                            this.state.bpce
                              ? this.state.bpceSelect[this.state.bpce].text
                              : null
                          }
                          group={
                            this.state.group
                              ? this.state.groupSelect.filter(
                                  (el) => el.value === this.state.group
                                )[0].text
                              : null
                          }
                        />
                      </>
                    ) : (
                      // TECHNIQUE
                      <>
                        <StatisticsCard
                          title="Statistiques"
                          data={{
                            folderTypeAudit: this.state.folderTypeAudit,
                            folderTypeScore: this.state.folderTypeScore,
                            groupScore: this.state.groupScore,
                            groupCost: this.state.groupCost,
                          }}
                          bpce={
                            this.state.bpce
                              ? this.state.bpceSelect[this.state.bpce].text
                              : null
                          }
                          group={
                            this.state.group
                              ? this.state.groupSelect.filter(
                                  (el) => el.value === this.state.group
                                )[0].text
                              : null
                          }
                          agency={
                            this.state.agency
                              ? this.state.agencySelect.filter(
                                  (el) => el.value === this.state.agency
                                )[0].text
                              : null
                          }
                        />
                        <AuditListCard
                          title="Liste des audits"
                          groupId={this.state.group}
                          agencyId={this.state.agency}
                          dateStart={this.state.dateStart}
                          dateEnd={this.state.dateEnd}
                          bpce={
                            this.state.bpce
                              ? this.state.bpceSelect[this.state.bpce].text
                              : null
                          }
                          group={
                            this.state.group
                              ? this.state.groupSelect.filter(
                                  (el) => el.value === this.state.group
                                )[0].text
                              : null
                          }
                          agency={
                            this.state.agency
                              ? this.state.agencySelect.filter(
                                  (el) => el.value === this.state.agency
                                )[0].text
                              : null
                          }
                          refreshData={this.setData}
                        />
                      </>
                    )}
                  </TabsCustomItem>
                ))}
              </TabsCustomBody>
            </TabsCustom>
          </FadeIn>

          <Calendar
            show={this.state.isCalendar}
            valueDate={this.state[this.state.nameCalendar]}
            isWeek
            onClose={() =>
              this.setState({
                isCalendar: false,
                nameCalendar: "",
              })
            }
            onValid={(valueDate) => {
              this.setState(
                {
                  isCalendar: false,
                  [this.state.nameCalendar]: valueDate,
                },
                () => this.setData()
              );
            }}
          />

          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
