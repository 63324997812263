import React from "react";
import {
  Nav,
  Card,
  FormInput,
  Button,
  Dropzone,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import FadeIn from 'react-fade-in';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import AuthContext from "../../context/AuthContext";

import axiosConfig from "../../utils/axiosConfig";

export default class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            isPasswordError: false,
            picture: null,
            preview: null
        }
    }

    static contextType = AuthContext;
  
    componentDidMount() {
        this.getUserPicture();
    }

    getUserPicture = () => {
        axiosConfig("settings/get-picture").then(res => {
            if (res.data.success) {
                this.setState({ picture: res.data.data.picture });
            } else {
                this.setState({ picture: null });
            }
        });
      }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value, isPasswordError: false });
    }

    handleChangePasswordClick = () => {
        if(this.state.newPassword === this.state.confirmPassword) {
            axiosConfig("/settings/change-password", {
                data: {
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.newPassword
                }
            }).then(res => {
                this.setState({ oldPassword: "", newPassword: "", confirmPassword: "" });
                if (res.data.success) {
                    alert("Le mot de passe a été modifié");
                } else {
                    alert("Erreur lors du changement de mot de passe");
                }
            });
        } else {
            this.setState({ isPasswordError: true });
        }
        
    }

    setBlobValue = event => {
        this.setState({
            dataBlob: event.base64,
            dataMime: event.mime,
            preview : event.base64
        });
    }

    handleSavePictureClick = () => {
        axiosConfig({
            url: "settings/update-picture",
            data: {
                base64: this.state.dataBlob,
                mime: this.state.dataMime
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({ 
                    picture : this.state.dataBlob, 
                    preview: null
                });
                alert("Photo de profil enregistrée avec succès");
                document.location.reload();
            } else {
                alert("Erreur lors de la sauvegarde");
            }
        });
      }
    
    render() {
        return (
            <>
                <FadeIn>
                <Nav title="Paramètres" className="mb-20" />

                    {/* UPDATE PASSWORD */}
                    <Card title="Mot de passe" className="mb-20">
                        <Row>
                            <Col sm={12} md={3}>
                                <FormInput 
                                    className="mb-20" 
                                    type="password" 
                                    name="oldPassword" 
                                    value={this.state.oldPassword} 
                                    title="Ancien mot de passe" 
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col sm={12} md={3}>
                                <FormInput 
                                    className="mb-20" 
                                    type="password" 
                                    name="newPassword" 
                                    value={this.state.newPassword} 
                                    title="Nouveau mot de passe" 
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col sm={12} md={3}>
                                <FormInput 
                                    className="mb-20" 
                                    type="password" 
                                    name="confirmPassword" 
                                    value={this.state.confirmPassword} 
                                    title="Confirmer nouveau mot de passe" 
                                    error={this.state.isPasswordError ? "Mots de passe différents" : ""}
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col sm={12} md={3} className="d-flex">
                                <Button 
                                    disabled={!this.state.oldPassword || !this.state.newPassword || !this.state.confirmPassword} 
                                    className="mt-auto mb-20" 
                                    text="Modifier" 
                                    onClick={this.handleChangePasswordClick}
                                />
                            </Col>
                        </Row>
                    </Card>

                    {/* UPDATE PICTURE */}
                    <Row>
                        <Col xs={12} md={6}>
                            <Card title="Photo de profil" className="mb-20">
                                <div className="container">
                                    {this.state.picture 
                                        ? <img style={{ width: '200px', position: 'relative', borderRadius: '50%' }} src={this.state.picture} alt="profile"/> 
                                        : <FontAwesomeIcon icon={faUser} size="6x" />
                                    }
                                    <Dropzone 
                                        className={"m-auto my-10"}
                                        dropHandler={this.setBlobValue}
                                        typeName={[ "png", "jpeg" ]}
                                        typeAccept={[ "image/png", "image/jpeg" ]}
                                    />
                                    {this.state.dataBlob ? (
                                    <> 
                                        {this.state.preview ? (
                                            <div>
                                                <h4>Aperçu : </h4>
                                                <img style={{ width: '100px', position: 'relative', borderRadius: '50%' }} src={this.state.preview} alt="profile"/> 
                                            </div>
                                        ) : null}
                                        <Button 
                                            text="Valider"
                                            onClick={this.handleSavePictureClick}
                                        />
                                    </>
                                    ) : null}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </FadeIn>
            </>
        );
    }
}
