import React from "react";
import { Nav } from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";

import AuthContext from "../../context/AuthContext";
import ContactRequest from "../../components/general/ContactRequest";

import checkRights from "../../utils/checkRights";

export default class Anomaly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({ accessAllowed: checkRights("Anomaly", this.context) });
  }

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Anomalie" className="mb-20" />
          <ContactRequest page="Anomalie" />
        </FadeIn>
      ) : (
        <>
          <Nav title="Anomalie" />
        </>
      );
    } else {
      return <></>;
    }
  }
}
