import React from "react";
import {
    Modal,
    FormInput,
    FormSelect,
    Button,
} from "ui-kit-ck-consultant";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";

export default class ModalGarage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Form entries
            color: "#000000",
            name: "",
            groupId: null,
            departmentId: 1,
            companyId: 1,
            // Form data
            groups: [],
            groupsSelect: [],
            departments: [],
            departmentsSelect: [],
            companiesSelect: [],
            isError: false
        }
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.getCompanies();
        this.getGroups();
        this.getDepartments();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show !== this.props.show && this.props.id) {
            this.setState({
                color: this.props.color,
                name: this.props.name,
                departmentId: this.props.departmentId,
                companyId: this.props.companyId,
                isError: false
            });
            this.getGroups();
        } else if (prevProps.show !== this.props.show && !this.props.id) {
            this.setState({
                color: "#000000",
                name: "",
                departmentId: 1,
                companyId: this.state.companiesSelect[0].value,
                isError: false,
            });
            this.getGroups();
        } else if (prevState.companyId !== this.state.companyId) {
            this.getGroups();
        }
    }

    getCompanies = () => {
        axiosConfig("admin/general/get-all-companies").then(res => {
            if (res.data.success === true) {
                let companiesSelect = res.data.data.map(element => {
                    return { value: Number(element.id), text: element.name }
                });
                this.setState({ companiesSelect: companiesSelect });
            } else {
                this.setState({ companiesSelect: [] });
            }
        });
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleAddGarageClick = () => {
        if (this.state.name && this.state.groupId) {
            axiosConfig({
                url: "admin/garage/add-garage",
                data: {
                    groupId: this.state.groupId, 
                    name: this.state.name,
                    color: this.state.color,
                    departmentId: this.state.departmentId
                }
            }).then(res => {
                if (res.data.success) {
                    alert("Le réparateur a bien été ajouté");
                    this.props.onClose();
                } else {
                    alert("Erreur lors de la sauvegarde");
                    this.props.onClose();
                }
            });
        } else {
            this.setState({ isError: true });
        }
    }

    handleEditGarageClick = () => {
        if (this.state.name) {
            axiosConfig({
                url: "admin/garage/edit-garage",
                data: {
                    id: this.props.id,
                    name: this.state.name,
                    color: this.state.color,
                    departmentId: this.state.departmentId
                }
            }).then(res => {
                if (res.data.success) {
                    alert("Le réparateur a bien été modifié");
                    this.props.onClose();
                } else {
                    alert("Erreur lors de la sauvegarde");
                    this.props.onClose();
                }
            });
        } else {
            this.setState({ isError: true });
        }
    }

    getGroups = () => {
        axiosConfig({
            url: "admin/garage/get-all-groups",
            data: {
                companyId: this.context.companyId ? this.context.companyId : this.state.companyId
            }
        }).then(res => {
            if (res.data.success === true) {
                let groups = res.data.data; 
                let groupsSelect = res.data.data.map(element => {
                    return { value: Number(element.id), text: element.name }
                });
                this.setState({ groups: groups, groupsSelect: groupsSelect }, () => {
                    if (this.props.groupId) {
                        this.setState({ groupId: this.props.groupId });
                    } else {
                        this.setState({ groupId: this.state.groups.length > 0 ? this.state.groups[0].id : null });
                    }
                });
            } else {
                this.setState({ groups: [], groupsSelect: [], groupId: null });
            }
        });
    }

    getDepartments = () => {
        axiosConfig({
            url: "admin/garage/get-all-departments"
        }).then(res => {
            if (res.data.success === true) {
                let departments = res.data.data;
                let departmentsSelect = res.data.data.map(element => {
                    return { value: Number(element.id), text: `${element.zip} - ${element.name}`}
                });
                this.setState({ departments: departments, departmentsSelect: departmentsSelect });
            } else {
                this.setState({ departments: [], departmentsSelect: [] });
            }
        });
    }

    render () {
        return (
            <Modal
                title={this.props.isEdit ? `Modifier ${this.props.name}` : "Ajouter un réparateur"}
                show={this.props.show}
                onClose={this.props.onClose}
            >
                {this.context.companyId === null ? (
                    <FormSelect
                        className="mb-20"
                        name="companyId"
                        value={this.state.companyId}
                        options={this.state.companiesSelect} 
                        title="Compagnie"
                        onChange={this.handleInputChange}
                        ignore={true}
                        init="Veuillez sélectionner une option"
                        disabled={this.props.isEdit}
                    />
                ) : null}
                <FormSelect
                    className="mb-20"
                    name="groupId"
                    value={this.state.groupId}
                    options={this.state.groupsSelect} 
                    title="Réseau"
                    onChange={this.handleInputChange}
                    ignore={true}
                    error={this.state.isError === true && !this.state.groupId ? "Ajoutez d'abord un réseau à cette compagnie" : ""}
                    disabled={this.props.isEdit}
                />
                <FormSelect
                    className="mb-20"
                    name="departmentId"
                    value={this.state.departmentId}
                    options={this.state.departmentsSelect} 
                    title="Département"
                    onChange={this.handleInputChange}
                    ignore={true}
                />
                <FormInput
                    className="mb-20"
                    type="text"
                    name="name"
                    value={this.state.name}
                    title="Réparateur"
                    onChange={this.handleInputChange}
                    error={this.state.isError === true && !this.state.name ? "Veuillez remplir ce champ" : ""}
                />
                <FormInput 
                    className="mb-20" 
                    type="color"
                    name="color" 
                    value={this.state.color}
                    title="Couleur" 
                    onChange={this.handleInputChange} 
                    
                />
                <Button
                    className="mb-4"
                    text={this.props.isEdit ? "Modifier" : "Ajouter"}
                    onClick={this.props.isEdit ? this.handleEditGarageClick : this.handleAddGarageClick}
                />
          </Modal>
        )
    }
}