const colors = {
  green: ["#0c8540", "#bcf5d5", "#01612a"],
  cyan: ["#00c0ba", "#befffd", "#026a67"],
  blue: ["#026fc2", "#bbe1fe", "#023f6d"],
  purple: ["#6a3ab3", "#dcc5fe", "#41236d"],
  pink: ["#b40c8d", "#ffbcf0", "#7a0e61"],
  red: ["#db2828", "#ffb8b8", "#9d0c0c"],
  orange: ["#f2711c", "#fecaa9", "#b94900"],
  yellow: ["#ffcc00", "#fbefc0", "#d5ab00"],
  gray: ["#d8d8d8", "#f3f3f3"],
  white: ["#ffffff"],
  black: ["#1e1f26"],
};

const getColors = () => {
  return Object.keys(colors).map((key) => colors[key][0]);
};

export { colors, getColors };
