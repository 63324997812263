import React from "react";
import {
  Nav,
  Card,
  Loader,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
  PieChart,
  BarChart,
  DoughnutChart,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../../context/AuthContext";

import ContactRequest from "../../../components/general/ContactRequest";
import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";
import TableReparable from "../../../components/statistics/statistics-covea/TableReparable";
import TableUnreparable from "../../../components/statistics/statistics-covea/TableUnreparable";

import { getCurrencyValue } from "../../../utils/general";
import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

export default class StatisticsCovea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      isLoad: false,
      // Filters
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 1,
      group: 1,
      region: 0,
      agency: 0,
      // Data state
      data: null,
      dataCompanies: null,
      dataCompaniesOthers: null,
      months: null,
      // Tabs
      tabsMenu: ["Réparables", "Non Réparables"],
      tabsIndex: 0,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      dateSinisterEnd:
        name === "dateSinisterStart" ? value : this.state.dateSinisterEnd,
      dateStart: name === "dateSinisterStart" ? value : this.state.dateStart,
      [name]: value,
    });
  };

  handleInputBlur = () => {};

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: Number(value) }, () => {
      if (name === "network") {
        this.setState({ group: 0, region: 0, agency: 0 });
      } else if (name === "group") {
        this.setState({ region: 0, agency: 0 });
      } else if (name === "region") {
        this.setState({ agency: 0 });
      }
    });
  };

  componentDidMount() {
    this.setState({
      accessAllowed: checkRights("StatisticsCovea", this.context),
    });
  }

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig({
          url: "statistics/statistics-covea/get-data",
          data: {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
          },
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                data: res.data.data,
                dataCompanies: res.data.dataCompanies,
                dataCompaniesOthers: res.data.dataCompaniesOthers,
                months: res.data.months,
                isLoad: false,
              });
            } else {
              this.setState({
                isLoad: false,
              });
            }
          })
          .catch((err) => {
            this.setState({ isLoad: false });
          });
      }
    );
  };

  render() {
    const dataPieChart = {
      ...this.state.dataCompanies,
      ...this.state.dataCompaniesOthers,
    };

    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Statistiques spécifiques" className="mb-20" />
          <ContactRequest page="Statistiques - Générales" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Statistiques spécifiques" className="mb-20" />
            <ShowOptions
              className="mb-20"
              handleInputChange={this.handleInputChange}
              handleInputBlur={this.handleInputBlur}
              handleSelectChange={this.handleSelectChange}
              getData={this.getData}
              isDateStart={true}
              isDateEnd={true}
              dateStart={this.state.dateStart}
              dateEnd={this.state.dateEnd}
              network={this.state.network}
              group={this.state.group}
              region={this.state.region}
              agency={this.state.agency}
              dateStartText="Date de début"
              dateEndText="Date de fin"
            />
            <Row>
              <Col xs={12} md={6} className="mb-10 mb-20-xl">
                <Card title="Répartition compagnie">
                  {this.state.dataCompanies ? (
                    <>
                      <PieChart
                        height={280}
                        full
                        type="pie"
                        digits={0}
                        legendDisplay={false}
                        positionLegend="bottom"
                        data={Object.keys(dataPieChart).map((key) => ({
                          labels: `${dataPieChart[key].name} (${dataPieChart[key].folders_percentage}%)`,
                          data: dataPieChart[key].folders_value,
                          color: dataPieChart[key].color,
                        }))}
                      />
                      <div className="d-flex mt-20">
                        <div
                          style={{
                            borderRadius: "9px",
                            height: "18px",
                            width: "18px",
                            backgroundColor:
                              dataPieChart[this.context.companyId].color,
                            marginRight: "6px",
                          }}
                        />
                        <p className="mt-auto mb-auto">
                          {dataPieChart[this.context.companyId].name} -{" "}
                          {dataPieChart[this.context.companyId].folders_value} (
                          {
                            dataPieChart[this.context.companyId]
                              .folders_percentage
                          }
                          %)
                        </p>
                      </div>
                    </>
                  ) : null}
                </Card>
              </Col>
              <Col xs={12} md={6} className="mb-10 mb-20-xl">
                <Card title="Coût Moyen">
                  {this.state.dataCompanies ? (
                    <>
                      <BarChart
                        height={280}
                        barWidth={40}
                        digits={2}
                        format="currency"
                        labels={Object.keys(this.state.dataCompanies).map(
                          (key) => this.state.dataCompanies[key].name
                        )}
                        dataSet={[
                          {
                            label: "Coût moyen",
                            data: Object.keys(this.state.dataCompanies).map(
                              (key) =>
                                this.state.dataCompanies[key].averageCost_value
                            ),
                            color: Object.keys(this.state.dataCompanies).map(
                              (key) => this.state.dataCompanies[key].color
                            ),
                          },
                        ]}
                        xAxes={[
                          {
                            ticks: {
                              display: false,
                            },
                            gridLines: {
                              display: false,
                            },
                          },
                        ]}
                      />

                      <div className="d-flex mt-20">
                        <div
                          style={{
                            borderRadius: "9px",
                            height: "18px",
                            width: "18px",
                            backgroundColor:
                              this.state.dataCompanies[this.context.companyId]
                                .color,
                            marginRight: "6px",
                          }}
                        />
                        <p className="mt-auto mb-auto">
                          {
                            this.state.dataCompanies[this.context.companyId]
                              .name
                          }{" "}
                          -{" "}
                          {getCurrencyValue(
                            this.state.dataCompanies[this.context.companyId]
                              .averageCost_value
                          )}
                        </p>
                      </div>
                    </>
                  ) : null}
                </Card>
              </Col>
              <Col xs={12} md={12} className="mb-10 mb-20-xl">
                <Card title="Note Qualité">
                  {this.state.dataCompanies ? (
                    <Row>
                      <Col xs={12} md={3}>
                        <DoughnutChart
                          className="m-auto"
                          number={
                            this.state.dataCompanies[this.context.companyId]
                              .satisfactionScore_value
                          }
                          text={`Note moyenne (Position : ${
                            this.state.dataCompanies[this.context.companyId]
                              .satisfactionScore_position
                          })`}
                          max={20}
                        />
                        <DoughnutChart
                          className="m-auto mt-20"
                          number={
                            this.state.dataCompanies[this.context.companyId]
                              .satisfactionRate_value
                          }
                          text={`Taux de réponse (Position : ${
                            this.state.dataCompanies[this.context.companyId]
                              .satisfactionRate_position
                          })`}
                          max={100}
                        />
                      </Col>
                      <Col xs={12} md={9}>
                        {this.state.dataCompanies ? (
                          <BarChart
                            height={280}
                            digits={2}
                            labels={this.state.months.map((month) => month)}
                            dataSet={[
                              {
                                type: "line",
                                label: "Note moyenne",
                                data: this.state.dataCompanies[
                                  this.context.companyId
                                ].satisfactionScore_months_value,
                                color: "green",
                              },
                              {
                                type: "line",
                                label: "Taux de réponse",
                                data: this.state.dataCompanies[
                                  this.context.companyId
                                ].satisfactionRate_months_value,
                                color: "red",
                              },
                            ]}
                            xAxes={[
                              {
                                ticks: {
                                  display: false,
                                },
                                gridLines: {
                                  display: false,
                                },
                              },
                            ]}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  ) : null}
                </Card>
              </Col>
              <TabsCustom>
                <TabsCustomHeader>
                  {this.state.tabsMenu.map((element, idx) => (
                    <TabsCustomMenu
                      key={idx}
                      title={element}
                      active={this.state.tabsIndex === idx}
                      onClick={() => {
                        this.setState({ tabsIndex: idx });
                      }}
                    />
                  ))}
                </TabsCustomHeader>
                <TabsCustomBody>
                  {this.state.data ? (
                    <>
                      <TabsCustomItem active={this.state.tabsIndex === 0}>
                        <TableReparable
                          data={this.state.data && this.state.data.R}
                        />
                      </TabsCustomItem>
                      <TabsCustomItem active={this.state.tabsIndex === 1}>
                        <TableUnreparable
                          data={this.state.data && this.state.data.NR}
                        />
                      </TabsCustomItem>
                    </>
                  ) : null}
                </TabsCustomBody>
              </TabsCustom>
            </Row>
          </FadeIn>

          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
