import React from "react";
import {
    Card,
    Table, 
    Thead, 
    Tbody, 
    Tr, 
    Td, 
    Th,
    BarChart,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../context/AuthContext";

import "./statistics-card.css";

export default class StatisticsCard extends React.Component {
    static contextType = AuthContext;

    render() {
        return (
            <Card title={this.props.title} className="mb-20">
                <Row>
                
                        <Col xs={12} md={7} className="mb-20">
                        {this.props.data.folderTypeAudit ? (
                            <BarChart
                                title="Nombre d'audits / type de dossier"
                                isLegend={true}
                                digits={0}
                                widthBar={10}
                                height={300}
                                dataSet={this.props.data.folderTypeAudit.map(el => ({
                                    type: "bar",
                                    label: el.name,
                                    data: el.data,
                                    color: el.color
                                }))}
                                xAxes={[{ stacked: true }]}
                                yAxes={[
                                    {
                                        ticks: {
                                            display: true,
                                            min: 0,
                                            beginAtZero: true
                                        },
                                        gridLines: {
                                            drawBorder: false,
                                        }
                                    }
                                ]}
                            />  ) : null}
                             {this.props.data.folderTypeScore ? (
                            <BarChart
                                title="Pourcentage d'atteinte / type de dossier"
                                labels={this.props.data.folderTypeScore.map(el => el.name)}
                                format="percentage"
                                digits={0}
                                widthBar={10}
                                height={300}
                                dataSet={[
                                    {
                                        type: "bar",
                                        data: this.props.data.folderTypeScore.map(el => el.value),
                                        color: this.context.interfaceColor.secondary,
                                    }
                                ]}
                                yAxes={[
                                    {
                                        ticks: {
                                            display: true,
                                            min: 0,
                                            beginAtZero: true
                                        },
                                        gridLines: {
                                            drawBorder: false,
                                        },
                                    }
                                ]}
                            />
                    ) : null}
                    {this.props.data.groupScore ? (
                            <BarChart
                                title="Pourcentage d'atteinte / groupe d'experts"
                                labels={this.props.data.groupScore.map(el => el.name)}
                                format="percentage"
                                digits={0}
                                widthBar={10}
                                height={300}
                                dataSet={[
                                    {
                                        type: "bar",
                                        data: this.props.data.groupScore.map(el => el.value),
                                        color: this.context.interfaceColor.secondary,
                                    }
                                ]}
                                yAxes={[
                                    {
                                        ticks: {
                                            display: true,
                                            min: 0,
                                            beginAtZero: true
                                        },
                                        gridLines: {
                                            drawBorder: false,
                                        },
                                    }
                                ]}
                            />
                    ) : null}
                        </Col>
                    {this.props.data.groupCost ? (
                        <Col xs={12} md={5} className="mb-20">
                            <div className="table_title">
                                <span style={{ color: `${this.context.interfaceColor.primary}` }}>Calcul d'impact €</span>
                            </div>
                            <Table
                                empty={
                                    this.props.data.groupCost.length > 0
                                        ? ""
                                        : "Aucune donnée n'est disponible"
                                }
                            >
                                <Thead>
                                    <Tr>
                                        <Th className="text-center">{this.props.bpce || this.props.group ? "Agences" : "Groupes"}</Th>
                                        <Th className="text-center">Moyen</Th>
                                        <Th className="text-center">Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {this.props.data.groupCost.map(element => ( 
                                        <Tr>
                                            <Th>{element.name}</Th>
                                            <Td>{element.avg} €</Td>
                                            <Td>{element.total} €</Td>
                                        </Tr>
                                    ))}
                                    {this.props.data.groupCost.length > 0 ? (
                                        <Tr>
                                            <Th>Global</Th>
                                            <Td>
                                                {this.props.data.groupCost && this.props.data.groupCost.length > 0 
                                                    ? Math.round(
                                                        this.props.data.groupCost.reduce((acc, currValue) => acc + currValue.avg, 0) 
                                                        / this.props.data.groupCost.length
                                                    ) 
                                                    : 0
                                                } €
                                            </Td>
                                            <Td>{this.props.data.groupCost.reduce((acc, currValue) => acc + currValue.total, 0)} €</Td>
                                        </Tr>
                                    ) : null}
                                </Tbody>
                            </Table>
                        </Col>
                    ) : null}
                </Row>
            </Card>
        )
    }
}