import React from "react";
import {
  Nav,
  Button,
  ButtonIcon,
  Card,
  FormInput,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Pagination,
  PaginationClass,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faEdit,
  faTrashAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import ModalUser from "../../../components/admin/admin-user/ModalUser";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

export default class AdminUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      search: "",
      users: [],
      order: { name: "id", desc: false },
      // Pagination
      activePage: 1,
      tabLength: 20,
      totalData: 0,
      // Modal states
      isModalUserAdd: false,
      isModalUserEdit: false,
      modalUserId: null,
      modalUserName: null,
      modalUserEmail: null,
      modalUserCompanyId: null,
      modalUserAdmin: null,
    };
    this.refColumnName = React.createRef();
    this.refColumnEmail = React.createRef();
    this.refColumnCompany = React.createRef();
    this.refColumnAdmin = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({
      accessAllowed: checkRights("Administration", this.context),
    });
    this.PaginationClass = new PaginationClass(
      "admin/user/count",
      "admin/user/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.isModalUserAdd !== this.state.isModalUserAdd &&
        this.state.isModalUserAdd === false) ||
      (prevState.isModalUserEdit !== this.state.isModalUserEdit &&
        this.state.isModalUserEdit === false)
    ) {
      this.initSearch();
    }
  }

  initSearch = () => {
    this.setState({ activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ totalData: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              if (result.success === true) {
                this.setState({ users: result.tabData });
              } else {
                this.setState({ users: [] });
              }
            });
          });
        }
      });
    });
  };

  getData = () => {
    return {
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  };

  handleSearchInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.initSearch);
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnEmail.current.setState({ desc: false, sort: false });
    }
    if (!this.context.companyId && column !== 2) {
      this.refColumnCompany.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnAdmin.current.setState({ desc: false, sort: false });
    }

    let order = null;

    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "email", desc: desc };
        break;
      case 2:
        order = { name: "company", desc: desc };
        break;
      case 3:
        order = { name: "admin", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  handlePasswordRequestClick = (email) => {
    if (
      window.confirm(`Envoyer un nouveau mot de passe à l'adresse ${email} ?`)
    ) {
      axiosConfig({
        url: "/admin/user/edit-user-password",
        data: {
          email: email,
        },
      }).then((res) => {
        if (res.data.success === true) {
          alert(`Un nouveau mot de passe a été envoyé à l'adresse ${email}`);
        } else {
          alert("Erreur lors du renvoi de mot de passe");
        }
      });
    }
  };

  handleDeleteUserClick = (user) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer l'utilisateur "${user.name}" ?`
      )
    ) {
      axiosConfig({
        url: "admin/user/delete-user",
        data: {
          id: user.id,
        },
      }).then((res) => {
        if (res.data.success) {
          alert("L'utilisateur a bien été supprimé");
          this.initSearch();
        } else {
          alert("Erreur lors de la suppression");
        }
      });
    }
  };

  handlePageChange = (activePage) => {
    this.PaginationClass.page(activePage, () => {
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setState({ users: result.tabData });
          } else {
            this.setState({ users: [] });
          }
        });
      });
    });
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <h2>
          Oups... Vous n'avez pas les autorisations nécessaires pour accéder à
          cette page...
        </h2>
      ) : (
        <>
          <FadeIn>
            {/* HEADER */}
            <Nav title="Utilisateurs" className="mb-20">
              <Button
                text="Ajouter un utilisateur"
                onClick={() => this.setState({ isModalUserAdd: true })}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Nav>

            {/* SEARCH BAR */}
            <Row>
              <Col sm={12} md={6}>
                <Card className="mb-20">
                  <FormInput
                    value={this.state.search}
                    name="search"
                    onChange={this.handleSearchInputChange}
                    placeholder={
                      this.context.companyId
                        ? "Nom ou adresse mail de l'utilisateur"
                        : "Nom, adresse mail ou compagnie de l'utilisateur"
                    }
                  >
                    <FontAwesomeIcon className="icon" icon={faSearch} />
                  </FormInput>
                </Card>
              </Col>
            </Row>

            {/* TABLE */}
            <Card
              title="Liste des utilisateurs"
              className="mb-20"
              classNameChildren="overflow-x-auto"
            >
              <Table
                empty={
                  this.state.users.length > 0
                    ? ""
                    : "Aucune donnée n'est disponible"
                }
                radiusTopLeft
              >
                <Thead>
                  <Tr>
                    <Th hide={this.state.users.length > 0 ? true : false}>#</Th>
                    <Th
                      ref={this.refColumnName}
                      sort
                      onSort={(desc) => this.handleSortClick(desc, 0)}
                      radiusTopLeft={this.state.users.length > 0 ? true : false}
                    >
                      Nom
                    </Th>
                    <Th
                      ref={this.refColumnEmail}
                      sort
                      onSort={(desc) => this.handleSortClick(desc, 1)}
                    >
                      Adresse mail
                    </Th>
                    {this.context.companyId ? null : (
                      <Th
                        ref={this.refColumnCompany}
                        sort
                        onSort={(desc) => this.handleSortClick(desc, 2)}
                      >
                        Compagnie
                      </Th>
                    )}
                    <Th
                      ref={this.refColumnAdmin}
                      sort
                      onSort={(desc) => this.handleSortClick(desc, 3)}
                    >
                      Administrateur
                    </Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {this.state.users.map((element, idx) => (
                    <Tr key={element.id}>
                      <Th radiusTopLeft={idx === 0 ? true : false}>
                        {(this.state.activePage - 1) * this.state.tabLength +
                          idx +
                          1}
                      </Th>
                      <Td>{element.name}</Td>
                      <Td>{element.email}</Td>
                      {this.context.companyId ? null : (
                        <Td>{element.companyName}</Td>
                      )}
                      <Td>{element.admin ? "✓" : ""}</Td>
                      <Td className="nowrap">
                        <div className="d-flex">
                          <ButtonIcon
                            small
                            style={{ color: "var(--primary-color" }}
                            onClick={() =>
                              this.handlePasswordRequestClick(element.email)
                            }
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </ButtonIcon>
                          <ButtonIcon
                            small
                            style={{ color: "var(--primary-color" }}
                            onClick={() =>
                              this.setState({
                                isModalUserEdit: true,
                                modalUserId: element.id,
                                modalUserName: element.name,
                                modalUserEmail: element.email,
                                modalUserCompanyId: element.companyId,
                                modalUserAdmin: element.admin,
                              })
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </ButtonIcon>
                          <ButtonIcon
                            small
                            style={{ color: "var(--primary-color" }}
                            onClick={() => this.handleDeleteUserClick(element)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </ButtonIcon>
                        </div>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                className="mt-10"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.tabLength}
                totalItemsCount={this.state.totalData}
                pageRangeDisplayed={4}
                onChange={this.handlePageChange}
                firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
                nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
              />
            </Card>
          </FadeIn>

          {/* ADD OR EDIT USER */}
          <ModalUser
            show={this.state.isModalUserAdd || this.state.isModalUserEdit}
            isEdit={this.state.isModalUserEdit}
            id={this.state.modalUserId}
            name={this.state.modalUserName}
            email={this.state.modalUserEmail}
            companyId={this.state.modalUserCompanyId}
            admin={this.state.modalUserAdmin}
            onClose={() =>
              this.setState({
                isModalUserAdd: false,
                isModalUserEdit: false,
                modalUserId: null,
                modalUserName: null,
                modalUserEmail: null,
                modalUserCompanyId: null,
                modalUserAdmin: null,
              })
            }
          />
        </>
      );
    } else {
      return <></>;
    }
  }
}
