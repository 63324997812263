import React, { Component } from "react";
import { Card, Table, Thead, Tbody, Tr, Th, Td } from "ui-kit-ck-consultant";
import { ExportCSV } from "react-export-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getPercentageValue, getCurrencyValue } from "../../utils/general";

export class DetailOfVolumetriesAndCost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownload: false,
    };
  }

  render() {
    let data = [];

    if (this.props.data.length) {
      this.props.data[0].forEach((month, idxRow) => {
        data.push({});
        this.props.data.forEach((element, idxCol) => {
          data[idxRow][idxCol] = element[idxRow];
        });
      });
    }

    return (
      <>
        <Card
          title="Détail des volumétries et coût"
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
          action={<FontAwesomeIcon icon={faDownload} />}
          onClick={() => this.setState({ isDownload: true })}
        >
          <Table>
            <Thead>
              <Tr>
                <Th rowSpan={2}>Mois</Th>
                <Th colSpan={5}>
                  {this.props.isElec ? "Électrique" : "Camping Car"}
                </Th>
                <Th colSpan={5}>
                  {this.props.isElec ? "Hybride" : "Caravanes"}
                </Th>
              </Tr>
              <Tr>
                <Th>Taux de VEI</Th>
                <Th>Taux EAD</Th>
                <Th>
                  Coût global du sinistre (Rép + Diff de valeurs VEI et RPV),
                  cumul
                </Th>
                <Th>Variation N-1 du coût global du sinistre</Th>
                <Th>Nbre dossiers VVNR Clôturés</Th>
                <Th>Taux de VEI</Th>
                <Th>Taux EAD</Th>
                <Th>
                  Coût global du sinistre (Rép + Diff de valeurs VEI et RPV),
                  cumul
                </Th>
                <Th>Variation N-1 du coût global du sinistre</Th>
                <Th>Nbre dossiers VVNR Clôturés</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data.length
                ? this.props.data[0].map((month, idxRow) => (
                    <Tr key={idxRow}>
                      {this.props.data.map((element, idxCol) => (
                        <Td key={idxCol}>
                          {[1, 2, 6, 7].includes(idxCol)
                            ? getPercentageValue(element[idxRow])
                            : [3, 4, 8, 9].includes(idxCol)
                            ? getCurrencyValue(element[idxRow])
                            : element[idxRow]}
                        </Td>
                      ))}
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </Card>
        {this.state.isDownload ? (
          <ExportCSV
            header={[
              {
                name: "Mois",
                key: "0",
              },
              {
                name: "Taux de VEI",
                key: "1",
              },
              {
                name: "Coût global du sinistre (Rép + Diff de valeurs VEI et RPV), cumul",
                key: "2",
              },
              {
                name: "Variation N-1 du coût global du sinistre",
                key: "3",
              },
              {
                name: "Nbre dossiers VVNR Clôturés",
                key: "4",
              },
              {
                name: "Taux de VEI",
                key: "5",
              },
              {
                name: "Coût global du sinistre (Rép + Diff de valeurs VEI et RPV), cumul",
                key: "6",
              },
              {
                name: "Variation N-1 du coût global du sinistre",
                key: "7",
              },
              {
                name: "Nbre dossiers VVNR Clôturés",
                key: "8",
              },
            ]}
            data={data}
            separator={";"}
            callback={() =>
              this.setState({
                isDownload: false,
              })
            }
            isDownload={true}
            filename={`Détail des volumétries et coût ${this.props.year}.csv`}
          />
        ) : null}
      </>
    );
  }
}

export default DetailOfVolumetriesAndCost;
