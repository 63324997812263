import React from "react";
import {
  Nav
} from "ui-kit-ck-consultant"

export default class Home extends React.Component {
  render() {
    return (
      <>
        <Nav title="Tableau de bord" />
      </>
    );
  }
}
