import React from "react";

import axiosConfig from "../../utils/axiosConfig";

export default class Signout extends React.Component {
    componentDidMount() {
        axiosConfig("/auth/signout").then(res => {
            document.location.href = document.location.origin;
        });
    }

    render() {
        return null;
    }
}