import React from "react";
import { 
  Card, 
  Button,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import AuditModal from "./AuditModal";

import "./declare.css";

export default class Declare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: ""
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showModal !== prevState.showModal && !this.state.showModal) {
      this.props.refreshData();
    }
  }

  render() {
    return (
      <>
        <Card className={this.props.className}>
          <Row>
            <Col xs={12} md={3} className={"mb-10"}>
              <Button
                outline
                className="declare_button"
                text="Nouvel audit interne technique terrain réparable"
                onClick={() => this.setState({ showModal: true, modalType: "field-expert" })}
              >
                <FontAwesomeIcon style={{ width: "40px" }} icon={faPlus} />
              </Button>
            </Col>
            <Col xs={12} md={3} className={"mb-10"}>
              <Button
                outline
                className="declare_button"
                text="Nouvel audit interne technique EAD"
                onClick={() => this.setState({ showModal: true, modalType: "ead" })}
              >
                <FontAwesomeIcon style={{ width: "40px" }} icon={faPlus} />
              </Button>
            </Col>
            <Col xs={12} md={3} className={"mb-10"}>
              <Button
                outline
                className="declare_button"
                text="Nouvel audit interne technique RIV/VEI"
                onClick={() => this.setState({ showModal: true, modalType: "riv-vei" })}
              >
                <FontAwesomeIcon style={{ width: "40px" }} icon={faPlus} />
              </Button>
            </Col>
            <Col xs={12} md={3} className={"mb-10"}>
              <Button
                outline
                className="declare_button"
                text="Nouvel audit interne technique VE"
                onClick={() => this.setState({ showModal: true, modalType: "ve" })}
              >
                <FontAwesomeIcon style={{ width: "40px" }} icon={faPlus} />
              </Button>
            </Col>
            <Col xs={12} md={3} className={"mb-10"}>
              <Button
                outline
                className="declare_button"
                text="Nouvel audit interne technique incohérence"
                onClick={() => this.setState({ showModal: true, modalType: "inconsistency" })}
              >
                <FontAwesomeIcon style={{ width: "40px" }} icon={faPlus} />
              </Button>
            </Col>
            <Col xs={12} md={3} className={"mb-10"}>
              <Button
                outline
                className="declare_button"
                text="Nouvel audit interne technique VVR"
                onClick={() => this.setState({ showModal: true, modalType: "vvr" })}
              >
                <FontAwesomeIcon style={{ width: "40px" }} icon={faPlus} />
              </Button>
            </Col>
            <Col xs={12} md={3} className={"mb-10"}>
              <Button
                outline
                className="declare_button"
                text="Nouvel audit interne technique incendie"
                onClick={() => this.setState({ showModal: true, modalType: "fire" })}
              >
                <FontAwesomeIcon style={{ width: "40px" }} icon={faPlus} />
              </Button>
            </Col> 
          </Row>
        </Card>

        <AuditModal
          showModal={this.state.showModal} 
          closeModal={() => this.setState({ showModal: false, modalType: "" })} 
          auditType={this.state.modalType}
        />        
      </>
    );
  }
}
