import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faThumbtack,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import {
  ButtonIcon,
  Card,
  Pagination,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import "./Reparator.css";

import AuthContext from "../../context/AuthContext";

export class GlobalMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
    };
    this.refColumnName = React.createRef();
    this.refColumnNbFolder = React.createRef();
    this.refColumnAverageCost = React.createRef();
    this.refColumnAge = React.createRef();
    this.refColumnHourlyRate = React.createRef();
    this.refColumnMissionDepot = React.createRef();
    this.refColumnPiecesCost = React.createRef();
    this.refColumnNbHours = React.createRef();
    this.refColumnNbHoursPaint = React.createRef();
    this.refColumnRP = React.createRef();
    this.refColumnPRE = React.createRef();
    this.refColumnCancelAndReplace = React.createRef();
    this.refColumnInvoideValidation = React.createRef();
    this.refColumnPrecision = React.createRef();
    this.refColumnEADRate = React.createRef();
  }

  static contextType = AuthContext;

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnNbFolder.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnAverageCost.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnAge.current.setState({ desc: false, sort: false });
    }
    if (column !== 4) {
      this.refColumnHourlyRate.current.setState({ desc: false, sort: false });
    }
    if (column !== 5) {
      this.refColumnMissionDepot.current.setState({ desc: false, sort: false });
    }
    if (column !== 6) {
      this.refColumnPiecesCost.current.setState({ desc: false, sort: false });
    }
    if (column !== 7) {
      this.refColumnNbHours.current.setState({ desc: false, sort: false });
    }
    if (column !== 8) {
      this.refColumnNbHoursPaint.current.setState({ desc: false, sort: false });
    }
    if (column !== 9) {
      this.refColumnRP.current.setState({ desc: false, sort: false });
    }
    if (column !== 10) {
      this.refColumnPRE.current.setState({ desc: false, sort: false });
    }
    if (column !== 11) {
      this.refColumnCancelAndReplace.current.setState({
        desc: false,
        sort: false,
      });
    }
    if (column !== 12) {
      this.refColumnInvoideValidation.current.setState({
        desc: false,
        sort: false,
      });
    }
    if (column !== 13) {
      this.refColumnPrecision.current.setState({ desc: false, sort: false });
    }
    if (column !== 14) {
      this.refColumnEADRate.current.setState({ desc: false, sort: false });
    }

    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "nbFolders", desc: desc };
        break;
      case 2:
        order = { name: "averageCost", desc: desc };
        break;
      case 3:
        order = { name: "age", desc: desc };
        break;
      case 4:
        order = { name: "hourlyRate", desc: desc };
        break;
      case 5:
        order = { name: "visitDepot", desc: desc };
        break;
      case 6:
        order = { name: "piecesCost", desc: desc };
        break;
      case 7:
        order = { name: "nbHours", desc: desc };
        break;
      case 8:
        order = { name: "nbHoursPaint", desc: desc };
        break;
      case 9:
        order = { name: "RP", desc: desc };
        break;
      case 10:
        order = { name: "PRE", desc: desc };
        break;
      case 11:
        order = { name: "tauxCancelAndReplace", desc: desc };
        break;
      case 12:
        order = { name: "tauxInvoiceValidation", desc: desc };
        break;
      case 13:
        order = { name: "", desc: desc };
        break;
      case 14:
        order = { name: "tauxEAD", desc: desc };
        break;
      default:
        order = { name: "nbFolders", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.props.onSortClick(order);
    });
  };

  render() {
    return (
      <FadeIn>
        <Card classNameChildren="overflow-x-auto">
          <div style={{ fontSize: "10px" }} className={"mb-20"}>
            <p>
              Genre véhicule "VP, CTTE"; Nature evenement : exclure "Force de la
              nature" et "Bris de glace"
            </p>
            <p>
              Total HT rapport "compris entre 20 et 5000"; Nature expertise
              exclure "I" et "3"
            </p>
          </div>
          <Table
            className="mb-10 "
            empty={
              this.props.data && this.props.data.length > 0
                ? ""
                : "Aucune donnée n'est disponible"
            }
          >
            <Thead>
              <Tr>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnName}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                >
                  Nom
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnNbFolder}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                >
                  Nb
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnAverageCost}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 2)}
                >
                  Rép. (€ HT)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnAge}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 3)}
                >
                  Âge (m)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnHourlyRate}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 4)}
                >
                  Tarif H. (€ HT)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnMissionDepot}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 5)}
                >
                  Délai dép. (j)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnPiecesCost}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 6)}
                >
                  Pièces (€ HT)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnNbHours}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 7)}
                >
                  Nb H (h)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnNbHoursPaint}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 8)}
                >
                  Nb HP (h)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnRP}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 9)}
                >
                  Tx RP (%)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnPRE}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 10)}
                >
                  Tx PRE (%)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnCancelAndReplace}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 11)}
                >
                  Tx A/R (%)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnInvoideValidation}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 12)}
                >
                  Valid. fac. (%)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnPrecision}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 13)}
                >
                  Précision (%)
                </Th>
                <Th
                  className="FontSize-10"
                  ref={this.refColumnEADRate}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 14)}
                >
                  Tx EAD (%)
                </Th>
                {(parseInt(this.context.role) === 1 ||
                  parseInt(this.context.role) === 2) &&
                !this.props.isPrint ? (
                  <Th className="FontSize-10">Actions</Th>
                ) : parseInt(this.context.type) !== 0 && !this.props.isPrint ? (
                  <Th className="FontSize-10">Suivis</Th>
                ) : null}
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data &&
                this.props.data.map((garage, idx) => {
                  return (
                    <Tr key={idx}>
                      <Td className="FontSize-10">{garage.name}</Td>
                      <Td className="FontSize-10">{garage.nbFolders}</Td>
                      <Td className="FontSize-10">{garage.averageCost}</Td>
                      <Td className="FontSize-10">{garage.age}</Td>
                      <Td className="FontSize-10">{garage.hourlyRate}</Td>
                      <Td className="FontSize-10">{garage.visitDepot}</Td>
                      <Td className="FontSize-10">{garage.piecesCost}</Td>
                      <Td className="FontSize-10">{garage.nbHours}</Td>
                      <Td className="FontSize-10">{garage.nbHoursPaint}</Td>
                      <Td className="FontSize-10">{garage.RP}</Td>
                      <Td className="FontSize-10">{garage.PRE}</Td>
                      <Td className="FontSize-10">
                        {garage.tauxCancelAndReplace}
                      </Td>
                      <Td className="FontSize-10">
                        {garage.tauxInvoiceValidation}
                      </Td>
                      <Td className="FontSize-10"></Td>
                      <Td className="FontSize-10">{garage.tauxEAD}</Td>
                      {!this.props.isPrint && this.props.agency ? (
                        <Td>
                          <ButtonIcon
                            onClick={() =>
                              this.props.onFollowGarage(
                                garage.id,
                                this.context.companyId
                              )
                            }
                          >
                            <FontAwesomeIcon
                              color={
                                this.props.garageIds &&
                                this.props.garageIds[this.context.companyId] &&
                                this.props.garageIds[
                                  this.context.companyId
                                ].includes(garage.id.toString())
                                  ? "green"
                                  : "gray"
                              }
                              icon={faThumbtack}
                            />
                          </ButtonIcon>
                        </Td>
                      ) : (
                        <Td>
                          {this.props.garageIds &&
                          this.props.garageIds[this.context.companyId] &&
                          this.props.garageIds[this.context.companyId].includes(
                            garage.id.toString()
                          ) ? (
                            <FontAwesomeIcon color="green" icon={faCheck} />
                          ) : (
                            ""
                          )}
                        </Td>
                      )}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
          <br />
          {this.props.isPrint ? null : (
            <Pagination
              activePage={this.props.activePage}
              itemsCountPerPage={this.props.tabLength}
              totalItemsCount={this.props.total}
              pageRangeDisplayed={4}
              onChange={(page) => {
                this.props.onPageChange(page);
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          )}
        </Card>
      </FadeIn>
    );
  }
}

export default GlobalMonitoring;
