import React from "react";
import {
    Card,
    Table, 
    Thead, 
    Tbody, 
    Tr, 
    Td, 
    Th,
    ButtonIcon,
    Pagination,
    PaginationClass,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faEdit,
  faTrashAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

import AuthContext from "../../context/AuthContext";
import AuditModal from "./AuditModal";

import axiosConfig from "../../utils/axiosConfig";

export default class AuditListCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audits: [],
            order: { name: "date", desc: true },
            // Pagination
            activePage: 1,
            tabLength: 20,
            totalData: 0,
            // Modal
            showModal: false,
            auditId: null,
            answers: {},
            modalTypeId: null,
            folder: null,
            groupdId: null,
            expertId: null,
            comment: null,
            financialImpact: null
        }
        this.refColumnDate = React.createRef();
        this.refColumnFolder = React.createRef();
        this.refColumnGroup = React.createRef();
        this.refColumnAgency = React.createRef();
        this.refColumnAuditType = React.createRef();
        this.refColumnScore = React.createRef();
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.PaginationClass = new PaginationClass(
            "audit/count",
            "audit/pagination",
            this.state.tabLength
        );
        this.initSearch();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dateStart !== prevProps.dateStart 
            ||this.props.dateEnd !== prevProps.dateEnd 
            ||this.props.groupId !== prevProps.groupId 
            || this.props.agencyId !== prevProps.agencyId 
            || this.props.bpce !== prevProps.bpce
        ) {
            this.initSearch();
        } else if (this.state.showModal !== prevState.showModal && !this.state.showModal) {
            this.props.refreshData();
            this.initSearch();
        }
    }

    initSearch = () => {
        this.setState({ activePage: 1 }, () => {
            this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
                if (result.success === true) {
                    this.setState({ totalData: result.total }, () => {
                        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
                            if (result.success === true) {
                                this.setState({ audits: result.tabData });
                            } else {
                                this.setState({ audits: [] })
                            }
                        });
                    });
                }
            })
        })
    }

    getData = () => {
        return {
            dateStart: this.props.dateStart,
            dateEnd: this.props.dateEnd,
            bpce: this.props.bpce ? this.props.bpce : null,
            groupId: this.props.groupId ? this.props.groupId : null,
            agencyId: this.props.agencyId ? this.props.agencyId : null,
            orderName: this.state.order ? this.state.order.name : null,
            orderDesc: this.state.order ? this.state.order.desc : null,
        }
    }

    handleSortClick = (desc, column) => {
        if (column !== 0) {
          this.refColumnDate.current.setState({ desc: false, sort: false });
        }
        if (column !== 1) {
          this.refColumnFolder.current.setState({ desc: false, sort: false });
        }
        if (column !== 2) {
          this.refColumnGroup.current.setState({ desc: false, sort: false });
        }
        if (column !== 3) {
          this.refColumnAgency.current.setState({ desc: false, sort: false });
        }
        if (column !== 4) {
            this.refColumnAuditType.current.setState({ desc: false, sort: false });
        }
        if (column !== 5) {
            this.refColumnScore.current.setState({ desc: false, sort: false });
        }
    
        let order = null;
    
        switch (column) {
            case 0:
                order = { name: "date", desc: desc }
                break;
            case 1:
                order = { name: "folder", desc: desc }
                break;
            case 2:
                order = { name: "group", desc: desc }
                break;
            case 3:
                order = { name: "agency", desc: desc }
                break;
            case 4:
                order = { name: "auditType", desc: desc }
                break;
            case 5:
                order = { name: "acore", desc: desc }
                break;
            default:
                order = { name: "date", desc: desc }
                break;
        }

        this.setState({ order: order }, () => {
            this.initSearch();
        })
      }

    handlePageChange = activePage => {
        this.PaginationClass.page(activePage, () => {
            this.setState({ activePage: activePage }, () => {
                this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
                    if (result.success === true) {
                        this.setState({ audits: result.tabData });
                    } else {
                        this.setState({ audits: [] })
                    }
                });
            });
        });
    }

    getColor = value => {
        if (value >= 90) {
            return "bg-green-light";
        } else if (value >= 80) {
            return "bg-orange-light";
        } else {
            return "bg-red-light";
        }
    }

    handleAuditDelete = (auditId, auditNb) => {
        if (window.confirm(`Êtes-vous sûr de vouloir supprimer l'audit #${auditNb} ?`)) {
            axiosConfig({
              url: "audit/delete-audit",
              data: {
                id: auditId
              }
            }).then(res => {
              if (res.data.success) {
                alert("L'audit a bien été supprimé");
                this.props.refreshData();
                this.initSearch();
              } else {
                alert("Erreur lors de la suppression");
              }
            });
          }
    }

    render() {
        return (
            <>
                <Card 
                    title={this.props.title} 
                    className="mb-20" 
                    classNameChildren="overflow-x-auto"
                >
                    <Table
                        empty={
                            this.state.audits.length > 0
                                ? ""
                                : "Aucune donnée n'est disponible"
                        }
                    >
                        <Thead>
                            <Tr>
                                <Th ref={this.refColumnDate} sort onSort={desc => this.handleSortClick(desc, 0)}>Date</Th>
                                <Th ref={this.refColumnFolder} sort onSort={desc => this.handleSortClick(desc, 1)}>N°sinistre</Th>
                                <Th ref={this.refColumnGroup} sort onSort={desc => this.handleSortClick(desc, 2)}>Groupe</Th>
                                <Th ref={this.refColumnAgency} sort onSort={desc => this.handleSortClick(desc, 3)}>Agence</Th>
                                <Th ref={this.refColumnAuditType} sort onSort={desc => this.handleSortClick(desc, 4)}>Type d'audit</Th>
                                <Th>Commentaire</Th>
                                <Th ref={this.refColumnScore} sort onSort={desc => this.handleSortClick(desc, 5)}>% d'atteinte</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {this.state.audits.map(element => (
                                <Tr key={element.id}>
                                    <Td className={this.getColor(element.score)}>{moment(element.date).format("DD/MM/YYYY")}</Td>
                                    <Td className={this.getColor(element.score)}>{element.folder}</Td>
                                    <Td className={this.getColor(element.score)}>{element.group}</Td>
                                    <Td className={this.getColor(element.score)}>{element.agency}</Td>
                                    <Td className={this.getColor(element.score)}>{element.auditType}</Td>
                                    <Td className={this.getColor(element.score)}>{element.comment}</Td>
                                    <Td className={this.getColor(element.score)}>{element.score}%</Td>
                                    <Td className={this.getColor(element.score)}>
                                        <div className="d-flex">
                                            <ButtonIcon
                                                small
                                                style={{ color: "var(--primary-color" }}
                                                onClick={() => this.setState({
                                                    showModal: true,
                                                    auditId: element.id,
                                                    answers: element.answers,
                                                    modalTypeId: element.auditTypeId,
                                                    folder: element.folder,
                                                    groupId: element.groupId,
                                                    expertId: element.agencyId,
                                                    comment: element.comment,
                                                    financialImpact: element.financialImpact
                                                })}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </ButtonIcon>
                                            <ButtonIcon
                                                small
                                                style={{ color: "var(--primary-color" }}
                                                onClick={() => this.handleAuditDelete(element.id, element.folder)}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </ButtonIcon>
                                        </div>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                    <Pagination
                        className="mt-10"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.tabLength}
                        totalItemsCount={this.state.totalData}
                        pageRangeDisplayed={4}
                        onChange={this.handlePageChange}
                        firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                        lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                        prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
                        nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
                    />
                </Card>

                <AuditModal
                    showModal={this.state.showModal} 
                    closeModal={() => this.setState({
                        showModal: false,
                        auditId: null,
                        answers: {},
                        modalTypeId: null,
                        folder: null,
                        groupdId: null,
                        expertId: null,
                        comment: null,
                        financialImpact: null
                    })} 
                    isEdit={true}
                    id={this.state.auditId}
                    answers={this.state.answers}
                    auditTypeId={this.state.modalTypeId}
                    folder={this.state.folder}
                    groupId={this.state.groupId}
                    expertId={this.state.expertId}
                    comment={this.state.comment}
                    financialImpact={this.state.financialImpact}
                />
            </>
        )
    }
}