import React from "react";
import {
    Card,
    Table, 
    Thead, 
    Tbody, 
    Tr, 
    Td, 
    Th,
    PieChart,
} from "ui-kit-ck-consultant";
import Podium from "../../components/general/Podium";
import { Row, Col } from "react-flexbox-grid";

export default class RankingCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: []
        }
    }

    componentDidMount() {
        this.setChartData();   
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setChartData();
        }            
    }

    setChartData = () => {
        let chartData = [
            {
                data: 0,
                labels: "90-100%",
                color: "#0c8540"
            },
            {
                data: 0,
                labels: "80-90%",
                color: "#f2711c"
            },
            {
                data: 0,
                labels: "- de 80%",
                color: "#db2828"
            }
        ];

        this.props.data.forEach(element => {
            if (element.score >= 90) {
                chartData[0].data++;
            } else if (element.score >= 80) {
                chartData[1].data++;
            } else {
                chartData[2].data++;
            }
        });

        this.setState({ chartData: chartData });
    }

    getColor = value => {
        if (value >= 90) {
            return "bg-green-light";
        } else if (value >= 80) {
            return "bg-orange-light";
        } else {
            return "bg-red-light";
        }
    }

    render() {
        return (
            <Card title={this.props.title} className="mb-20">
                {this.props.data.length > 0 ? (
                    <Row>
                        {/* CHARTS */}
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={4} className={"d-flex"}>
                                    <Podium
                                        className={"mt-auto ml-auto mr-auto mb-30 "}
                                        height={60}
                                        dataNumber={this.props.data[1] ? this.props.data[1].score : 0}
                                        dataText={this.props.data[1] ? this.props.title === "Groupes" ? this.props.data[1].group : this.props.data[1].agency : null}
                                        rank={2}
                                        format="percentage"
                                    />
                                </Col>
                                <Col xs={4} className={"d-flex"}>
                                    <Podium
                                        className={"mt-auto ml-auto mr-auto mb-30 "}
                                        height={70}
                                        dataNumber={this.props.data[0] ? this.props.data[0].score : 0}
                                        dataText={this.props.data[0] ? this.props.title === "Groupes" ? this.props.data[0].group : this.props.data[0].agency : null}
                                        rank={1}
                                        format="percentage"
                                    />
                                </Col>
                                <Col xs={4} className={"d-flex"}>
                                    <Podium
                                        className={"mt-auto ml-auto mr-auto mb-30 "}
                                        height={50}
                                        dataNumber={this.props.data[2] ? this.props.data[2].score : 0}
                                        dataText={this.props.data[2] ? this.props.title === "Groupes" ? this.props.data[2].group : this.props.data[2].agency : null}
                                        rank={3}
                                        format="percentage"
                                    />
                                </Col>
                                <Col xs={12} className="mb-20">
                                    <PieChart
                                        title={`Répartition des ${this.props.title}`}
                                        legendDisplay={true}
                                        digits={0}
                                        data={this.state.chartData}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        {/* TABLE */}
                        <Col xs={12} md={8}>
                            <div style={{ 
                                display: "inline-block", 
                                height: this.props.data.length > 15 ? "650px" : "auto", 
                                width: "100%", 
                                overflowY: "scroll" 
                            }}>
                                <Table 
                                    empty={
                                        this.props.data.slice(3).length > 0
                                            ? ""
                                            : "Aucune donnée n'est disponible"
                                    }
                                >
                                    <Thead>
                                        <Tr>
                                            <Th className="text-center">Classement</Th>
                                            <Th className="text-center">Groupe</Th>
                                            {this.props.title === "Agences" ? <Th className="text-center">Agences</Th> : null}
                                            <Th className="text-center">% d'atteinte</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {this.props.data.slice(3).map((element, idx) => (
                                            <Tr>
                                                <Td className="text-center">{idx + 4}</Td>
                                                <Td className="text-center">{element.group}</Td>
                                                {element.agency ? <Td className="text-center">{element.agency}</Td> : null}
                                                <Td className={`text-center ${this.getColor(element.score)}`}>{element.score}%</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                ) : "Aucune donnée n'est disponible"}
            </Card>
        )
    }
}