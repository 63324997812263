import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormRadio,
  ButtonIcon,
  Loader,
  FormCheckbox,
  Button,
  Pagination,
} from "ui-kit-ck-consultant";
import {
  faDownload,
  faSms,
  faEnvelope,
  faCheckCircle,
  faCheck,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { getCurrencyValue } from "../../../utils/general";
import axiosConfig from "../../../utils/axiosConfig";
import handleSortClick from "../../../utils/handleSortClick";

import AuthContext from "../../../context/AuthContext";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageLength: 20,
      loaderCount: 0,
      loaderTotal: 0,
      dataIdx: {},
      filterGarage: false,
      filterSms: false,
      filterCampingCar: false,
      filterMail: false,
      filterAnswer: false,
      filterCatnat: false,
      filterLevel1: false,
      filterLevel2: false,
      filterLevel3: false,
      order: null,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  static contextType = AuthContext;

  getStatus = (status, key) => {
    switch (status) {
      case 0:
        return (
          <Td key={key} className="pl-10 pr-10 nowrap bg-red-light text-center">
            Anomalie
          </Td>
        );
      case 1:
        return (
          <Td key={key} className="pl-10 pr-10 nowrap bg-red-light text-center">
            En attente de RDV
          </Td>
        );
      case 2:
        return (
          <Td
            key={key}
            className="pl-10 pr-10 nowrap bg-orange-light text-center"
          >
            Expertise à venir
          </Td>
        );
      case 3:
        return (
          <Td
            key={key}
            className="pl-10 pr-10 nowrap bg-blue-light text-center"
          >
            Expertisé
          </Td>
        );
      case 4:
        return (
          <Td
            key={key}
            className="pl-10 pr-10 nowrap bg-green-light text-center"
          >
            Clôturé sans expertise
          </Td>
        );
      case 5:
        return (
          <Td
            key={key}
            className="pl-10 pr-10 nowrap bg-green-light text-center"
          >
            Clôturé avec expertise
          </Td>
        );
      default:
        break;
    }
  };

  sendSmsCatnatManageEGALocation = (idx = 0) => {
    this.setState(
      {
        loaderCount: Object.keys(this.state.dataIdx).length - idx,
        loaderTotal: Object.keys(this.state.dataIdx).length,
      },
      () => {
        axiosConfig("EGA/send-sms-catnat-manage-ega-location", {
          data: {
            folderId:
              this.props.data.body[
                this.getIdx(Object.keys(this.state.dataIdx)[idx])
              ].id,
            phone:
              this.props.data.body[
                this.getIdx(Object.keys(this.state.dataIdx)[idx])
              ].phone,
          },
        }).then((res) => {
          if (this.state.loaderCount > 1) {
            setTimeout(() => {
              this.sendSmsCatnatManageEGALocation(idx + 1);
            }, 200);
          } else {
            this.setState(
              {
                loaderCount: 0,
              },
              () => {
                this.props.getData();
              }
            );
          }
        });
      }
    );
  };

  sendSmsQuotation = (idx = 0) => {
    this.setState(
      {
        loaderCount: Object.keys(this.state.dataIdx).length - idx,
        loaderTotal: Object.keys(this.state.dataIdx).length,
      },
      () => {
        axiosConfig("EGA/send-sms-quotation", {
          data: {
            folderId:
              this.props.data.body[
                this.getIdx(Object.keys(this.state.dataIdx)[idx])
              ].id,
            phone:
              this.props.data.body[
                this.getIdx(Object.keys(this.state.dataIdx)[idx])
              ].phone,
          },
        }).then((res) => {
          if (this.state.loaderCount > 1) {
            setTimeout(() => {
              this.sendSmsQuotation(idx + 1);
            }, 200);
          } else {
            this.setState(
              {
                loaderCount: 0,
              },
              () => {
                this.props.getData();
              }
            );
          }
        });
      }
    );
  };

  sendSmsManageEGALocation = (idx = 0) => {
    this.setState(
      {
        loaderCount: Object.keys(this.state.dataIdx).length - idx,
        loaderTotal: Object.keys(this.state.dataIdx).length,
      },
      () => {
        axiosConfig("EGA/send-sms-manage-ega-location", {
          data: {
            folderId:
              this.props.data.body[
                this.getIdx(Object.keys(this.state.dataIdx)[idx])
              ].id,
            phone:
              this.props.data.body[
                this.getIdx(Object.keys(this.state.dataIdx)[idx])
              ].phone,
            dataId: Object.keys(this.props.dataId).map((element) => element),
          },
        }).then((res) => {
          if (this.state.loaderCount > 1) {
            setTimeout(() => {
              this.sendSmsManageEGALocation(idx + 1);
            }, 200);
          } else {
            this.setState(
              {
                loaderCount: 0,
              },
              () => {
                this.props.getData();
              }
            );
          }
        });
      }
    );
  };

  sendMailManageEGALocation = (idx = 0) => {
    this.setState(
      {
        loaderCount: Object.keys(this.state.dataIdx).length - idx,
        loaderTotal: Object.keys(this.state.dataIdx).length,
      },
      () => {
        axiosConfig("EGA/send-mail-manage-ega-location", {
          data: {
            folderId:
              this.props.data.body[
                this.getIdx(Object.keys(this.state.dataIdx)[idx])
              ].id,
            mail: this.props.data.body[
              this.getIdx(Object.keys(this.state.dataIdx)[idx])
            ].mail,

            dataId: Object.keys(this.props.dataId).map((element) => element),
          },
        }).then((res) => {
          if (this.state.loaderCount > 1) {
            this.sendMailManageEGALocation(idx + 1);
          } else {
            this.setState(
              {
                loaderCount: 0,
              },
              () => {
                this.props.getData();
              }
            );
          }
        });
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      this.setState({ dataIdx: {} });
    }
  }

  checkExistRegion = (region) => {
    if (this.props.dataLocation && this.props.dataLocation.length) {
      const result = this.props.dataLocation.filter(
        (location) => region === location.region && location.status
      );
      return !!result.length;
    } else {
      return false;
    }
  };

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  getIdx = (id) => {
    let result = -1;

    this.props.data.body.forEach((element, idx) => {
      if (element.id && id && element.id.toString() === id.toString()) {
        result = idx;
      }
    });

    return result;
  };

  checkIfIncludes = (model) => {
    const models = [
      "DUCATO",
      "SPRINTER",
      "DAILY",
      "MASTER",
      "JUMPER",
      "BOXER",
      "CALIFORNIA",
      "MULTIVAN",
      "TRANSPORTER",
      "CRAFTER",
    ];

    if (!model) {
      return true;
    } else {
      return !models.filter((element) => model.includes(element)).length;
    }
  };

  render() {
    let dataBody = this.props.data.body.filter(
      (element) =>
        ([0, 1, 2].includes(element.status) &&
          (element.countSmsCatnat === 0 || !this.state.filterCatnat) &&
          (!element.garageName || !this.state.filterGarage) &&
          (element.countSms === 0 || !this.state.filterSms) &&
          (this.checkIfIncludes(element.model) ||
            !this.state.filterCampingCar) &&
          (element.countMail === 0 || !this.state.filterMail) &&
          (!element.egaDateAppointment || !this.state.filterAnswer) &&
          ((parseInt(element.list5Rubrique3) === 1 &&
            this.state.filterLevel1) ||
            (parseInt(element.list5Rubrique3) === 2 &&
              this.state.filterLevel2) ||
            (parseInt(element.list5Rubrique3) === 3 &&
              this.state.filterLevel3) ||
            (!this.state.filterLevel1 &&
              !this.state.filterLevel2 &&
              !this.state.filterLevel3))) ||
        !this.props.isSms
    );

    if (this.state.order) {
      dataBody.sort((a, b) => {
        let aX = a[this.state.order.name] || "";
        let bX = b[this.state.order.name] || "";

        if (Number.isInteger(aX) && Number.isInteger(bX)) {
          console.log(aX, bX);
          return this.state.order.desc ? aX - bX : bX - aX;
        } else {
          aX = aX.toString();
          bX = bX.toString();
          return this.state.order.desc
            ? aX.normalize().localeCompare(bX.normalize())
            : bX.normalize().localeCompare(aX.normalize());
        }
      });
    }

    return (
      <>
        <Card
          title={`Liste des dossiers (${dataBody.length} dossiers)${
            Object.keys(this.state.dataIdx).length
              ? ` (${Object.keys(this.state.dataIdx).length} sélectionnés)`
              : ""
          }`}
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
          action={
            <div className="nowrap w-100 d-flex">
              {this.props.isSms ? (
                <ButtonIcon
                  className="mr-10 bg-green"
                  isBackground
                  onClick={() => {
                    let dataIdx = {};
                    dataBody.forEach((element) => {
                      dataIdx[element.id] = true;
                    });
                    this.setState({ dataIdx });
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} />
                </ButtonIcon>
              ) : null}
              {this.props.isSms ? (
                <ButtonIcon
                  className="mr-10 bg-red"
                  isBackground
                  onClick={() => {
                    let dataIdx = {};
                    this.props.data.body.forEach((element, idx) => {
                      if ([0, 1, 2].includes(element.status)) {
                        delete dataIdx[element.id];
                      }
                    });
                    this.setState({ dataIdx });
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} />
                </ButtonIcon>
              ) : null}
              {this.props.isSms ? (
                <ButtonIcon
                  className="mr-10"
                  disabled={
                    !Object.keys(this.props.dataId).length ||
                    !Object.keys(this.state.dataIdx).filter(
                      (idx) => this.state.dataIdx[idx]
                    ).length
                  }
                  isBackground
                  onClick={() =>
                    window.confirm(
                      "Confirmez vous l'envoi de sms dans tous les dossiers sélectionnés pour toutes les plateformes actives ?"
                    )
                      ? this.sendSmsManageEGALocation()
                      : null
                  }
                >
                  <FontAwesomeIcon icon={faSms} />
                </ButtonIcon>
              ) : null}
              {this.props.isSms ? (
                <ButtonIcon
                  className="mr-10"
                  disabled={
                    !Object.keys(this.props.dataId).length ||
                    !Object.keys(this.state.dataIdx).filter(
                      (idx) => this.state.dataIdx[idx]
                    ).length
                  }
                  isBackground
                  onClick={() =>
                    window.confirm(
                      "Confirmez vous l'envoi de mail dans tous les dossiers sélectionnés pour toutes les plateformes actives ?"
                    )
                      ? this.sendMailManageEGALocation()
                      : null
                  }
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </ButtonIcon>
              ) : null}
              {this.props.isSms ? (
                <Button
                  className="w-auto mr-10"
                  text="SMS DEVIS REP"
                  disabled={
                    !Object.keys(this.state.dataIdx).filter(
                      (idx) => this.state.dataIdx[idx]
                    ).length
                  }
                  onClick={() =>
                    window.confirm(
                      "Confirmez vous l'envoi de sms DEVIS REP dans tous les dossiers sélectionnés pour toutes les plateformes actives ?"
                    )
                      ? this.sendSmsQuotation()
                      : null
                  }
                >
                  <FontAwesomeIcon icon={faSms} />
                </Button>
              ) : null}
              {this.props.isSms ? (
                <Button
                  className="w-auto mr-10"
                  text="SMS CATNAT"
                  disabled={
                    !Object.keys(this.state.dataIdx).filter(
                      (idx) => this.state.dataIdx[idx]
                    ).length
                  }
                  onClick={() =>
                    window.confirm(
                      "Confirmez vous l'envoi de sms CATNAT dans tous les dossiers sélectionnés pour toutes les plateformes actives ?"
                    )
                      ? this.sendSmsCatnatManageEGALocation()
                      : null
                  }
                >
                  <FontAwesomeIcon icon={faSms} />
                </Button>
              ) : null}
              <ButtonIcon
                isBackground
                onClick={() => this.props.handleDownloadCSV()}
                info="Télécharger la liste au format CSV"
              >
                <FontAwesomeIcon icon={faDownload} />
              </ButtonIcon>
              {this.context.companyId === 10 ? (
                <ButtonIcon
                  className="ml-10"
                  isBackground
                  onClick={() => this.props.handleDownloadCSV_GAN()}
                  info="(GAN) Télécharger la liste au format CSV"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </ButtonIcon>
              ) : null}
            </div>
          }
          disableStyleAction
        >
          {!this.props.isSms ? (
            <>
              <div className="d-flex mb-10">
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderType === "all" ? true : false}
                  name={"folderType"}
                  text={"Tous"}
                  value={"all"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderType === "R" ? true : false}
                  name={"folderType"}
                  text={"Réparables"}
                  value={"R"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderType === "NR" ? true : false}
                  name={"folderType"}
                  text={"Non réparables"}
                  value={"NR"}
                  onChange={this.props.handleInputChange}
                />
              </div>
              <div className="d-flex mb-10">
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderStatus === "all" ? true : false}
                  name={"folderStatus"}
                  text={"Tous"}
                  value={"all"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderStatus === "new" ? true : false}
                  name={"folderStatus"}
                  text={"En attente de RDV"}
                  value={"new"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderStatus === "visit" ? true : false}
                  name={"folderStatus"}
                  text={"Expertise à venir"}
                  value={"visit"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderStatus === "visitOK" ? true : false}
                  name={"folderStatus"}
                  text={"Expertisé"}
                  value={"visitOK"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={
                    this.props.folderStatus === "noVisited" ? true : false
                  }
                  name={"folderStatus"}
                  text={"Clôturé sans expertise"}
                  value={"noVisited"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderStatus === "closing" ? true : false}
                  name={"folderStatus"}
                  text={"Clôturé avec expertise"}
                  value={"closing"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderStatus === "error" ? true : false}
                  name={"folderStatus"}
                  text={"Anomalie"}
                  value={"error"}
                  onChange={this.props.handleInputChange}
                />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex mb-10">
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderStatus === "all" ? true : false}
                  name={"folderStatus"}
                  text={"Tous"}
                  value={"all"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderStatus === "new" ? true : false}
                  name={"folderStatus"}
                  text={"En attente de RDV"}
                  value={"new"}
                  onChange={this.props.handleInputChange}
                />
                <FormRadio
                  className="mr-10"
                  checked={this.props.folderStatus === "visit" ? true : false}
                  name={"folderStatus"}
                  text={"Expertise à venir"}
                  value={"visit"}
                  onChange={this.props.handleInputChange}
                />
              </div>
              <div className="d-flex mb-10">
                <FormCheckbox
                  text={"Filtre no réparateur"}
                  className="mr-10"
                  checked={this.state.filterGarage}
                  onChange={({ target }) =>
                    this.setState({ filterGarage: target.checked })
                  }
                />
                <FormCheckbox
                  text={"Filtre no SMS"}
                  className="mr-10"
                  checked={this.state.filterSms}
                  onChange={({ target }) =>
                    this.setState({ filterSms: target.checked })
                  }
                />
                <FormCheckbox
                  text={"Filtre no Mail"}
                  className="mr-10"
                  checked={this.state.filterMail}
                  onChange={({ target }) =>
                    this.setState({ filterMail: target.checked })
                  }
                />
                <FormCheckbox
                  text={"Filtre no CAMPING-CAR"}
                  className="mr-10"
                  checked={this.state.filterCampingCar}
                  onChange={({ target }) =>
                    this.setState({ filterCampingCar: target.checked })
                  }
                />
                <FormCheckbox
                  text={"Filtre no Réponse"}
                  className="mr-10"
                  checked={this.state.filterAnswer}
                  onChange={({ target }) =>
                    this.setState({ filterAnswer: target.checked })
                  }
                />
                <FormCheckbox
                  text={"Filtre no CATNAT"}
                  className="mr-10"
                  checked={this.state.filterCatnat}
                  onChange={({ target }) =>
                    this.setState({ filterCatnat: target.checked })
                  }
                />
                <FormCheckbox
                  text={"Filtre niveau 1"}
                  className="mr-10"
                  checked={this.state.filterLevel1}
                  onChange={({ target }) =>
                    this.setState({ filterLevel1: target.checked })
                  }
                />
                <FormCheckbox
                  text={"Filtre niveau 2"}
                  className="mr-10"
                  checked={this.state.filterLevel2}
                  onChange={({ target }) =>
                    this.setState({ filterLevel2: target.checked })
                  }
                />
                <FormCheckbox
                  text={"Filtre niveau 3"}
                  className="mr-10"
                  checked={this.state.filterLevel3}
                  onChange={({ target }) =>
                    this.setState({ filterLevel3: target.checked })
                  }
                />
              </div>
            </>
          )}
          <Table>
            <Thead>
              <Tr>
                {this.props.data &&
                this.props.data.head &&
                this.props.data.head.length ? (
                  <>
                    {this.props.data.head.map((element, idx) => {
                      if (
                        (this.props.isSms &&
                          [
                            "natureExpertiseName",
                            "totalReportDutyFree",
                            "VRADEDutyFree",
                            "dateClosing",
                          ].includes(element.key)) ||
                        [
                          "levelIxperience",
                          "dateAccident",
                          "referenceSinister",
                          "licensePlate",
                        ].includes(element.key)
                      ) {
                        return null;
                      }

                      return (
                        <Th
                          className="pl-10 pr-10"
                          key={idx}
                          ref={this.sortRef[idx]}
                          sort={true}
                          onSort={(desc) => this.handleSortClick(desc, idx)}
                          nameSort={element.key}
                        >
                          {element.name}
                        </Th>
                      );
                    })}
                    {this.props.isSms ? (
                      <>
                        <Th
                          className="pl-10 pr-10"
                          ref={this.sortRef[this.props.data.head.length]}
                          sort={true}
                          onSort={(desc) =>
                            this.handleSortClick(
                              desc,
                              this.props.data.head.length
                            )
                          }
                          nameSort={"egaDateAppointment"}
                        >
                          Réponse
                        </Th>
                        <Th
                          className="pl-10 pr-10"
                          ref={this.sortRef[this.props.data.head.length + 1]}
                          sort={true}
                          onSort={(desc) =>
                            this.handleSortClick(
                              desc,
                              this.props.data.head.length + 1
                            )
                          }
                          nameSort={"countSmsCatnat"}
                        >
                          SMS CAT.
                        </Th>
                        <Th
                          className="pl-10 pr-10"
                          ref={this.sortRef[this.props.data.head.length + 2]}
                          sort={true}
                          onSort={(desc) =>
                            this.handleSortClick(
                              desc,
                              this.props.data.head.length + 2
                            )
                          }
                          nameSort={"countSms"}
                        >
                          SMS
                        </Th>
                        <Th
                          className="pl-10 pr-10"
                          ref={this.sortRef[this.props.data.head.length + 3]}
                          sort={true}
                          onSort={(desc) =>
                            this.handleSortClick(
                              desc,
                              this.props.data.head.length + 3
                            )
                          }
                          nameSort={"countMail"}
                        >
                          Mail
                        </Th>
                        <Th className="pl-10 pr-10">Actions</Th>
                      </>
                    ) : null}
                  </>
                ) : (
                  <></>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {dataBody
                .slice(
                  (this.state.activePage - 1) * this.state.pageLength,
                  this.state.activePage * this.state.pageLength
                )
                .map((element, idx) => (
                  <Tr key={element.id}>
                    {this.props.data.head.map((subElement, subIdx) => {
                      if (
                        (this.props.isSms &&
                          [
                            "natureExpertiseName",
                            "totalReportDutyFree",
                            "VRADEDutyFree",
                            "dateClosing",
                          ].includes(subElement.key)) ||
                        [
                          "levelIxperience",
                          "dateAccident",
                          "referenceSinister",
                          "licensePlate",
                        ].includes(subElement.key)
                      ) {
                        return null;
                      }

                      if (subElement.type === "currency") {
                        return (
                          <Td className="pl-10 pr-10" key={subIdx}>
                            {element[subElement.key]
                              ? getCurrencyValue(element[subElement.key])
                              : ""}
                          </Td>
                        );
                      } else if (subElement.type === "date") {
                        return (
                          <Td className="pl-10 pr-10" key={subIdx}>
                            {element[subElement.key]
                              ? moment(element[subElement.key]).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </Td>
                        );
                      } else if (subElement.type === "status") {
                        return this.getStatus(element[subElement.key], subIdx);
                      } else {
                        return (
                          <Td className="pl-10 pr-10" key={subIdx}>
                            {element[subElement.key]}
                          </Td>
                        );
                      }
                    })}
                    {this.props.isSms ? (
                      <>
                        <Td className="pl-10 pr-10">
                          {element.egaDateAppointment
                            ? `OUI ${element.egaDateAppointment} à ${element.egaHourAppointment}`
                            : "NON"}
                        </Td>
                        <Td className="pl-10 pr-10">
                          {element.countSmsCatnat}
                        </Td>
                        <Td className="pl-10 pr-10">{element.countSms}</Td>
                        <Td className="pl-10 pr-10">{element.countMail}</Td>
                        <Td className="pl-10 pr-10">
                          <div className="d-flex">
                            {(element.phone || element.mail) &&
                            this.checkExistRegion(element.region) &&
                            !element.egaDateAppointment ? (
                              <FormCheckbox
                                className="m-auto ml-0"
                                checked={
                                  this.state.dataIdx[element.id]
                                    ? this.state.dataIdx[element.id]
                                    : false
                                }
                                onChange={({ target }) => {
                                  let dataIdx = this.state.dataIdx;
                                  if (target.checked) {
                                    dataIdx[element.id] = target.checked;
                                  } else {
                                    delete dataIdx[element.id];
                                  }
                                  this.setState({ dataIdx });
                                }}
                              />
                            ) : null}
                            <ButtonIcon
                              className="blue"
                              small
                              onClick={() =>
                                window.open(
                                  `https://ega.pilotagecabinet.fr/${element.id}/0/${this.context.id}`
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </ButtonIcon>
                          </div>
                        </Td>
                      </>
                    ) : null}
                  </Tr>
                ))}
            </Tbody>
          </Table>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageLength}
            totalItemsCount={dataBody.length}
            pageRangeDisplayed={4}
            onChange={(activePage) => {
              this.setState({ activePage: activePage });
            }}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        <Loader
          show={this.state.loaderCount > 0}
          message={`${this.state.loaderTotal - this.state.loaderCount}/${
            this.state.loaderTotal
          }`}
        />
      </>
    );
  }
}
