import React, { Component } from "react";
import { Card, Table, Thead, Tbody, Tr, Th, Td } from "ui-kit-ck-consultant";
import { ExportCSV } from "react-export-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getValue, getPercentageValue } from "../../utils/general";

export class AverageAgeOfCurrentCases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownload: false,
    };
  }

  render() {
    let data = [];

    if (this.props.data.length) {
      this.props.data[0].forEach((month, idxRow) => {
        data.push({});
        this.props.data.forEach((element, idxCol) => {
          data[idxRow][idxCol] = element[idxRow];
        });
      });
    }

    return (
      <>
        <Card
          title="Age moyen des dossiers en cours"
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
          action={<FontAwesomeIcon icon={faDownload} />}
          onClick={() => this.setState({ isDownload: true })}
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Mois</Th>
                <Th>Stock en début du mois</Th>
                <Th>Age moyen du stock en début de mois (en nbre de jours)</Th>
                <Th>% de dossiers de moins de 30 jours</Th>
                <Th>% de dossiers de moins de 60 jours</Th>
                <Th>% de dossiers de moins de 90 jours</Th>
                <Th>% de dossiers de plus de 90 jours</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data.length
                ? this.props.data[0].map((month, idxRow) => (
                    <Tr key={idxRow}>
                      {this.props.data.map((element, idxCol) => (
                        <Td key={idxCol}>
                          {[3, 4, 5, 6].includes(idxCol)
                            ? getPercentageValue(element[idxRow])
                            : [2].includes(idxCol)
                            ? getValue(element[idxRow])
                            : element[idxRow]}
                        </Td>
                      ))}
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </Card>
        {this.state.isDownload ? (
          <ExportCSV
            header={[
              { name: "Mois", key: "0" },
              { name: "Stock en début du mois", key: "1" },
              {
                name: "Age moyen du stock en début de mois (en nbre de jours)",
                key: "2",
              },
              { name: "% de dossiers de moins de 30 jours", key: "3" },
              { name: "% de dossiers de moins de 60 jours", key: "4" },
              { name: "% de dossiers de moins de 90 jours", key: "5" },
              { name: "% de dossiers de plus de 90 jours", key: "6" },
            ]}
            data={data}
            separator={";"}
            callback={() =>
              this.setState({
                isDownload: false,
              })
            }
            isDownload={true}
            filename={`Age moyen des dossiers en cours ${this.props.year}.csv`}
          />
        ) : null}
      </>
    );
  }
}

export default AverageAgeOfCurrentCases;
