import React from "react";
import {
  Card,
  BarChart,
  DoughnutChart
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

export default class GraphCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSet: []
        }
    }

    componentDidMount() {
        this.setData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dataSet !== prevProps.dataSet) {
            this.setData();
        }
    }

    setData = () => {
        let dataSet = [];
        this.setState({
            dataSet
        }, () => {
            if (this.props.goal) {
                this.props.dataSet.forEach((element, idx) => {
                    let color = this.props.dataSet[0].data[this.props.year === new Date().getFullYear() 
                        ? new Date().getMonth() 
                        : element.length - 1] >= this.props.goal
                    ? idx === 0 ? "green" : "yellowgreen"
                    : idx === 0 ? "red" : "pink";
    
                    dataSet.push({
                        ...element,
                        color: color
                    });
                });
                dataSet.push({
                    type: "line",
                    data: Array(12).fill(this.props.goal),
                    color: "steelblue",
                    label: "Objectif %",
                    yAxisID: "A"
                });
            } else {
                dataSet = this.props.dataSet;
            }
            setTimeout(() => {
                this.setState({ dataSet });
            }, 500)
        })
    }

    render() {
        return (
            <>
                <Card
                    title={this.props.title} 
                    className="mb-20" 
                >
                    <Row>
                        <Col xs={12} md={this.props.average && this.props.average >= 0 ? 9 : 12}>
                            <BarChart
                                height={this.props.height ? this.props.height : 250}
                                isLegend={true}
                                digits={0}
                                dataSet={this.state.dataSet}
                                yAxes={[
                                    {
                                        id: "A",
                                        type: "linear",
                                        position: "left",
                                        ticks: {
                                            display: true,
                                            min: 0,
                                            beginAtZero: true
                                        },
                                        gridLines: {
                                            display: true
                                        }
                                    },
                                    {
                                        id: "B",
                                        type: "linear",
                                        position: "right",
                                        ticks: {
                                            display: this.props.secondYAxis ? true : false,
                                            min: 0,
                                            beginAtZero: true
                                        },
                                        gridLines: {
                                            display: false
                                        }
                                    }
                                ]}
                            />
                        </Col>
                        {this.props.average && this.props.average >= 0 ? (
                            <Col
                                xs={12}
                                md={3}
                                className="d-flex"
                                style={{ alignItems: "center", flexDirection: "column" }}
                            >
                                <DoughnutChart
                                    number={parseInt(this.props.average)}
                                    max={100}
                                    text="en moyenne"
                                    />
                                <span
                                    className="mt-20 p-10"
                                    style={{ fontSize: "14px", backgroundColor: "whitesmoke", borderRadius: "10px" }}
                                >{`${this.props.total} dossiers`}</span>
                            </Col>
                        ) : null}
                    </Row>

                    {this.props.title === "Nombre de dossiers" ? (
                    <div style={{ fontSize: "10px" }} className="mb-20">
                        <p>* Nbe Réparables Agréés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés</p>
                        <p>* Nbe ead : Nature expertise inclure "J" ; Agréés</p>
                        <p>* Nbe Selfcare Lancés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés ; Selfcare vrai</p>
                        <p>* Nbe Selfcare Ok : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés ; EAD soc vrai</p>
                        <p>* Nbe Réparables Agréés + Non Agréés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q"</p>
                    </div>
                    ) : this.props.title === "% EAD / Réparables Agréés" ? (
                    <div style={{ fontSize: "10px" }} className="mb-20">
                        <p>* EAD : Nature expertise inclure "J" ; Agréés</p>
                        <p>* Réparables Agréés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés</p>
                    </div>
                    ) : this.props.title === "% Selfcare Lancés / Réparables Agréés" ? (
                    <div style={{ fontSize: "10px" }} className="mb-20">
                        <p>* Selfcare Lancés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés ; Selfcare vrai</p>
                        <p>* Réparables Agréés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés</p>
                    </div>
                    ) : this.props.title === "% Selfcare Validés / Réparables Agréés" ? (
                    <div style={{ fontSize: "10px" }} className="mb-20">
                        <p>* Selfcare Validés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés ; EAD soc vrai</p>
                        <p>* Réparables Agréés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés</p>
                    </div>
                    ) : this.props.title === "% Réussite Selfcare" ? (
                    <div style={{ fontSize: "10px" }} className="mb-20">
                        <p>* Taux Réussite Selfcare = (Selfcare Validés / Selfcare Lancés) * 100</p>
                        <p>* Selfcare Validés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés ; EAD soc vrai</p>
                        <p>* Selfcare Lancés : Nature expertise inclure "1" ou "6" ou "A" ou "C" ou "J" ou "Q" ; Agréés ; Selfcare vrai</p>
                    </div>
                    ) : null}
                </Card>
            </>
        )
    }
}
