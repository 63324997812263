import React from "react";
import {
  Nav,
  Loader,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  FormInput,
  FormSelect,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../../context/AuthContext";
import ContactRequest from "../../../components/general/ContactRequest";
import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";
import { getCurrencyValue } from "../../../utils/general";

import "../../../index.css";

export default class IRSA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: true,
      isLoad: false,
      isDownload: false,
      // data search
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 1,
      group: 1,
      region: 29,
      agency: 186,
      data: [],
      sinister: "",
      code: "",
    };

    this.sortRef = [];
  }

  static contextType = AuthContext;

  handleRadioChange = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      this.getGeneralData
    );
  };

  componentDidMount() {
    this.setState(
      {
        accessAllowed: checkRights("StatisticsIRSA", this.context),
      },
      () => this.getData()
    );
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    let dateStart = this.state.dateStart;
    let dateEnd = this.state.dateEnd;

    if (
      name === "dateEnd" &&
      moment(value).format("YYYY") !== moment(dateStart).format("YYYY")
    ) {
      dateStart = moment(`${moment(value).format("YYYY")}-01-01`).format(
        "YYYY-MM-DD"
      );
    } else if (
      name === "dateStart" &&
      moment(value).format("YYYY") !== moment(dateEnd).format("YYYY")
    ) {
      dateEnd = moment(`${moment(value).format("YYYY")}-12-31`).format(
        "YYYY-MM-DD"
      );
    }

    this.setState({
      dateStart,
      dateEnd,
      [name]: value,
    });
  };

  getData = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("pilotage-cabinet/specialties/irsa-maif/get-data", {
        data: {
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              data: res.data.data,
              isLoad: false,
            });
          } else {
            this.setState({
              data: [],
              isLoad: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoad: false });
        });
    });
  };

  render() {
    let codesUniques = [];

    let codes = this.state.data.filter((folder) => {
      if (!codesUniques.includes(folder.code)) {
        codesUniques.push(folder.code);
        return true;
      }
      return false;
    });

    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Statistiques IRSA" className="mb-20" />
          <ContactRequest page="Statistiques - IRSA" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Statistiques IRSA" className="mb-20" />
          </FadeIn>
          <ShowOptions
            handleInputChange={this.handleInputChange}
            handleSelectChange={this.handleInputChange}
            getData={this.getData}
            isDateStart={true}
            isDateEnd={true}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
            network={this.state.network}
            group={this.state.group}
            region={this.state.region}
            agency={this.state.agency}
            className={"mb-10 mb-20-xl"}
            footerData={
              <Row>
                <Col xs={12} md={4} lg={3}>
                  <FormInput
                    title="N° sinistre"
                    name="sinister"
                    value={this.state.sinister}
                    onChange={(e) =>
                      this.setState({ sinister: e.target.value })
                    }
                  />
                </Col>

                <Col xs={12} md={4} lg={3}>
                  <FormSelect
                    title="État"
                    name="code"
                    value={this.state.code}
                    ignore={true}
                    options={[
                      { value: "", text: "Filtrage par état" },
                      ...codes.map((code) => ({
                        value: code.code,
                        text: code.code,
                      })),
                    ]}
                    onChange={(e) => this.setState({ code: e.target.value })}
                  />
                </Col>
              </Row>
            }
          />
          <Card title="Tableau" className="overflow-x-auto">
            <Table
              isCSV
              getAllData={(callback) =>
                this.setState({ isDownload: true }, callback)
              }
              csvCallback={() => this.setState({ isDownload: false })}
            >
              <Thead>
                <Tr>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th
                    colSpan={this.state.isDownload ? 1 : 3}
                    style={{
                      borderRadius: "6px",
                    }}
                  >
                    Montant objet de la réclamation Vrade ou Réparation
                  </Th>
                  {this.state.isDownload ? <Th hide></Th> : null}
                  {this.state.isDownload ? <Th hide></Th> : null}
                  <Th
                    colSpan={this.state.isDownload ? 1 : 3}
                    style={{
                      borderRadius: "6px",
                    }}
                  >
                    Vos conclusions
                  </Th>
                  {this.state.isDownload ? <Th hide></Th> : null}
                  {this.state.isDownload ? <Th hide></Th> : null}
                  <Th hide></Th>
                  <Th hide></Th>
                </Tr>
                <Tr>
                  <Th>#</Th>
                  <Th>Nom du groupement</Th>
                  <Th>Nom Expert</Th>
                  <Th>N° dossier Expert</Th>
                  <Th>N° sinistre MAIF</Th>
                  <Th>Date de réception mission</Th>
                  <Th>Etat du dossier</Th>
                  <Th>Immatriculation véhicule sur la mission</Th>
                  <Th>Nature de l'expertise</Th>
                  <Th>Montant VRADE partie adverse ht</Th>
                  <Th>Montant REPARATION partie adverse ht</Th>
                  <Th>SRGC Partie adverse ht</Th>
                  <Th>Montant VRADE Expert MAIF ht</Th>
                  <Th>Montant REPARATION Expert MAIF ht</Th>
                  <Th>
                    "SRGC* Expert MAIF ht *concernés par l'assiette recours"
                  </Th>
                  <Th>Montant de l'honoraire</Th>
                  <Th>Ecart final Global (base Recours - Honoraires)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.data
                  .filter(
                    (folder) =>
                      (!this.state.sinister ||
                        (folder.sinister || "")
                          .toUpperCase()
                          .includes(this.state.sinister.toUpperCase())) &&
                      (!this.state.code || this.state.code === folder.code)
                  )
                  .map((folder, idx) => (
                    <Tr key={idx}>
                      <Th>{idx + 1}</Th>
                      <Td>GROUPE LANG</Td>
                      <Td>{folder.name}</Td>
                      <Td>{folder.reference}</Td>
                      <Td>{folder.sinister}</Td>
                      <Td>{folder.mission}</Td>
                      <Td>{folder.code}</Td>
                      <Td>{folder.licensePlate}</Td>
                      <Td>{folder.natureExpertise}</Td>
                      <Td>{getCurrencyValue(folder.a1_r3)}</Td>
                      <Td>{getCurrencyValue(folder.a2_r3)}</Td>
                      <Td>{getCurrencyValue(folder.a3_r3)}</Td>
                      <Td>{getCurrencyValue(folder.VRADE)}</Td>
                      <Td>{getCurrencyValue(folder.totalReport)}</Td>
                      <Td>{getCurrencyValue(folder.SRGC)}</Td>
                      <Td>{getCurrencyValue(folder.honorary)}</Td>
                      <Td>{getCurrencyValue(folder.final)}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Card>
          <Loader show={this.state.isLoad || this.state.isDownload} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
