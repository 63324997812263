import React from "react";
import {
  Modal,
  FormInput,
  FormTextarea,
  Button,
} from "ui-kit-ck-consultant";

import axiosConfig from "../../utils/axiosConfig";

export default class ContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ""
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show && this.props.show) {
            this.setState({ message: "" });   
        }
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSendMessage = () => {
        axiosConfig({
            url: "/contact/send-message",
            data: {
                page: this.props.page,
                message: this.state.message
            }
        }).then(res => {
            if (res.data.success) {
                alert("Message envoyé avec succès");
                this.setState({ message: "" });
                this.props.onClose();
            } else {
                alert("Erreur lors de l'envoi du message");
            }
        })
    }

    render() {
        return (
            <Modal
                title="Demande de module complémentaire"
                show={this.props.show}
                onClose={this.props.onClose}
            >
                <FormInput
                    className="mb-20 mt-20"
                    type="text"
                    value={this.props.page}
                    title="Module"
                    disabled={true}
                />
                <FormTextarea 
                    className="mb-20"  
                    type="text" 
                    name="message" 
                    value={this.state.message} 
                    placeholder="Votre message (optionnel)"
                    title="Message"
                    rows={10}
                    onChange={this.handleInputChange}
                />
                <Button
                    className="mb-4"
                    text="Envoyer"
                    onClick={this.handleSendMessage}
                />
            </Modal>
        );
    }
}
