import React from "react";
import {
  Nav,
  Loader,
  FormRadio,
  FormSelectMultiples,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";

import AuthContext from "../../../context/AuthContext";
import ContactRequest from "../../../components/general/ContactRequest";
import GeneralData from "../../../components/statistics/statistics-general/GeneralData";
import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

import "../../../index.css";

export default class StatisticsGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: true,
      isLoad: false,
      data: null,
      // data search
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 1,
      group: null,
      region: null,
      agency: null,
      kind: ["Voiture"],
      filter: "1",
      // Tabs state
      tabsData: ["Data Général"],
      tabsIndexGeneral: 0,
      companyLogo: null,
    };
  }

  static contextType = AuthContext;

  handleRadioChange = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      this.getGeneralData
    );
  };

  componentDidMount() {
    this.setState({
      accessAllowed: checkRights("StatisticsGeneral", this.context),
    });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    let dateStart = this.state.dateStart;
    let dateEnd = this.state.dateEnd;

    if (
      name === "dateEnd" &&
      moment(value).format("YYYY") !== moment(dateStart).format("YYYY")
    ) {
      dateStart = moment(`${moment(value).format("YYYY")}-01-01`).format(
        "YYYY-MM-DD"
      );
    } else if (
      name === "dateStart" &&
      moment(value).format("YYYY") !== moment(dateEnd).format("YYYY")
    ) {
      dateEnd = moment(`${moment(value).format("YYYY")}-12-31`).format(
        "YYYY-MM-DD"
      );
    }

    this.setState({
      dateStart,
      dateEnd,
      [name]: value,
    });
  };

  getData = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("pilotage-cabinet/company-performance/statistics-5", {
        data: {
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          kind: this.state.kind,
          filter: this.state.filter,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              data: res.data.dataGeneral,
              isLoad: false,
            });
          } else {
            this.setState({
              isLoad: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoad: false });
        });
    });
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Statistiques générales" className="mb-20" />
          <ContactRequest page="Statistiques - Générales" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Statistiques générales" className="mb-20" />
          </FadeIn>
          <ShowOptions
            className="mb-20"
            handleInputChange={this.handleInputChange}
            handleSelectChange={this.handleInputChange}
            getData={this.getData}
            isDateStart={true}
            isDateEnd={true}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
            network={this.state.network}
            group={this.state.group}
            region={this.state.region}
            agency={this.state.agency}
            footerData={
              this.context.companyId === 3 ? (
                <>
                  <FormRadio
                    className="mt-10 mr-10"
                    name="filter"
                    value={"1"}
                    checked={this.state.filter === "1"}
                    onChange={this.handleRadioChange}
                    text="R 21 - 4000 / NR : 100 - 12 000"
                  />
                  <FormRadio
                    className="mt-5 mr-10"
                    name="filter"
                    value={"2"}
                    checked={this.state.filter === "2"}
                    onChange={this.handleRadioChange}
                    text="R 21 - 35000 / NR : 0 - 35 000"
                  />
                  <FormSelectMultiples
                    title="Genre(s) véhicule"
                    value={this.state.kind}
                    default="Tous les genres"
                    options={["Voiture", "Moto"].map((element) => ({
                      value: element,
                      text: element,
                    }))}
                    onChange={(value) => {
                      console.log("test");
                      this.setState({
                        kind: [...value],
                      });
                    }}
                  />
                </>
              ) : null
            }
          />
          {this.state.data ? (
            <GeneralData
              network={this.state.network}
              group={this.state.group}
              region={this.state.region}
              agency={this.state.agency ? [this.state.agency] : []}
              user={this.state.user}
              company={this.context.companyId}
              data={this.state.data}
              dataOpenDescriptifState={this.state.dataOpenDescriptifState}
              dataOpenMarketSearch={this.state.dataOpenMarketSearch}
              isPrint={this.state.isPrint}
              tabsIndex={this.state.tabsIndexGeneral}
              onChangeTab={(idx) => this.setState({ tabsIndexGeneral: idx })}
              onLabelsLoaded={(isLoad) => this.setState({ isLoad })}
            />
          ) : null}
          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
