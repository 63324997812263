import React from "react";
import { Nav, Loader, FormSelect, Card } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import FadeIn from "react-fade-in";

import AuthContext from "../../../context/AuthContext";
import ContactRequest from "../../../components/general/ContactRequest";

import MainFigures from "../../../components/caravaning/MainFigures";
import TreatmentDelays from "../../../components/caravaning/TreatmentDelays";
import AverageAgeOfCurrentCases from "../../../components/caravaning/AverageAgeOfCurrentCases";
import DetailOfVolumetriesAndCost from "../../../components/caravaning/DetailOfVolumetriesAndCost";
import SavingsElectric from "../../../components/caravaning/SavingsElectric";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";
import getYears from "../../../utils/years";

export default class Electric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      isLoad: false,
      mainFigures: [],
      treatmentDelays: [],
      averageAgeOfCurrentCases: [],
      detailOfVolumetriesAndCost: [],
      savings: [],
      year: new Date().getFullYear(),
      yearSelect: getYears(null, new Date().getFullYear()),
    };
  }

  static contextType = AuthContext;

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      this.getData
    );
  };

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("statistics/electric/get-data", {
          data: {
            year: this.state.year,
          },
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                isLoad: false,
                mainFigures: res.data.data.mainFigures,
                treatmentDelays: res.data.data.treatmentDelays,
                averageAgeOfCurrentCases:
                  res.data.data.averageAgeOfCurrentCases,
                detailOfVolumetriesAndCost:
                  res.data.data.detailOfVolumetriesAndCost,
                savings: res.data.data.savings,
              });
            } else {
              this.setState({
                isLoad: false,
                mainFigures: [],
                treatmentDelays: [],
                averageAgeOfCurrentCases: [],
                detailOfVolumetriesAndCost: [],
                savings: [],
              });
            }
          })
          .catch(() => {
            this.setState({
              isLoad: false,
              mainFigures: [],
              treatmentDelays: [],
              averageAgeOfCurrentCases: [],
              detailOfVolumetriesAndCost: [],
              savings: [],
            });
          });
      }
    );
  };

  componentDidMount() {
    this.setState(
      { accessAllowed: checkRights("Electric", this.context) },
      () => {
        if (this.state.accessAllowed) {
          this.getData();
        }
      }
    );
  }

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Électrique" className="mb-20" />
          <ContactRequest page="Électrique" />
        </FadeIn>
      ) : (
        <>
          <Nav title="Électrique" className="mb-20" />
          <Card title="Filtres" className="mb-20">
            <Row>
              <Col sm={12} md={3}>
                <FormSelect
                  name="year"
                  value={this.state.year}
                  options={this.state.yearSelect}
                  title="Années"
                  onChange={this.handleSelectChange}
                  ignore={true}
                />
              </Col>
              <Col sm={12} md={4}>
                <FormSelect
                  name="agency"
                  value={this.state.agency}
                  options={[{ value: "", text: "SOLUTION ÉLECTRIQUE" }]}
                  title="Agence"
                  disabled
                  onChange={this.handleSelectChange}
                  ignore={true}
                />
              </Col>
            </Row>
          </Card>
          <MainFigures
            year={this.state.year}
            data={this.state.mainFigures}
            isElectric={true}
          />
          <TreatmentDelays
            year={this.state.year}
            data={this.state.treatmentDelays}
          />
          <AverageAgeOfCurrentCases
            year={this.state.year}
            data={this.state.averageAgeOfCurrentCases}
          />
          <DetailOfVolumetriesAndCost
            year={this.state.year}
            data={this.state.detailOfVolumetriesAndCost}
            isElec={true}
          />
          <SavingsElectric year={this.state.year} data={this.state.savings} />
          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
