import React from "react";
import {
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Card,
  FormInput,
  Button,
  ButtonIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Pagination,
  PaginationClass,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faEdit,
  faTrashAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import ModalGarageGroup from "../../../components/admin/admin-garage/ModalGarageGroup";
import ModalGarage from "../../../components/admin/admin-garage/ModalGarage";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

export default class AdminGarage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      search: "",
      data: [],
      order: { name: "groupId", desc: false },
      // Tabs
      tabsMenu: ["Réseau", "Réparateur"],
      tabsIndex: 0,
      // Pagination
      activePage: 1,
      tabLength: 20,
      totalData: 0,
      // Modal groups states
      isModalGroupAdd: false,
      isModalGroupEdit: false,
      modalGroupId: null,
      modalGroupColor: null,
      modalGroupName: null,
      modalGroupCompanyId: null,
      // Modal garagess states
      isModalGarageAdd: false,
      isModalGarageEdit: false,
      modalGarageId: null,
      modalGarageGroupId: null,
      modalGarageDepartmentId: null,
      modalGarageColor: null,
      modalGarageName: null,
      modalGarageCompanyId: null,
    };
    this.refColumnGroupName = React.createRef();
    this.refColumnGroupCompany = React.createRef();
    this.refColumnGarageName = React.createRef();
    this.refColumnGarageGroup = React.createRef();
    this.refColumnGarageCompany = React.createRef();
    this.refColumnDepartment = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({
      accessAllowed: checkRights("Administration", this.context),
    });
    this.GroupPaginationClass = new PaginationClass(
      "admin/garage/count-group",
      "admin/garage/pagination-group",
      this.state.tabLength
    );
    this.GaragePaginationClass = new PaginationClass(
      "admin/garage/count",
      "admin/garage/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.isModalGroupAdd !== this.state.isModalGroupAdd &&
        this.state.isModalGroupAdd === false) ||
      (prevState.isModalGroupEdit !== this.state.isModalGroupEdit &&
        this.state.isModalGroupEdit === false) ||
      (prevState.isModalGarageAdd !== this.state.isModalGarageAdd &&
        this.state.isModalGarageAdd === false) ||
      (prevState.isModalGarageEdit !== this.state.isModalGarageEdit &&
        this.state.isModalGarageEdit === false)
    ) {
      this.initSearch();
    } else if (prevState.tabsIndex !== this.state.tabsIndex) {
      this.initSearch();
    }
  }

  initSearch = () => {
    this.setState({ activePage: 1 }, () => {
      if (this.state.tabsIndex === 0) {
        this.GroupPaginationClass.init(
          axiosConfig,
          this.getData(),
          (result) => {
            if (result.success === true) {
              this.setState({ totalData: result.total }, () => {
                this.GroupPaginationClass.get(
                  axiosConfig,
                  this.getData(),
                  (result) => {
                    if (result.success === true) {
                      this.setState({ data: result.tabData });
                    } else {
                      this.setState({ data: [] });
                    }
                  }
                );
              });
            }
          }
        );
      } else if (this.state.tabsIndex === 1) {
        this.GaragePaginationClass.init(
          axiosConfig,
          this.getData(),
          (result) => {
            if (result.success === true) {
              this.setState({ totalData: result.total }, () => {
                this.GaragePaginationClass.get(
                  axiosConfig,
                  this.getData(),
                  (result) => {
                    if (result.success === true) {
                      this.setState({ data: result.tabData });
                    } else {
                      this.setState({ data: [] });
                    }
                  }
                );
              });
            }
          }
        );
      }
    });
  };

  getData = () => {
    return {
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  };

  handleSearchInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.initSearch);
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnGroupName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnGarageName.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnGarageGroup.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnDepartment.current.setState({ desc: false, sort: false });
    }
    if (!this.context.companyId && column !== 4) {
      this.refColumnGroupCompany.current.setState({ desc: false, sort: false });
    }
    if (!this.context.companyId && column !== 5) {
      this.refColumnGarageCompany.current.setState({
        desc: false,
        sort: false,
      });
    }

    let order = null;

    switch (column) {
      case 0:
        order = { name: "groupName", desc: desc };
        break;
      case 1:
        order = { name: "garageName", desc: desc };
        break;
      case 2:
        order = { name: "groupName", desc: desc };
        break;
      case 3:
        order = { name: "department", desc: desc };
        break;
      case 4:
      case 5:
        order = { name: "company", desc: desc };
        break;
      default:
        order = {
          name: this.state.tabsIndex === 0 ? "groupId" : "garageId",
          desc: desc,
        };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  handleDeleteGroupClick = (group) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer le réseau ${group.name} ?`
      )
    ) {
      axiosConfig({
        url: "admin/garage/delete-group",
        data: {
          id: group.id,
        },
      }).then((res) => {
        if (res.data.success) {
          axiosConfig({
            url: "admin/garage/delete-many-garages",
            data: {
              groupId: group.id,
            },
          }).then((res) => {
            if (res.data.success) {
              alert("Le réseau a bien été supprimé");
              this.initSearch();
            } else {
              alert(
                "Erreur lors de la suppression (réparateurs associés non supprimés"
              );
              this.initSearch();
            }
          });
        } else {
          alert("Erreur lors de la suppression");
        }
      });
    }
  };

  handleDeleteGarageClick = (garage) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer le réparateur ${garage.name} ?`
      )
    ) {
      axiosConfig({
        url: "admin/garage/delete-garage",
        data: {
          id: garage.id,
        },
      }).then((res) => {
        if (res.data.success) {
          alert("Le réparateur a bien été supprimé");
          this.initSearch();
        } else {
          alert("Erreur lors de la suppression");
        }
      });
    }
  };

  handlePageChange = (activePage) => {
    if (this.state.tabsIndex === 0) {
      this.GroupPaginationClass.page(activePage, () => {
        this.setState({ activePage: activePage }, () => {
          this.GroupPaginationClass.get(
            axiosConfig,
            this.getData(),
            (result) => {
              if (result.success === true) {
                this.setState({ data: result.tabData });
              } else {
                this.setState({ data: [] });
              }
            }
          );
        });
      });
    } else if (this.state.tabsIndex === 1) {
      this.GaragePaginationClass.page(activePage, () => {
        this.setState({ activePage: activePage }, () => {
          this.GaragePaginationClass.get(
            axiosConfig,
            this.getData(),
            (result) => {
              if (result.success === true) {
                this.setState({ data: result.tabData });
              } else {
                this.setState({ data: [] });
              }
            }
          );
        });
      });
    }
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <h2>
          Oups... Vous n'avez pas les autorisations nécessaires pour accéder à
          cette page...
        </h2>
      ) : (
        <>
          <FadeIn>
            {/* HEADER */}
            <Nav title="Réparateurs" className="mb-20">
              <Button
                text={
                  this.state.tabsIndex === 0
                    ? "Ajouter un réseau"
                    : "Ajouter un réparateur"
                }
                onClick={() =>
                  this.state.tabsIndex === 0
                    ? this.setState({ isModalGroupAdd: true })
                    : this.setState({ isModalGarageAdd: true })
                }
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Nav>

            {/* SEARCH BAR */}
            <Row>
              <Col sm={12} md={6}>
                <Card className="mb-20">
                  <FormInput
                    value={this.state.search}
                    name="search"
                    onChange={this.handleSearchInputChange}
                    placeholder={
                      this.state.tabsIndex === 0
                        ? this.context.companyId
                          ? "Nom du réseau"
                          : "Nom ou compagnie du réseau"
                        : this.context.companyId
                        ? "Nom, réseau ou départment du réparateur"
                        : "Nom, réseau, compagnie ou départment du réparateur"
                    }
                  >
                    <FontAwesomeIcon className="icon" icon={faSearch} />
                  </FormInput>
                </Card>
              </Col>
            </Row>

            {/* TABLE */}
            <TabsCustom>
              <TabsCustomHeader>
                {this.state.tabsMenu.map((menu, idx) => (
                  <TabsCustomMenu
                    key={idx}
                    title={menu}
                    active={this.state.tabsIndex === idx}
                    onClick={(event) => this.setState({ tabsIndex: idx })}
                  />
                ))}
              </TabsCustomHeader>

              <TabsCustomBody noCard>
                {this.state.tabsMenu.map((element, idx) => (
                  <TabsCustomItem
                    key={idx}
                    active={this.state.tabsIndex === idx}
                  >
                    <Card
                      title={
                        idx === 0
                          ? "Liste des réseaux"
                          : "Liste des réparateurs"
                      }
                      className="mb-20"
                      classNameChildren="overflow-x-auto"
                    >
                      {idx === 0 ? (
                        // GROUPS TABLE
                        <Table
                          empty={
                            this.state.data.length > 0
                              ? ""
                              : "Aucune donnée n'est disponible"
                          }
                          radiusTopLeft
                        >
                          <Thead>
                            <Tr>
                              <Th
                                hide={this.state.data.length > 0 ? true : false}
                              ></Th>
                              <Th
                                ref={this.refColumnGroupName}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 0)}
                                radiusTopLeft={
                                  this.state.data.length > 0 ? true : false
                                }
                              >
                                Nom
                              </Th>
                              {this.context.companyId ? null : (
                                <Th
                                  ref={this.refColumnGroupCompany}
                                  sort
                                  onSort={(desc) =>
                                    this.handleSortClick(desc, 4)
                                  }
                                >
                                  Compagnie
                                </Th>
                              )}
                              <Th>Actions</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {this.state.data.map((element, idx) => (
                              <Tr key={idx}>
                                <Th radiusTopLeft={idx === 0 ? true : false}>
                                  {(this.state.activePage - 1) *
                                    this.state.tabLength +
                                    idx +
                                    1}
                                </Th>
                                <Td>
                                  <div className="d-flex">
                                    <div
                                      className="mt-auto mb-auto mr-10"
                                      style={{
                                        backgroundColor: element.color,
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "10px",
                                      }}
                                    />
                                    <div className="mt-auto mb-auto">
                                      {element.name}
                                    </div>
                                  </div>
                                </Td>
                                {this.context.companyId ? null : (
                                  <Td>{element.companyName}</Td>
                                )}
                                <Td className="nowrap">
                                  <div className="d-flex">
                                    <ButtonIcon
                                      small
                                      style={{ color: "var(--primary-color" }}
                                      onClick={() =>
                                        this.setState({
                                          isModalGroupEdit: true,
                                          modalGroupId: element.id,
                                          modalGroupColor: element.color,
                                          modalGroupName: element.name,
                                          modalGroupCompanyId:
                                            element.companyId,
                                        })
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </ButtonIcon>
                                    <ButtonIcon
                                      small
                                      style={{ color: "var(--primary-color" }}
                                      onClick={() =>
                                        this.handleDeleteGroupClick(element)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </ButtonIcon>
                                  </div>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      ) : (
                        // GARAGES TABLE
                        <Table
                          empty={
                            this.state.data.length > 0
                              ? ""
                              : "Aucune donnée n'est disponible"
                          }
                          radiusTopLeft
                        >
                          <Thead>
                            <Tr>
                              <Th
                                hide={this.state.data.length > 0 ? true : false}
                              ></Th>
                              <Th
                                ref={this.refColumnGarageName}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 1)}
                                radiusTopLeft={
                                  this.state.data.length > 0 ? true : false
                                }
                              >
                                Nom
                              </Th>
                              <Th
                                ref={this.refColumnGarageGroup}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 2)}
                              >
                                Réseau
                              </Th>
                              {this.context.companyId ? null : (
                                <Th
                                  ref={this.refColumnGarageCompany}
                                  sort
                                  onSort={(desc) =>
                                    this.handleSortClick(desc, 5)
                                  }
                                >
                                  Compagnie
                                </Th>
                              )}
                              <Th
                                ref={this.refColumnDepartment}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 3)}
                              >
                                Département
                              </Th>
                              <Th>Actions</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {this.state.data.map((element, idx) => (
                              <Tr key={idx}>
                                <Th radiusTopLeft={idx === 0 ? true : false}>
                                  {(this.state.activePage - 1) *
                                    this.state.tabLength +
                                    idx +
                                    1}
                                </Th>
                                <Td>
                                  <div className="d-flex">
                                    <div
                                      className="mt-auto mb-auto mr-10"
                                      style={{
                                        backgroundColor: element.color,
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "10px",
                                      }}
                                    />
                                    <div className="mt-auto mb-auto">
                                      {element.name}
                                    </div>
                                  </div>
                                </Td>
                                <Td>{element.group}</Td>
                                {this.context.companyId ? null : (
                                  <Td>{element.companyName}</Td>
                                )}
                                <Td>
                                  {element.zip} - {element.department}
                                </Td>
                                <Td className="nowrap">
                                  <div className="d-flex">
                                    <ButtonIcon
                                      small
                                      style={{ color: "var(--primary-color" }}
                                      onClick={() =>
                                        this.setState({
                                          isModalGarageEdit: true,
                                          modalGarageId: element.id,
                                          modalGarageGroupId: element.groupId,
                                          modalGarageDepartmentId:
                                            element.departmentId,
                                          modalGarageColor: element.color,
                                          modalGarageName: element.name,
                                          modalGarageCompanyId:
                                            element.companyId,
                                        })
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </ButtonIcon>
                                    <ButtonIcon
                                      small
                                      style={{ color: "var(--primary-color" }}
                                      onClick={() =>
                                        this.handleDeleteGarageClick(element)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </ButtonIcon>
                                  </div>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}

                      <Pagination
                        className="mt-10"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.tabLength}
                        totalItemsCount={this.state.totalData}
                        pageRangeDisplayed={4}
                        onChange={this.handlePageChange}
                        firstPageText={
                          <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        }
                        lastPageText={
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        }
                        prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
                        nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
                      />
                    </Card>
                  </TabsCustomItem>
                ))}
              </TabsCustomBody>
            </TabsCustom>
          </FadeIn>

          {/* ADD OR EDIT GROUP */}
          <ModalGarageGroup
            show={this.state.isModalGroupAdd || this.state.isModalGroupEdit}
            isEdit={this.state.isModalGroupEdit}
            id={this.state.modalGroupId}
            color={this.state.modalGroupColor}
            name={this.state.modalGroupName}
            companyId={this.state.modalGroupCompanyId}
            onClose={() =>
              this.setState({
                isModalGroupAdd: false,
                isModalGroupEdit: false,
                modalGroupId: null,
                modalGroupColor: null,
                modalGroupName: null,
                modalGroupCompanyId: null,
              })
            }
          />

          {/* ADD OR EDIT GARAGE */}
          <ModalGarage
            show={this.state.isModalGarageAdd || this.state.isModalGarageEdit}
            isEdit={this.state.isModalGarageEdit}
            id={this.state.modalGarageId}
            groupId={this.state.modalGarageGroupId}
            departmentId={this.state.modalGarageDepartmentId}
            color={this.state.modalGarageColor}
            name={this.state.modalGarageName}
            companyId={this.state.modalGarageCompanyId}
            onClose={() =>
              this.setState({
                isModalGarageAdd: false,
                isModalGarageEdit: false,
                modalGarageId: null,
                modalGarageGroupId: null,
                modalGarageDepartmentId: null,
                modalGarageColor: null,
                modalGarageName: null,
                modalGarageCompanyId: null,
              })
            }
          />
        </>
      );
    } else {
      return <></>;
    }
  }
}
