import React from "react";
import {
  Nav,
  Card,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
  Button,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../../context/AuthContext";
import MissionsCard from "../../../components/missions/missions-table/MissionsCard";
import ModalAgency from "../../../components/admin/admin-agency/ModalAgency";
import ModalAgencyGroup from "../../../components/admin/admin-agency/ModalAgencyGroup";
import ContactRequest from "../../../components/general/ContactRequest";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

export default class MissionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      // Loader
      isLoad: false,
      // Main tabs
      tabsMenu: ["Répartition par département", "Total départements"],
      tabsIndex: 0,
      // Filters
      filtersMenu: [],
      filtersIndex: 0,
      // Data
      dataFirstTab: [],
      filteredData: [],
      dataSecondTab: [],
      groups: [],
      // Modal agency states
      isModalAgencyAdd: false,
      isModalAgencyEdit: false,
      modalAgencyId: null,
      modalAgencyGroupId: null,
      modalAgencyDepartmentId: null,
      modalAgencyName: null,
      modalAgencyYear3: null,
      modalAgencyYear2: null,
      modalAgencyYear1: null,
      // Modal group states
      isModalGroupAdd: false,
      isModalGroupEdit: false,
      modalGroupId: null,
      modalGroupName: null,
      modalLegalForm: null,
      modalLastIncome: null,
      modalIncomeVolume: null,
      modalIncomeRate: null,
      modalCoveredArea: null,
      modalActiveArea: null,
      modalMissions: null,
      modalColor: null,
    };
  }

  componentDidMount() {
    this.setState({ accessAllowed: checkRights("Missions", this.context) });
    this.setGroups();
    this.setData();
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.isModalAgencyAdd !== this.state.isModalAgencyAdd &&
        this.state.isModalAgencyAdd === false) ||
      (prevState.isModalAgencyEdit !== this.state.isModalAgencyEdit &&
        this.state.isModalAgencyEdit === false) ||
      (prevState.isModalGroupAdd !== this.state.isModalGroupAdd &&
        this.state.isModalGroupAdd === false) ||
      (prevState.isModalGroupEdit !== this.state.isModalGroupEdit &&
        this.state.isModalGroupEdit === false)
    ) {
      this.setGroups();
      this.setData();
    } else if (prevState.filtersIndex !== this.state.filtersIndex) {
      this.filterData();
    }
  }

  // GET GROUPS INFOS
  setGroups = () => {
    axiosConfig({
      url: "missions/get-all-groups",
      data: {
        companyId: this.context.companyId,
      },
    }).then((res) => {
      if (res.data.success === true) {
        let newFilters = ["Tous les groupes"];
        res.data.data.forEach((element) => {
          newFilters = [...newFilters, element.name];
        });
        this.setState({ groups: res.data.data, filtersMenu: newFilters });
      } else {
        this.setState({ filtersMenu: ["Tous les groupes"], groups: [] });
      }
    });
  };

  // SET DATA
  setData = () => {
    this.setState({ isLoad: true });
    axiosConfig({
      url: "missions/get-all-agencies",
      data: {
        companyId: this.context.companyId,
      },
    }).then((res) => {
      if (res.data.success === true) {
        // DISPLAY BY DEPARTMENTS
        let newDataFirstTab = res.data.data;
        axiosConfig({
          url: "missions/get-missions-by-departments",
          data: {
            companyId: this.context.companyId,
          },
        }).then((res) => {
          if (res.data.success === true) {
            // DISPLAY TOTALS
            let newDataSecondTab = [
              { title: "Par départements", data: res.data.data },
            ];
            axiosConfig({
              url: "missions/get-missions-by-groups",
              data: {
                companyId: this.context.companyId,
              },
            }).then((res) => {
              if (res.data.success === true) {
                newDataSecondTab = [
                  ...newDataSecondTab,
                  { title: "Par groupes", data: res.data.data },
                ];
                this.setState(
                  {
                    dataFirstTab: newDataFirstTab,
                    dataSecondTab: newDataSecondTab,
                    isLoad: false,
                  },
                  this.filterData
                );
              }
            });
          }
        });
      } else {
        this.setState({
          dataFirstTab: [],
          dataSecondTab: [],
          isLoad: false,
        });
      }
    });
  };

  // FILTER DATA
  filterData = () => {
    this.setState({ filteredData: [] }, () => {
      if (this.state.filtersIndex !== 0) {
        let newData = this.state.dataFirstTab.map((department) => {
          let groupData = department.data.filter(
            (group) =>
              group.group === this.state.filtersMenu[this.state.filtersIndex]
          );
          return { ...department, data: groupData };
        });
        this.setState({ filteredData: newData });
      }
    });
  };

  // EDIT AGENCY
  onEditAgencyRequest = (agency, departmentId) => {
    let data = [];
    for (let i = 0; i < 3; i++) {
      let filteredData = agency.data.filter(
        (element) => Number(element.year) === new Date().getFullYear() - i
      );
      if (filteredData.length > 0) {
        data.push(filteredData[0].value);
      } else {
        data.push("");
      }
    }

    this.setState({
      isModalAgencyEdit: true,
      modalAgencyId: agency.agencyId,
      modalAgencyGroupId: agency.groupId,
      modalAgencyDepartmentId: departmentId,
      modalAgencyName: agency.agencyName,
      modalAgencyYear3: data[2],
      modalAgencyYear2: data[1],
      modalAgencyYear1: data[0],
    });
  };

  // DELETE AGENCY
  onDeleteAgencyRequest = (agency, departmentId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette agence ?")) {
      axiosConfig({
        url: "admin/agency/delete-agency",
        data: {
          id: agency.agencyId,
        },
      }).then((res) => {
        if (res.data.success) {
          alert("L'agence a bien été supprimée");
          this.setGroups();
          this.setData();
        } else {
          alert("Erreur lors de la suppression");
        }
      });
    }
  };

  // EDIT GROUP
  onEditGroupRequest = (group) => {
    this.setState({
      isModalGroupEdit: true,
      modalGroupId: group.groupId,
      modalGroupName: group.groupName,
      modalLegalForm: group.groupLegalForm,
      modalLastIncome: group.groupLastIncome,
      modalIncomeVolume: group.groupIncomeVolume,
      modalIncomeRate: group.groupIncomeRate,
      modalCoveredArea: group.groupCoveredArea,
      modalActiveArea: group.groupActiveArea,
      modalMissions: group.groupMissions,
      modalColor: group.groupColor,
    });
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Répartition missions" className="mb-20" />
          <ContactRequest page="Répartition missions - Tableau" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Répartition missions" className="mb-20">
              {/* ADD GROUP BUTTON */}
              <div style={{ display: "flex" }}>
                <Button
                  className="ml-2"
                  text="AJOUTER UNE AGENCE"
                  onClick={() => this.setState({ isModalAgencyAdd: true })}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  className="ml-10"
                  text="AJOUTER UN GROUPE"
                  onClick={() => this.setState({ isModalGroupAdd: true })}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </Nav>

            <TabsCustom>
              {/* DISPLAY MENU */}
              <TabsCustomHeader>
                {this.state.tabsMenu.map((menu, index) => (
                  <TabsCustomMenu
                    key={index}
                    title={menu}
                    active={this.state.tabsIndex === index}
                    onClick={(event) => this.setState({ tabsIndex: index })}
                  />
                ))}
              </TabsCustomHeader>

              {/* DISPLAY CONTENT */}
              <TabsCustomBody noCard>
                {this.state.tabsMenu.map((element, tabsIndex) => (
                  <TabsCustomItem
                    key={tabsIndex}
                    active={this.state.tabsIndex === tabsIndex}
                  >
                    {tabsIndex === 0 ? (
                      // FIRST TAB - BY DEPARTMENT
                      this.state.dataFirstTab.length > 0 ? (
                        <TabsCustom>
                          <TabsCustomHeader>
                            {this.state.filtersMenu.map(
                              (filter, filterIndex) => (
                                <TabsCustomMenu
                                  key={filterIndex}
                                  title={filter}
                                  active={
                                    this.state.filtersIndex === filterIndex
                                  }
                                  onClick={(event) =>
                                    this.setState({ filtersIndex: filterIndex })
                                  }
                                />
                              )
                            )}
                          </TabsCustomHeader>
                          <TabsCustomBody noCard>
                            {this.state.filtersMenu.map((element, idx) => (
                              <TabsCustomItem
                                key={idx}
                                active={this.state.filtersIndex === idx}
                              >
                                {idx === 0 ? (
                                  // UNFILTERED DATA
                                  <Card>
                                    {this.state.dataFirstTab.map(
                                      (tableData, tableIndex) =>
                                        tableData.data.length > 0 ? (
                                          <MissionsCard
                                            key={tableIndex}
                                            data={tableData}
                                            addButton={true}
                                            tableType={1}
                                            onEditAgency={
                                              this.onEditAgencyRequest
                                            }
                                            onDeleteAgency={
                                              this.onDeleteAgencyRequest
                                            }
                                          />
                                        ) : null
                                    )}
                                  </Card>
                                ) : (
                                  // FILETERED DATA
                                  <Card>
                                    {this.state.filteredData.map(
                                      (tableData, tableIndex) =>
                                        tableData.data.length > 0 ? (
                                          <MissionsCard
                                            key={tableIndex}
                                            data={tableData}
                                            addButton={true}
                                            tableType={1}
                                            onEditAgency={
                                              this.onEditAgencyRequest
                                            }
                                            onDeleteAgency={
                                              this.onDeleteAgencyRequest
                                            }
                                          />
                                        ) : null
                                    )}
                                  </Card>
                                )}
                              </TabsCustomItem>
                            ))}
                          </TabsCustomBody>
                        </TabsCustom>
                      ) : null
                    ) : // SECOND TAB - TOTALS
                    this.state.dataSecondTab.length > 0 ? (
                      <Card>
                        {this.state.dataSecondTab.map(
                          (tableData, tableIndex) => (
                            <MissionsCard
                              key={tableIndex}
                              data={tableData}
                              tableType={tableIndex === 0 ? 2 : 3}
                              onEditGroup={this.onEditGroupRequest}
                            />
                          )
                        )}
                      </Card>
                    ) : null}
                  </TabsCustomItem>
                ))}
              </TabsCustomBody>
            </TabsCustom>
          </FadeIn>

          {/* ADD, EDIT OR DELETE AGENCY MODALS */}
          <ModalAgency
            show={this.state.isModalAgencyAdd || this.state.isModalAgencyEdit}
            isEdit={this.state.isModalAgencyEdit}
            id={this.state.modalAgencyId}
            groupId={this.state.modalAgencyGroupId}
            departmentId={this.state.modalAgencyDepartmentId}
            agencyName={this.state.modalAgencyName}
            year3={this.state.modalAgencyYear3}
            year2={this.state.modalAgencyYear2}
            year1={this.state.modalAgencyYear1}
            companyId={this.context.companyId}
            onClose={() =>
              this.setState({
                isModalAgencyAdd: false,
                isModalAgencyEdit: false,
                modalAgencyId: null,
                modalAgencyGroupId: null,
                modalAgencyDepartmentId: null,
                modalAgencyName: null,
                modalAgencyYear3: null,
                modalAgencyYear2: null,
                modalAgencyYear1: null,
                dataFirstTab: [],
                dataSecondTab: [],
                filteredData: [],
              })
            }
          />

          {/* ADD OR EDIT GROUP MODAL */}
          <ModalAgencyGroup
            show={this.state.isModalGroupAdd || this.state.isModalGroupEdit}
            isEdit={this.state.isModalGroupEdit}
            id={this.state.modalGroupId}
            groupName={this.state.modalGroupName}
            legalForm={this.state.modalLegalForm}
            lastIncome={this.state.modalLastIncome}
            incomeVolume={this.state.modalIncomeVolume}
            incomeRate={this.state.modalIncomeRate}
            coveredArea={this.state.modalCoveredArea}
            activeArea={this.state.modalActiveArea}
            missions={this.state.modalMissions}
            color={this.state.modalColor}
            companyId={this.context.companyId}
            onClose={() =>
              this.setState({
                isModalGroupAdd: false,
                isModalGroupEdit: false,
                modalGroupId: null,
                modalGroupName: null,
                modalLegalForm: null,
                modalLastIncome: null,
                modalIncomeVolume: null,
                modalIncomeRate: null,
                modalCoveredArea: null,
                modalActiveArea: null,
                modalMissions: null,
                modalColor: null,
                dataFirstTab: [],
                dataSecondTab: [],
                filteredData: [],
              })
            }
          />

          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
