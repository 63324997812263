import React from "react";
import {
  Nav,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";

import ContactRequest from "../../../components/general/ContactRequest";
import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";
import { getPercentageValue } from "../../../utils/general";

export default class StatisticsAllianzOM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      accessAllowed: false,
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: "1",
      group: "1",
      region: "26",
      agency: "DEFAULT",
      data: null,
      // Tabs
      tabsMenu: ["Stocks", "Coûts", "Délais", "SLA", "EAD"],
      tabsIndex: 0,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({
      accessAllowed: checkRights("StatisticsAllianzOM", this.context),
    });
  }

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig({
          url: "statistics/statistics-allianzOM/get-data",
          data: {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            region: this.state.region,
            agency: this.state.agency,
          },
          timeout: 240000,
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({ data: res.data.data, isLoad: false });
            } else {
              this.setState({ data: null, isLoad: false }, () =>
                alert("Erreur")
              );
            }
          })
          .catch(() => {
            this.setState({ data: null, isLoad: false }, () => alert("Erreur"));
          });
      }
    );
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;

    let dateStart = this.state.dateStart;
    let dateEnd = this.state.dateEnd;

    if (
      name === "dateEnd" &&
      moment(value).format("YYYY") !== moment(dateStart).format("YYYY")
    ) {
      dateStart = moment(`${moment(value).format("YYYY")}-01-01`).format(
        "YYYY-MM-DD"
      );
    } else if (
      name === "dateStart" &&
      moment(value).format("YYYY") !== moment(dateEnd).format("YYYY")
    ) {
      dateEnd = moment(`${moment(value).format("YYYY")}-12-31`).format(
        "YYYY-MM-DD"
      );
    }

    this.setState({
      dateStart,
      dateEnd,
      [name]: value,
    });
  };

  handleInputBlur = () => {};

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: Number(value) }, () => {
      if (name === "network") {
        this.setState({ group: 0, region: 0, agency: 0 });
      } else if (name === "group") {
        this.setState({ region: 0, agency: 0 });
      } else if (name === "region") {
        this.setState({ agency: 0 });
      }
    });
  };

  getColor = (value, isReverse) => {
    if ((value > 0 && !isReverse) || (value < 0 && isReverse)) {
      return "bg-green-light green";
    } else {
      return "bg-red-light red";
    }
  };

  render() {
    if (!this.state.accessAllowed) {
      return (
        <FadeIn>
          <Nav title="Statistiques spécifiques" className="mb-20" />
          <ContactRequest page="Statistiques - Générales" />
        </FadeIn>
      );
    }

    return (
      <>
        <FadeIn>
          <Nav title="Statistiques spécifiques" className="mb-20" />
          <ShowOptions
            className="mb-20"
            handleInputChange={this.handleInputChange}
            handleInputBlur={this.handleInputBlur}
            handleSelectChange={this.handleSelectChange}
            getData={this.getData}
            isDateStart={true}
            isDateEnd={true}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
            network={this.state.network}
            group={this.state.group}
            region={this.state.region}
            agency={this.state.agency}
          />
          <TabsCustom>
            <TabsCustomHeader>
              {this.state.tabsMenu.map((menu, index) => (
                <TabsCustomMenu
                  key={index}
                  title={menu}
                  active={this.state.tabsIndex === index}
                  onClick={() => this.setState({ tabsIndex: index })}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              {!this.state.data
                ? null
                : this.state.data.map((element, idx) => (
                    <TabsCustomItem
                      key={idx}
                      active={this.state.tabsIndex === idx}
                    >
                      <Card title={this.state.tabsMenu[idx]}>
                        <Table isCSV>
                          <Thead>
                            <Tr>
                              <Th></Th>
                              <Th className="nowrap">N-1</Th>
                              <Th className="nowrap">N</Th>
                              <Th className="nowrap">N/N-1</Th>
                              <Th>Janv.</Th>
                              <Th>Fév.</Th>
                              <Th>Mars</Th>
                              <Th>Avril</Th>
                              <Th>Mai</Th>
                              <Th>Juin</Th>
                              <Th>Juil.</Th>
                              <Th>Août</Th>
                              <Th>Sept.</Th>
                              <Th>Oct.</Th>
                              <Th>Nov.</Th>
                              <Th>Déc.</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {element.map((_element, _idx) => (
                              <Tr key={_idx}>
                                <Th>
                                  {_element.data[0] === "NaN"
                                    ? ""
                                    : _element.data[0]}
                                </Th>
                                <Td>
                                  {_element.data[2] === "NaN"
                                    ? ""
                                    : _element.data[2]}
                                </Td>
                                <Td>
                                  {_element.data[1] === "NaN"
                                    ? ""
                                    : _element.data[1]}
                                </Td>
                                <Td
                                  className={this.getColor(
                                    _element.data[15],
                                    _element.isReverse
                                  )}
                                >
                                  {_element.data[15] === "NaN"
                                    ? ""
                                    : getPercentageValue(_element.data[15])}
                                </Td>
                                <Td>
                                  {_element.data[3] === "NaN"
                                    ? ""
                                    : _element.data[3]}
                                </Td>
                                <Td>
                                  {_element.data[4] === "NaN"
                                    ? ""
                                    : _element.data[4]}
                                </Td>
                                <Td>
                                  {_element.data[5] === "NaN"
                                    ? ""
                                    : _element.data[5]}
                                </Td>
                                <Td>
                                  {_element.data[6] === "NaN"
                                    ? ""
                                    : _element.data[6]}
                                </Td>
                                <Td>
                                  {_element.data[7] === "NaN"
                                    ? ""
                                    : _element.data[7]}
                                </Td>
                                <Td>
                                  {_element.data[8] === "NaN"
                                    ? ""
                                    : _element.data[8]}
                                </Td>
                                <Td>
                                  {_element.data[9] === "NaN"
                                    ? ""
                                    : _element.data[9]}
                                </Td>
                                <Td>
                                  {_element.data[10] === "NaN"
                                    ? ""
                                    : _element.data[10]}
                                </Td>
                                <Td>
                                  {_element.data[11] === "NaN"
                                    ? ""
                                    : _element.data[11]}
                                </Td>
                                <Td>
                                  {_element.data[12] === "NaN"
                                    ? ""
                                    : _element.data[12]}
                                </Td>
                                <Td>
                                  {_element.data[13] === "NaN"
                                    ? ""
                                    : _element.data[13]}
                                </Td>
                                <Td>
                                  {_element.data[14] === "NaN"
                                    ? ""
                                    : _element.data[14]}
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Card>
                    </TabsCustomItem>
                  ))}
            </TabsCustomBody>
          </TabsCustom>
          <Loader show={this.state.isLoad} />
        </FadeIn>
      </>
    );
  }
}
