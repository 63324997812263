import React from "react";
import { Nav, Loader, Card } from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";

import AuthContext from "../../../context/AuthContext";
import GraphCard from "../../../components/statistics/statistics-selfcare/GraphCard";
import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";
import ContactRequest from "../../../components/general/ContactRequest";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

export default class Selfcare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      accessAllowed: false,
      data: [],
      year: new Date().getFullYear(),
      network: 1,
      group: 0,
      region: 0,
      agency: 0,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({ accessAllowed: checkRights("Selfcare", this.context) });
  }

  getData = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig({
        url: "pilotage-cabinet/selfcare/get-data",
        data: {
          year: this.state.year,
          network: this.state.network ? this.state.network : null,
          group: this.state.group ? this.state.group : null,
          region: this.state.region ? this.state.region : null,
          agency: this.state.agency ? this.state.agency : null,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            data: res.data.data,
            isLoad: false,
          });
        } else {
          this.setState({
            data: [],
            isLoad: false,
          });
        }
      });
    });
  };

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: Number(value) }, () => {
      if (name === "network") {
        this.setState({ group: 0, region: 0, agency: 0 });
      } else if (name === "group") {
        this.setState({ region: 0, agency: 0 });
      } else if (name === "region") {
        this.setState({ agency: 0 });
      }
    });
  };

  getAverage = (arr) => {
    let sum = 0;
    let length = 0;

    arr.forEach((value) => {
      if (value !== null) {
        length++;
        sum += value;
      }
    });

    const avg = sum / length;
    return Number(Math.round(avg + "e+2") + "e-2") || 0;
  };

  getSum = (arr) => {
    let sum = 0;

    arr.forEach((value) => {
      if (value !== null) {
        sum += value;
      }
    });

    return sum;
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Selfcare" className="mb-20" />
          <ContactRequest page="Statistiques - Selfcare" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Selfcare" className="mb-20" />

            <ShowOptions
              className="mb-20"
              handleSelectChange={this.handleSelectChange}
              getData={this.getData}
              isYear={true}
              year={this.state.year}
              network={this.state.network}
              group={this.state.group}
              region={this.state.region}
              agency={this.state.agency}
            />

            {this.state.data.length > 0 ? (
              this.state.data.map((graph, idx) => (
                <GraphCard
                  key={graph.id}
                  height={graph.height ? graph.height : null}
                  title={graph.title}
                  dataSet={graph.dataSet}
                  secondYAxis={idx !== 0 ? true : false}
                  goal={graph.goal ? graph.goal : null}
                  year={this.state.year}
                  average={
                    idx !== 0 && graph.dataSet[0].x && graph.dataSet[0].y
                      ? (this.getSum(graph.dataSet[0].x) /
                          this.getSum(graph.dataSet[0].y)) *
                        100
                      : null
                  }
                  total={
                    idx !== 0
                      ? graph.dataSet[1].data.reduce(
                          (acc, currValue) => acc + currValue,
                          0
                        )
                      : null
                  }
                />
              ))
            ) : (
              <Card>
                <p>Aucune donnée n'est disponible</p>
              </Card>
            )}
          </FadeIn>

          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
