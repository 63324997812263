import React from "react";
import { Row, Col } from "react-flexbox-grid";
import {
    Card
} from "ui-kit-ck-consultant";

import MapSvg from "./svg/MapSvg";

import "./map.css";

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupToDisplay: "",
            zone: false,
            group: false,
            currentDep: null,
            currentYear: new Date().getFullYear(),
            currentGroups: [],
            currentAgency: null,
            currentGroup: null,
            currentData: [],
            currentGroupData: []
        };
    }

    // DISPLAYS ZONE INFOS
    displayZoneInfo = (zone = null) => {
        if (zone && zone.groups && zone.groups.length > 0) {
            let currentGroups = [];
            zone.groups.forEach(group => {
                if (group.data.filter(element => Number(element.year) === this.state.currentYear)[0]) {
                    currentGroups.push({
                        group: group.group,
                        data: group.data.filter(element => Number(element.year) === this.state.currentYear)[0]
                    });
                } else {
                    currentGroups.push({
                        group: group.group,
                        data: { value: 0 }
                    });
                }
            });
            this.setState({ 
                zone: true, 
                currentDep: zone.departmentName, 
                currentGroups: currentGroups 
            });
        } else {
            this.setState({ 
                zone: false, 
                currentDep: null, 
                currentGroups: []
            });
        }
    }
        
    // DISPLAYS AGENCIES INFOS    
    displayAgencyInfo = (group = null) => {
        if (group) {
            let groupData = this.props.groups.filter(element => element.id === group.groupId)[0];
            this.setState({ 
                group: true, 
                currentAgency: group.agencyName,
                currentGroup: group.group,
                currentGroupData: groupData
            });
            if (group.data && group.data.length > 0) {
                this.setState({ currentData: group.data });
            }
        } else {
            if (!this.state.groupToDisplay) {
                this.setState({
                    group: false,
                    currentAgency: null,
                    currentGroup: null,
                    currentData: [],
                    currentGroupData: []
                });
            } else {
                this.setState({
                    group: false,
                    currentAgency: null,
                    currentData: []
                });
            }
        }
    }

    // DISPLAY GROUP INFOS
    toggleData = (groupToDisplay) => {
        if (this.state.groupToDisplay === groupToDisplay.name) {
            this.setState({ 
                groupToDisplay: "",
                currentGroup: null,
                currentData: [],
                currentGroupData: null
            });
        } else {
            this.setState({ 
                groupToDisplay: groupToDisplay.name,
                currentGroup: groupToDisplay.name,
                currentData: [],
                currentGroupData: groupToDisplay
            });
        }
    }

    render() {
        let infoBox = this.state.groupToDisplay ? (
            this.state.currentGroup && this.state.currentGroupData ? (
                <div className="map-info-wrapper">
                    {this.state.zone && !this.state.group ? (
                        this.state.currentDep && this.state.currentYear && this.state.currentGroups ? (
                            <>
                                <h2>{this.state.currentDep} ({this.state.currentYear})</h2>
                                {this.state.currentGroups.map((group, idx) => (
                                    <p key={idx}>{group.group} : {group.data.value} missions</p>
                                ))} 
                                <hr />
                            </>  
                        ) : null
                    ) : this.state.group ? (
                        this.state.currentAgency && this.state.currentGroup ? (
                            <>
                                <h2>{this.state.currentAgency}</h2>
                                {this.state.currentData && this.state.currentData.length > 0 ? (
                                    this.state.currentData.map((data, idx) => (
                                        <p key={idx}>{data.year} : {data.value} missions</p>
                                    ))
                                ) : (
                                    <p>Aucune mission confiée</p>
                                )}
                                <hr />
                            </>
                        ) : null
                    ) : null}
                    <h2>{this.state.currentGroup}</h2>
                    <p><span>Forme juridique :</span> {this.state.currentGroupData.legalForm}</p>
                    <p><span>Dernier CA connu :</span> {this.state.currentGroupData.lastIncome}</p>
                    <p><span>CA confié :</span> {this.state.currentGroupData.incomeVolume}</p>
                    <p><span>% CA Confié / Dernier CA :</span> {this.state.currentGroupData.incomeRate}</p>
                    <p><span>Secteurs couverts :</span> {this.state.currentGroupData.coveredArea}</p>
                    <p><span>Secteurs confiés :</span> {this.state.currentGroupData.activeArea}</p>
                    <p><span>Total missions confiées :</span> {this.state.currentGroupData.missions}</p>
                </div>
            ) : null
        // ZONE INFOS
        ) : this.state.zone && !this.state.group ? (
            this.state.currentDep && this.state.currentYear && this.state.currentGroups ? (
                <div className="map-info-wrapper">
                    <h2>{this.state.currentDep} ({this.state.currentYear})</h2>
                    {this.state.currentGroups.map((group, idx) => (
                        <p key={idx}>{group.group} : {group.data.value} missions</p>
                    ))} 
                </div>  
            ) : null
        ) : (
            // AGENCY INFOS
            this.state.group ? (
                this.state.currentAgency && this.state.currentGroup ? (
                    <div className="map-info-wrapper">
                        <h2>{this.state.currentAgency}</h2>
                        {this.state.currentData && this.state.currentData.length > 0 ? (
                            this.state.currentData.map((data, idx) => (
                                <p key={idx}>{data.year} : {data.value} missions</p>
                            ))
                        ) : (
                            <p>Aucune mission confiée</p>
                        )}
                        <hr />
                        <h2>{this.state.currentGroup}</h2>
                        <p><span>Forme juridique :</span> {this.state.currentGroupData.legalForm}</p>
                        <p><span>Dernier CA connu :</span> {this.state.currentGroupData.lastIncome}</p>
                        <p><span>CA confié :</span> {this.state.currentGroupData.incomeVolume}</p>
                        <p><span>% CA Confié / Dernier CA :</span> {this.state.currentGroupData.incomeRate}</p>
                        <p><span>Secteurs couverts :</span> {this.state.currentGroupData.coveredArea}</p>
                        <p><span>Secteurs confiés :</span> {this.state.currentGroupData.activeArea}</p>
                        <p><span>Total missions confiées :</span> {this.state.currentGroupData.missions}</p>
                    </div>
                ) : null
            ) : null
        );

        let groupsFirstHalf = this.props.groups.filter((element, idx) => {
            return idx < Math.ceil(this.props.groups.length / 2)
        });
        let groupsSecondHalf = this.props.groups.filter((element, idx) => {
            return idx >= Math.ceil(this.props.groups.length / 2)
        });

        return (
            <Card>
                <Row>
                    {/* MAP */}
                    <Col xs={12} lg={8} xl={9}>
                        <MapSvg 
                            data={this.props.data} 
                            groupToDisplay={this.state.groupToDisplay}
                            onZoneHovering={this.displayZoneInfo}
                            onAgencyHovering={this.displayAgencyInfo}
                        />
                    </Col>

                    {/* INFOS */}
                    <Col xs={12} lg={4} xl={3}>
                        {/* LEGEND */}
                        <Row style={{ padding: "10px" }}>
                            <Col xs={6}>
                                {groupsFirstHalf.map((group, idx) => (
                                    <Row 
                                        key={idx} 
                                        className={`map-legend-item ${this.state.groupToDisplay === group.name ? "active" : ""}`}
                                        onClick={() => this.toggleData(group)}
                                    >
                                        <Col>
                                            <div 
                                                className="map-legend-color" 
                                                style={{backgroundColor: `${group.color}`}}
                                            />
                                        </Col>
                                        <Col>
                                            <p className="map-legend-text">{group.name}</p>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                            <Col xs={6}>
                                {groupsSecondHalf.map((group, idx) => (
                                    <Row 
                                        key={idx} 
                                        className={`map-legend-item ${this.state.groupToDisplay === group.name ? "active" : ""}`}
                                        onClick={() => this.toggleData(group)}
                                    >
                                        <Col>
                                            <div 
                                                className="map-legend-color" 
                                                style={{backgroundColor: `${group.color}`}}
                                            />
                                        </Col>
                                        <Col>
                                            <p className="map-legend-text">{group.name}</p>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>

                        {/* GROUPS INFO */}
                        <Row>
                            {infoBox}
                        </Row>
                    </Col>
                </Row>

                
            </Card>
        )
    }
}
