import React from "react";
import {
    Modal,
    FormSelect,
    FormInput,
    FormTextarea,
    Button,
} from "ui-kit-ck-consultant";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";

export default class ModalAgencyGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Form entries
            groupName: "",
            legalForm: "Groupe",
            lastIncome: null,
            incomeVolume: null,
            incomeRate: null,
            coveredArea: "",
            activeArea: "",
            missions: 0,
            color: "#000000",
            companyId: 1,
            // Form data
            legalFormSelect: [
                { value: "DEFAULT", text: ""},
                { value: "Groupe", text: "Groupe" }, 
                { value: "Réseau", text: "Réseau" }
            ],
            companiesSelect: [],
            isError: false
        }
    }

    static contextType = AuthContext;
    
    componentDidMount() {
        this.getCompanies();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.id) {
            this.setState({
                groupName: this.props.groupName,
                legalForm: this.props.legalForm ? this.props.legalForm : "DEFAULT",
                lastIncome: this.props.lastIncome,
                incomeVolume: this.props.incomeVolume,
                incomeRate: this.props.incomeRate,
                coveredArea: this.props.coveredArea,
                activeArea: this.props.activeArea,
                missions: this.props.missions,
                color: this.props.color,
                companyId: this.props.companyId,
                isError: false
            });
        } else if (prevProps.show !== this.props.show && !this.props.id) {
            this.setState({
                groupName: "",
                legalForm: "Groupe",
                lastIncome: null,
                incomeVolume: null,
                incomeRate: null,
                coveredArea: "",
                activeArea: "",
                missions: 0,
                color: "#000000",
                companyId: this.state.companiesSelect[0].value,
                isError: false,
            });
        }
    }

    getCompanies = () => {
        axiosConfig("admin/general/get-all-companies").then(res => {
            if (res.data.success === true) {
                let companiesSelect = res.data.data.map(element => {
                    return { value: Number(element.id), text: element.name }
                });
                this.setState({ companiesSelect: companiesSelect });
            } else {
                this.setState({ companiesSelect: [] });
            }
        });
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            if (name === "lastIncome" || name === "incomeVolume") {
                this.setState({ 
                    incomeRate: this.state.incomeVolume / this.state.lastIncome
                        ? Math.round(this.state.incomeVolume / this.state.lastIncome * 100)
                        : 0
                });
            }
        });

        
    }

    handleAddGroupClick = () => {
        if (this.state.groupName) {
            axiosConfig({
                url: "admin/agency/add-group",
                data: {
                    companyId: this.context.companyId ? this.context.companyId : this.state.companyId,
                    name: this.state.groupName,
                    color: this.state.color,
                    legalForm: this.state.legalForm !== "DEFAULT" ? this.state.legalForm : null,
                    lastIncome: this.state.lastIncome !== "" ? this.state.lastIncome : null,
                    incomeVolume: this.state.incomeVolume !== "" ? this.state.incomeVolume : null, 
                    incomeRate: typeof this.state.incomeRate === "number" ? this.state.incomeRate : null
                }
              }).then(res => {
                    if (res.data.success === true) {
                        alert("Le groupe a bien été ajouté");
                        this.props.onClose();
                    } else {
                        alert("Erreur lors de la sauvegarde");
                        this.props.onClose();
                    }
              });
        } else {
            this.setState({ isError: true });
        }
    }

    handleEditGroupClick = () => {
        if (this.state.groupName) {
            axiosConfig({
                url: "admin/agency/edit-group",
                data: {
                    id: this.props.id,
                    companyId: this.context.companyId ? this.context.companyId : this.state.companyId,
                    name: this.state.groupName,
                    color: this.state.color,
                    legalForm: this.state.legalForm !== "DEFAULT" ? this.state.legalForm : null,
                    lastIncome: this.state.lastIncome !== "" ? this.state.lastIncome : null,
                    incomeVolume: this.state.incomeVolume !== "" ? this.state.incomeVolume : null, 
                    incomeRate: typeof this.state.incomeRate === "number" ? this.state.incomeRate : null
                }
              }).then(res => {
                if (res.data.success === true) {
                    alert("Le groupe a bien été mis à jour");
                    this.props.onClose();
                } else {
                    alert("Erreur lors de la sauvegarde");
                    this.props.onClose();
                }
          });
        } else {
            this.setState({ isError: true });
        }
    }

    render () {
        return (
            <Modal
                title={this.props.isEdit ? `Modifier ${this.props.groupName}` : "Ajouter un groupe"}
                show={this.props.show}
                onClose={this.props.onClose}
            >
                {this.context.companyId === null ? (
                    <FormSelect
                        className="mb-20"
                        name="companyId"
                        value={this.state.companyId}
                        options={this.state.companiesSelect} 
                        title="Compagnie"
                        onChange={this.handleInputChange}
                        ignore={true}
                        init="Veuillez sélectionner une option"
                    />
                ) : null}
                <FormInput
                    className="mb-20"
                    type="text"
                    name="groupName"
                    value={this.state.groupName}
                    title="Groupe"
                    onChange={this.handleInputChange}
                    error={this.state.isError === true && !this.state.groupName ? "Veuillez remplir ce champ" : ""}
                />
                <FormSelect
                    className="mb-20"
                    name="legalForm"
                    value={this.state.legalForm}
                    options={this.state.legalFormSelect} 
                    title="Forme juridique"
                    onChange={this.handleInputChange}
                    ignore={true}
                />
                <FormInput
                    className="mb-20"
                    type="number"
                    name="lastIncome"
                    value={this.state.lastIncome}
                    title="Dernier chiffre d'affaire connu"
                    onChange={this.handleInputChange}
                />
                <FormInput
                    className="mb-20"
                    type="number"
                    name="incomeVolume"
                    value={this.state.incomeVolume}
                    title="Volume chiffre d'affaire confié"
                    onChange={this.handleInputChange}
                />
                <FormInput
                    className="mb-20"
                    type="number"
                    name="incomeRate"
                    value={this.state.incomeRate}
                    title="% CA confié / Dernier CA connu"
                    disabled={true}
                />
                <FormTextarea 
                    className="mb-20" 
                    type="text"
                    name="coveredArea" 
                    value={this.state.coveredArea}
                    placeholder={this.state.coveredArea ? null : "Aucun secteur couvert"}
                    title="Secteurs couverts" 
                    rows={this.state.coveredArea ? 3 : 1}
                    disabled={true}
                />
                <FormTextarea 
                    className="mb-20"  
                    type="text" 
                    name="activeArea" 
                    value={this.state.activeArea} 
                    placeholder={this.state.activeArea ? null : "Aucun secteur confié"}
                    title="Secteurs confiés" 
                    rows={this.state.activeArea ? 3 : 1} 
                    disabled={true}
                />
                <FormInput
                    className="mb-20"
                    type="number"
                    name="missions"
                    value={this.state.missions}
                    title="Nombre total de missions confiées"
                    disabled={true}
                />
                <FormInput
                    className="mb-20"
                    type="color"
                    name="color"
                    value={this.state.color}
                    title="Couleur"
                    onChange={this.handleInputChange}
                />
                <Button
                    className="mb-4"
                    text={this.props.isEdit ? "Modifier" : "Ajouter"}
                    onClick={this.props.isEdit ? this.handleEditGroupClick : this.handleAddGroupClick}
                />
            </Modal>
        )
    }
}