import React from "react";
import {
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Card,
  FormInput,
  Button,
  ButtonIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Pagination,
  PaginationClass,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faEdit,
  faTrashAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import ModalAgencyGroup from "../../../components/admin/admin-agency/ModalAgencyGroup";
import ModalAgency from "../../../components/admin/admin-agency/ModalAgency";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

export default class AdminAgency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      search: "",
      data: [],
      order: { name: "groupId", desc: false },
      // Tabs
      tabsMenu: ["Groupe", "Agence"],
      tabsIndex: 0,
      // Pagination
      activePage: 1,
      tabLength: 20,
      totalData: 0,
      // Modal groups states
      isModalGroupAdd: false,
      isModalGroupEdit: false,
      modalGroupId: null,
      modalGroupColor: null,
      modalGroupName: null,
      modalGroupLegalForm: null,
      modalGroupLastIncome: null,
      modalGroupIncomeVolume: null,
      modalGroupIncomeRate: null,
      modalGroupCoveredArea: null,
      modalGroupActiveArea: null,
      modalGroupMissions: null,
      modalGroupCompanyId: null,
      // Modal agencies states
      isModalAgencyAdd: false,
      isModalAgencyEdit: false,
      modalAgencyId: null,
      modalAgencyName: null,
      modalAgencyGroupId: null,
      modalAgencyDepartmenId: null,
      modalAgencyYear3: null,
      modalAgencyYear2: null,
      modalAgencyYear1: null,
      modalAgencyCompanyId: null,
    };
    this.refColumnGroupName = React.createRef();
    this.refColumnLegalForm = React.createRef();
    this.refColumnLastIncome = React.createRef();
    this.refColumnIncomeVolume = React.createRef();
    this.refColumnIncomeRate = React.createRef();
    this.refColumnMissions = React.createRef();
    this.refColumnGroupCompany = React.createRef();
    this.refColumnAgencyName = React.createRef();
    this.refColumnAgencyGroup = React.createRef();
    this.refColumnAgencyCompany = React.createRef();
    this.refColumnDepartment = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({
      accessAllowed: checkRights("Administration", this.context),
    });
    this.GroupPaginationClass = new PaginationClass(
      "admin/agency/count-group",
      "admin/agency/pagination-group",
      this.state.tabLength
    );
    this.AgencyPaginationClass = new PaginationClass(
      "admin/agency/count",
      "admin/agency/pagination",
      this.state.tabLength
    );
    this.initSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.isModalGroupAdd !== this.state.isModalGroupAdd &&
        this.state.isModalGroupAdd === false) ||
      (prevState.isModalGroupEdit !== this.state.isModalGroupEdit &&
        this.state.isModalGroupEdit === false) ||
      (prevState.isModalAgencyAdd !== this.state.isModalAgencyAdd &&
        this.state.isModalAgencyAdd === false) ||
      (prevState.isModalAgencyEdit !== this.state.isModalAgencyEdit &&
        this.state.isModalAgencyEdit === false)
    ) {
      this.initSearch();
    } else if (prevState.tabsIndex !== this.state.tabsIndex) {
      this.initSearch();
    }
  }

  initSearch = () => {
    this.setState({ activePage: 1 }, () => {
      if (this.state.tabsIndex === 0) {
        this.GroupPaginationClass.init(
          axiosConfig,
          this.getData(),
          (result) => {
            if (result.success === true) {
              this.setState({ totalData: result.total }, () => {
                this.GroupPaginationClass.get(
                  axiosConfig,
                  this.getData(),
                  (result) => {
                    if (result.success === true) {
                      this.setState({ data: result.tabData });
                    } else {
                      this.setState({ data: [] });
                    }
                  }
                );
              });
            }
          }
        );
      } else if (this.state.tabsIndex === 1) {
        this.AgencyPaginationClass.init(
          axiosConfig,
          this.getData(),
          (result) => {
            if (result.success === true) {
              this.setState({ totalData: result.total }, () => {
                this.AgencyPaginationClass.get(
                  axiosConfig,
                  this.getData(),
                  (result) => {
                    if (result.success === true) {
                      this.setState({ data: result.tabData });
                    } else {
                      this.setState({ data: [] });
                    }
                  }
                );
              });
            }
          }
        );
      }
    });
  };

  getData = () => {
    return {
      search: this.state.search,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  };

  handleSearchInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.initSearch);
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnGroupName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnLegalForm.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnLastIncome.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnIncomeVolume.current.setState({ desc: false, sort: false });
    }
    if (column !== 4) {
      this.refColumnIncomeRate.current.setState({ desc: false, sort: false });
    }
    if (column !== 5) {
      this.refColumnMissions.current.setState({ desc: false, sort: false });
    }
    if (column !== 6) {
      this.refColumnAgencyName.current.setState({ desc: false, sort: false });
    }
    if (column !== 7) {
      this.refColumnAgencyGroup.current.setState({ desc: false, sort: false });
    }
    if (column !== 8) {
      this.refColumnDepartment.current.setState({ desc: false, sort: false });
    }
    if (!this.context.companyId && column !== 9) {
      this.refColumnGroupCompany.current.setState({ desc: false, sort: false });
    }
    if (!this.context.companyId && column !== 10) {
      this.refColumnAgencyCompany.current.setState({
        desc: false,
        sort: false,
      });
    }

    let order = null;

    switch (column) {
      case 0:
        order = { name: "groupName", desc: desc };
        break;
      case 1:
        order = { name: "legalForm", desc: desc };
        break;
      case 2:
        order = { name: "lastIncome", desc: desc };
        break;
      case 3:
        order = { name: "incomeVolume", desc: desc };
        break;
      case 4:
        order = { name: "incomeRate", desc: desc };
        break;
      case 5:
        order = { name: "missions", desc: desc };
        break;
      case 6:
        order = { name: "agencyName", desc: desc };
        break;
      case 7:
        order = { name: "groupName", desc: desc };
        break;
      case 8:
        order = { name: "department", desc: desc };
        break;
      case 9:
      case 10:
        order = { name: "company", desc: desc };
        break;
      default:
        order = {
          name: this.state.tabsIndex === 0 ? "groupId" : "agencyId",
          desc: desc,
        };
        break;
    }
    this.setState({ order: order }, () => {
      this.initSearch();
    });
  };

  extractData = (data, year) => {
    let filteredData = data.filter((element) => Number(element.year) === year);
    if (filteredData.length > 0) {
      return filteredData[0].value;
    } else {
      return "";
    }
  };

  handleDeleteGroupClick = (group) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer le groupe ${group.name} ?`
      )
    ) {
      axiosConfig({
        url: "admin/agency/delete-group",
        data: {
          id: group.id,
        },
      }).then((res) => {
        if (res.data.success) {
          axiosConfig({
            url: "admin/agency/delete-many-agencies",
            data: {
              groupId: group.id,
            },
          }).then((res) => {
            if (res.data.success) {
              alert("Le groupe a bien été supprimé");
              this.initSearch();
            } else {
              alert(
                "Erreur lors de la suppression (agences associées non supprimées"
              );
              this.initSearch();
            }
          });
        } else {
          alert("Erreur lors de la suppression");
        }
      });
    }
  };

  handleDeleteAgencyClick = (agency) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer l'agence ${agency.name} ?`
      )
    ) {
      axiosConfig({
        url: "admin/agency/delete-agency",
        data: {
          id: agency.id,
        },
      }).then((res) => {
        if (res.data.success) {
          alert("L'agence a bien été supprimée");
          this.initSearch();
        } else {
          alert("Erreur lors de la suppression");
        }
      });
    }
  };

  handlePageChange = (activePage) => {
    if (this.state.tabsIndex === 0) {
      this.GroupPaginationClass.page(activePage, () => {
        this.setState({ activePage: activePage }, () => {
          this.GroupPaginationClass.get(
            axiosConfig,
            this.getData(),
            (result) => {
              if (result.success === true) {
                this.setState({ data: result.tabData });
              } else {
                this.setState({ data: [] });
              }
            }
          );
        });
      });
    } else if (this.state.tabsIndex === 1) {
      this.AgencyPaginationClass.page(activePage, () => {
        this.setState({ activePage: activePage }, () => {
          this.AgencyPaginationClass.get(
            axiosConfig,
            this.getData(),
            (result) => {
              if (result.success === true) {
                this.setState({ data: result.tabData });
              } else {
                this.setState({ data: [] });
              }
            }
          );
        });
      });
    }
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <h2>
          Oups... Vous n'avez pas les autorisations nécessaires pour accéder à
          cette page...
        </h2>
      ) : (
        <>
          <FadeIn>
            {/* HEADER */}
            <Nav title="Experts" className="mb-20">
              <Button
                text={
                  this.state.tabsIndex === 0
                    ? "Ajouter un groupe"
                    : "Ajouter une agence"
                }
                onClick={() =>
                  this.state.tabsIndex === 0
                    ? this.setState({ isModalGroupAdd: true })
                    : this.setState({ isModalAgencyAdd: true })
                }
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Nav>

            {/* SEARCH BAR */}
            <Row>
              <Col sm={12} md={6}>
                <Card className="mb-20">
                  <FormInput
                    value={this.state.search}
                    name="search"
                    onChange={this.handleSearchInputChange}
                    placeholder={
                      this.state.tabsIndex === 0
                        ? this.context.companyId
                          ? "Nom du groupe"
                          : "Nom ou compagnie du groupe"
                        : this.context.companyId
                        ? "Nom, groupe ou départment de l'agence"
                        : "Nom, groupe, compagnie ou départment de l'agence"
                    }
                  >
                    <FontAwesomeIcon className="icon" icon={faSearch} />
                  </FormInput>
                </Card>
              </Col>
            </Row>

            {/* TABLE */}
            <TabsCustom>
              <TabsCustomHeader>
                {this.state.tabsMenu.map((menu, idx) => (
                  <TabsCustomMenu
                    key={idx}
                    title={menu}
                    active={this.state.tabsIndex === idx}
                    onClick={(event) => this.setState({ tabsIndex: idx })}
                  />
                ))}
              </TabsCustomHeader>

              <TabsCustomBody noCard>
                {this.state.tabsMenu.map((element, idx) => (
                  <TabsCustomItem
                    key={idx}
                    active={this.state.tabsIndex === idx}
                  >
                    <Card
                      title={
                        idx === 0 ? "Liste des groupes" : "Liste des agences"
                      }
                      className="mb-20"
                      classNameChildren="overflow-x-auto"
                    >
                      {idx === 0 ? (
                        // GROUPS TABLE
                        <Table
                          empty={
                            this.state.data.length > 0
                              ? ""
                              : "Aucune donnée n'est disponible"
                          }
                          radiusTopLeft
                        >
                          <Thead>
                            <Tr>
                              <Th
                                hide={this.state.data.length > 0 ? true : false}
                              ></Th>
                              <Th
                                ref={this.refColumnGroupName}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 0)}
                                radiusTopLeft={
                                  this.state.data.length > 0 ? true : false
                                }
                              >
                                Nom
                              </Th>
                              {this.context.companyId ? null : (
                                <Th
                                  ref={this.refColumnGroupCompany}
                                  sort
                                  onSort={(desc) =>
                                    this.handleSortClick(desc, 9)
                                  }
                                >
                                  Compagnie
                                </Th>
                              )}
                              <Th
                                ref={this.refColumnLegalForm}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 1)}
                              >
                                Forme juridique
                              </Th>
                              <Th
                                ref={this.refColumnLastIncome}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 2)}
                              >
                                Dernier CA connu
                              </Th>
                              <Th
                                ref={this.refColumnIncomeVolume}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 3)}
                              >
                                CA confié
                              </Th>
                              <Th
                                ref={this.refColumnIncomeRate}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 4)}
                              >
                                % CA confié / dernier CA
                              </Th>
                              <Th>Secteurs couverts</Th>
                              <Th>Secteurs confiés</Th>
                              <Th
                                ref={this.refColumnMissions}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 5)}
                              >
                                Total missions confiées
                              </Th>
                              <Th>Actions</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {this.state.data.map((element, idx) => (
                              <Tr key={element.id}>
                                <Th radiusTopLeft={idx === 0 ? true : false}>
                                  {(this.state.activePage - 1) *
                                    this.state.tabLength +
                                    idx +
                                    1}
                                </Th>
                                <Td>
                                  <div className="d-flex">
                                    <div
                                      className="mt-auto mb-auto mr-10"
                                      style={{
                                        backgroundColor: element.color,
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "10px",
                                      }}
                                    />
                                    <div className="mt-auto mb-auto">
                                      {element.name}
                                    </div>
                                  </div>
                                </Td>
                                {this.context.companyId ? null : (
                                  <Td>{element.companyName}</Td>
                                )}
                                <Td>{element.legalForm}</Td>
                                <Td>{element.lastIncome}</Td>
                                <Td>{element.incomeVolume}</Td>
                                <Td>
                                  {element.incomeRate}{" "}
                                  {element.incomeRate !== null ? "%" : ""}
                                </Td>
                                <Td>{element.coveredArea}</Td>
                                <Td>{element.activeArea}</Td>
                                <Td>{element.missions}</Td>
                                <Td className="nowrap">
                                  <div className="d-flex">
                                    <ButtonIcon
                                      small
                                      style={{ color: "var(--primary-color" }}
                                      onClick={() =>
                                        this.setState({
                                          isModalGroupEdit: true,
                                          modalGroupId: element.id,
                                          modalGroupColor: element.color,
                                          modalGroupName: element.name,
                                          modalGroupLegalForm:
                                            element.legalForm,
                                          modalGroupLastIncome:
                                            element.lastIncome,
                                          modalGroupIncomeVolume:
                                            element.incomeVolume,
                                          modalGroupIncomeRate:
                                            element.incomeRate,
                                          modalGroupCoveredArea:
                                            element.coveredArea,
                                          modalGroupActiveArea:
                                            element.activeArea,
                                          modalGroupMissions: element.missions,
                                          modalGroupCompanyId:
                                            element.companyId,
                                        })
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </ButtonIcon>
                                    <ButtonIcon
                                      small
                                      style={{ color: "var(--primary-color" }}
                                      onClick={() =>
                                        this.handleDeleteGroupClick(element)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </ButtonIcon>
                                  </div>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      ) : (
                        // AGENCIES TABLE
                        <Table
                          empty={
                            this.state.data.length > 0
                              ? ""
                              : "Aucune donnée n'est disponible"
                          }
                          radiusTopLeft
                        >
                          <Thead>
                            <Tr>
                              <Th
                                hide={this.state.data.length > 0 ? true : false}
                              ></Th>
                              <Th
                                ref={this.refColumnAgencyName}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 6)}
                                radiusTopLeft={
                                  this.state.data.length > 0 ? true : false
                                }
                              >
                                Nom
                              </Th>
                              <Th
                                ref={this.refColumnAgencyGroup}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 7)}
                              >
                                Groupe
                              </Th>
                              {this.context.companyId ? null : (
                                <Th
                                  ref={this.refColumnAgencyCompany}
                                  sort
                                  onSort={(desc) =>
                                    this.handleSortClick(desc, 10)
                                  }
                                >
                                  Compagnie
                                </Th>
                              )}
                              <Th
                                ref={this.refColumnDepartment}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 8)}
                              >
                                Département
                              </Th>
                              <Th>Actions</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {this.state.data.map((element, idx) => (
                              <Tr key={element.id}>
                                <Th radiusTopLeft={idx === 0 ? true : false}>
                                  {(this.state.activePage - 1) *
                                    this.state.tabLength +
                                    idx +
                                    1}
                                </Th>
                                <Td>
                                  <div className="d-flex">
                                    <div
                                      className="mt-auto mb-auto mr-10"
                                      style={{
                                        backgroundColor: element.color,
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "10px",
                                      }}
                                    />
                                    <div className="mt-auto mb-auto">
                                      {element.name}
                                    </div>
                                  </div>
                                </Td>
                                <Td>{element.group}</Td>
                                {this.context.companyId ? null : (
                                  <Td>{element.companyName}</Td>
                                )}
                                <Td>
                                  {element.zip} - {element.department}
                                </Td>
                                <Td className="nowrap">
                                  <div className="d-flex">
                                    <ButtonIcon
                                      small
                                      style={{ color: "var(--primary-color" }}
                                      onClick={() =>
                                        this.setState({
                                          isModalAgencyEdit: true,
                                          modalAgencyId: element.id,
                                          modalAgencyName: element.name,
                                          modalAgencyGroupId: element.groupId,
                                          modalAgencyDepartmenId:
                                            element.departmentId,
                                          modalAgencyYear3: this.extractData(
                                            element.data,
                                            new Date().getFullYear() - 2
                                          ),
                                          modalAgencyYear2: this.extractData(
                                            element.data,
                                            new Date().getFullYear() - 1
                                          ),
                                          modalAgencyYear1: this.extractData(
                                            element.data,
                                            new Date().getFullYear()
                                          ),
                                          modalAgencyCompanyId:
                                            element.companyId,
                                        })
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </ButtonIcon>
                                    <ButtonIcon
                                      small
                                      style={{ color: "var(--primary-color" }}
                                      onClick={() =>
                                        this.handleDeleteAgencyClick(element)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </ButtonIcon>
                                  </div>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}

                      <Pagination
                        className="mt-10"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.tabLength}
                        totalItemsCount={this.state.totalData}
                        pageRangeDisplayed={4}
                        onChange={this.handlePageChange}
                        firstPageText={
                          <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        }
                        lastPageText={
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        }
                        prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
                        nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
                      />
                    </Card>
                  </TabsCustomItem>
                ))}
              </TabsCustomBody>
            </TabsCustom>
          </FadeIn>

          {/* ADD OR EDIT GROUP */}
          <ModalAgencyGroup
            show={this.state.isModalGroupAdd || this.state.isModalGroupEdit}
            isEdit={this.state.isModalGroupEdit}
            id={this.state.modalGroupId}
            color={this.state.modalGroupColor}
            groupName={this.state.modalGroupName}
            legalForm={this.state.modalGroupLegalForm}
            lastIncome={this.state.modalGroupLastIncome}
            incomeVolume={this.state.modalGroupIncomeVolume}
            incomeRate={this.state.modalGroupIncomeRate}
            coveredArea={this.state.modalGroupCoveredArea}
            activeArea={this.state.modalGroupActiveArea}
            missions={this.state.modalGroupMissions}
            companyId={this.state.modalGroupCompanyId}
            onClose={() =>
              this.setState({
                isModalGroupAdd: false,
                isModalGroupEdit: false,
                modalGroupId: null,
                modalGroupColor: null,
                modalGroupName: null,
                modalGroupLegalForm: null,
                modalGroupLastIncome: null,
                modalGroupIncomeVolume: null,
                modalGroupIncomeRate: null,
                modalGroupCoveredArea: null,
                modalGroupActiveArea: null,
                modalGroupMissions: null,
                modalGroupCompanyId: null,
              })
            }
          />

          {/* ADD OR EDIT AGENCY */}
          <ModalAgency
            show={this.state.isModalAgencyAdd || this.state.isModalAgencyEdit}
            isEdit={this.state.isModalAgencyEdit}
            id={this.state.modalAgencyId}
            agencyName={this.state.modalAgencyName}
            groupId={this.state.modalAgencyGroupId}
            departmentId={this.state.modalAgencyDepartmenId}
            year3={this.state.modalAgencyYear3}
            year2={this.state.modalAgencyYear2}
            year1={this.state.modalAgencyYear1}
            companyId={this.state.modalAgencyCompanyId}
            onClose={() =>
              this.setState({
                isModalAgencyAdd: false,
                isModalAgencyEdit: false,
                modalAgencyId: null,
                modalAgencyName: null,
                modalAgencyGroupId: null,
                modalAgencyDepartmenId: null,
                modalAgencyYear3: null,
                modalAgencyYear2: null,
                modalAgencyYear1: null,
                modalAgencyCompanyId: null,
              })
            }
          />
        </>
      );
    } else {
      return <></>;
    }
  }
}
