import React from "react";
import {
    Modal,
    FormInput,
    FormSelect,
    Button,
} from "ui-kit-ck-consultant";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";

export default class ModalGarageGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Form entries
            color: "#000000",
            name: "",
            companyId: 1,
            // Form data
            companiesSelect: [],
            isError: false
        }
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.getCompanies();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.id) {
            this.setState({
                color: this.props.color,
                name: this.props.name,
                companyId: this.props.companyId,
                isError: false
            });
        } else if (prevProps.show !== this.props.show && !this.props.id) {
            this.setState({
                color: "#000000",
                name: "",
                companyId: this.state.companiesSelect[0].value,
                isError: false,
            });
        }
    }

    getCompanies = () => {
        axiosConfig("admin/general/get-all-companies").then(res => {
            if (res.data.success === true) {
                let companiesSelect = res.data.data.map(element => {
                    return { value: Number(element.id), text: element.name }
                });
                this.setState({ companiesSelect: companiesSelect });
            } else {
                this.setState({ companiesSelect: [] });
            }
        });
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleAddGroupClick = () => {
        if (this.state.name) {
            axiosConfig({
                url: "admin/garage/add-group",
                data: {
                    companyId: this.context.companyId ? this.context.companyId : this.state.companyId,
                    name: this.state.name,
                    color: this.state.color
                }
            }).then(res => {
                if (res.data.success) {
                    alert("Le réseau a bien été ajouté");
                    this.props.onClose();
                } else {
                    alert("Erreur lors de la sauvegarde");
                    this.props.onClose();
                }
            });
        } else {
            this.setState({ isError: true });
        }
    }

    handleEditGroupClick = () => {
        if (this.state.name) {
            axiosConfig({
                url: "admin/garage/edit-group",
                data: {
                    id: this.props.id,
                    companyId: this.context.companyId ? this.context.companyId : this.state.companyId,
                    name: this.state.name,
                    color: this.state.color
                }
            }).then(res => {
                if (res.data.success) {
                    alert("Le réseau a bien été modifié");
                    this.props.onClose();
                } else {
                    alert("Erreur lors de la sauvegarde");
                    this.props.onClose();
                }
            });
        } else {
            this.setState({ isError: true });
        }
      }

    render () {
        return (
            <Modal
                title={this.props.isEdit ? `Modifier ${this.props.name}` : "Ajouter un réseau"}
                show={this.props.show}
                onClose={this.props.onClose}
            >
                {this.context.companyId === null ? (
                    <FormSelect
                        className="mb-20"
                        name="companyId"
                        value={this.state.companyId}
                        options={this.state.companiesSelect} 
                        title="Compagnie"
                        onChange={this.handleInputChange}
                        ignore={true}
                        init="Veuillez sélectionner une option"
                    />
                ) : null}
                <FormInput
                    className="mb-20"
                    type="text"
                    name="name"
                    value={this.state.name}
                    title="Réseau"
                    onChange={this.handleInputChange}
                    error={this.state.isError === true && !this.state.name ? "Veuillez remplir ce champ" : ""}
                />
                <FormInput 
                    className="mb-20" 
                    type="color"
                    name="color" 
                    value={this.state.color}
                    title="Couleur" 
                    onChange={this.handleInputChange} 
                    
                />
                <Button
                    className="mb-4"
                    text={this.props.isEdit ? "Modifier" : "Ajouter"}
                    onClick={this.props.isEdit ? this.handleEditGroupClick : this.handleAddGroupClick}
                />
          </Modal>
        )
    }
}