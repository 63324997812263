import React from "react";
import { Nav, Loader } from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";

import AuthContext from "../../../context/AuthContext";
import Map from "../../../components/missions/missions-map/Map";
import ContactRequest from "../../../components/general/ContactRequest";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

export default class MissionsMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      isLoad: false,
      data: [],
      groups: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({ accessAllowed: checkRights("Missions", this.context) });
    this.setData();
  }

  setData = () => {
    this.setState({ isLoad: true });

    axiosConfig({
      url: "missions/get-all-agencies",
      data: {
        companyId: this.context.companyId,
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({ data: res.data.data });
        } else {
          this.setState({ data: [] });
        }
      })
      .then(
        axiosConfig({
          url: "missions/get-all-groups",
          data: {
            companyId: this.context.companyId,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({ groups: res.data.data, isLoad: false });
          } else {
            this.setState({ groups: [], isLoad: false });
          }
        })
      );
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Répartition missions" className="mb-20" />
          <ContactRequest page="Répartition missions - Carte" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Répartition missions" className="mb-20" />
            <Map data={this.state.data} groups={this.state.groups} />
          </FadeIn>
          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
