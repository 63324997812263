import React from "react";
import { Nav, Card, FormInput, FormSelect } from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { Col, Row } from "react-flexbox-grid";
import moment from "moment";

import AuthContext from "../../../context/AuthContext";

import checkRights from "../../../utils/checkRights";

import ContactRequest from "../../../components/general/ContactRequest";
import CardAxa from "../../../components/statistics/statistics-axa/CardAXA";

export default class StatisticsAxa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      region: "DEFAULT",
      agency: "DEFAULT",
      department: "DEFAULT",
      regionOptions: [
        { value: "DEFAULT", text: "Toutes les régions" },
        {
          text: "NORD",
          agencies: [
            { value: "DEFAULT", text: "Toutes les agences" },
            { value: "02;59;62;80", text: "ZI 02 + 59 + 62 + 80" },
          ],
        },
        {
          text: "IDF",
          agencies: [
            { value: "DEFAULT", text: "Toutes les agences" },
            { value: "60;77", text: "ZI 60 + 77" },
            { value: "93;94;95", text: "ZI 93 + 94 + 95" },
          ],
        },
        {
          text: "NORD EST",
          agencies: [
            { value: "DEFAULT", text: "Toutes les agences" },
            { value: "67;68;90", text: "ZI 67 + 68 + 90" },
          ],
        },
      ],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({
      accessAllowed: checkRights("StatisticsAxa", this.context),
    });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      agency:
        name === "region"
          ? "DEFAULT"
          : name === "agency"
          ? value
          : this.state.agency,
      department:
        name === "region" || name === "agency"
          ? "DEFAULT"
          : name === "department"
          ? value
          : this.state.department,
    });
  };

  render() {
    if (!this.state.accessAllowed) {
      return (
        <FadeIn>
          <Nav title="Statistiques spécifiques" className="mb-20" />
          <ContactRequest page="Statistiques - Générales" />
        </FadeIn>
      );
    }

    return (
      <>
        <FadeIn>
          <Nav title="Statistiques spécifiques" className="mb-20" />
          <Card title="Filtres" className="mb-20">
            <Row>
              <Col xs={12} md={4} lg={2}>
                <FormInput
                  className="mb-20"
                  title="Date de début"
                  type="date"
                  name="dateStart"
                  value={this.state.dateStart}
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={4} lg={2}>
                <FormInput
                  className="mb-20"
                  title="Date de fin"
                  type="date"
                  name="dateEnd"
                  value={this.state.dateEnd}
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={4} lg={3}>
                <FormSelect
                  className="mb-20"
                  name="region"
                  value={this.state.region}
                  options={this.state.regionOptions.map((region, idx) => ({
                    value: !idx ? "DEFAULT" : idx,
                    text: region.text,
                  }))}
                  title="Région"
                  onChange={this.handleInputChange}
                  ignore={true}
                />
              </Col>
              <Col xs={12} md={4} lg={3}>
                <FormSelect
                  className="mb-20"
                  name="agency"
                  value={this.state.agency}
                  options={
                    this.state.region !== "DEFAULT"
                      ? this.state.regionOptions[this.state.region].agencies
                      : []
                  }
                  title="ZI"
                  onChange={this.handleInputChange}
                  ignore={true}
                />
              </Col>
              <Col xs={12} md={4} lg={2}>
                <FormSelect
                  className="mb-20"
                  name="department"
                  value={this.state.department}
                  options={[
                    {
                      value: "DEFAULT",
                      text: "Veuillez sélectionner un département",
                    },
                    ...(this.state.agency !== "DEFAULT"
                      ? this.state.agency.split(";").map((value) => {
                          return {
                            value,
                            text: value,
                          };
                        })
                      : []),
                  ]}
                  title="Département"
                  onChange={this.handleInputChange}
                  ignore={true}
                />
              </Col>
            </Row>
          </Card>
          <CardAxa
            data={{
              regionOptions: this.state.regionOptions,
              region: this.state.region,
              agency: this.state.agency,
              department: this.state.department,
              dateStart: this.state.dateStart,
              dateEnd: this.state.dateEnd,
            }}
          />
        </FadeIn>
      </>
    );
  }
}
