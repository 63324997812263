import React, { Component } from "react";
import { Card, DoughnutChart } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

export default class Indicators extends Component {
  render() {
    return (
      <Card
        title={`Indicateurs (Total: ${this.props.data.total})`}
        className="mb-10 mb-20-xl"
      >
        <Row
          className="ml-20 mr-20"
          style={{ justifyContent: "space-between" }}
        >
          <Col>
            <DoughnutChart
              max={100}
              className={"m-auto my-10"}
              number={this.props.data.waitAppointment.taux}
              text={`En attende de RDV : ${this.props.data.waitAppointment.count}`}
              high={70}
              medium={40}
              reverse={true}
            />
          </Col>
          <Col>
            <DoughnutChart
              max={100}
              className={"m-auto my-10"}
              number={this.props.data.comingSoonAppointment.taux}
              text={`Expertise à venir : ${this.props.data.comingSoonAppointment.count}`}
              high={70}
              medium={40}
              reverse={true}
            />
          </Col>
          <Col>
            <DoughnutChart
              max={100}
              className={"m-auto my-10"}
              number={this.props.data.visited.taux}
              text={`Expertisé : ${this.props.data.visited.count}`}
              high={70}
              medium={40}
            />
            <p className="text-center gray">* cumulé</p>
          </Col>
          <Col>
            <DoughnutChart
              max={100}
              className={"m-auto my-10"}
              number={this.props.data.noVisited.taux}
              text={`Cloturés sans expertise : ${this.props.data.noVisited.count}`}
              high={70}
              medium={40}
            />
            <p className="text-center gray">* cumulé</p>
          </Col>
          <Col>
            <DoughnutChart
              max={100}
              className={"m-auto my-10"}
              number={this.props.data.closed.taux}
              text={`Cloturés avec expertise : ${this.props.data.closed.count}`}
              high={70}
              medium={40}
            />
            <p className="text-center gray">* cumulé</p>
          </Col>
        </Row>
      </Card>
    );
  }
}
