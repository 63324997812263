import React, { Component } from 'react'
import FadeIn from 'react-fade-in'
import { Status, Table, Tbody, Td, Th, Thead, Tr } from 'ui-kit-ck-consultant'

export class TableReparator extends Component {

  getName = (label) => {
    let name
    if (label === 'nbFolders' || label === 'age') {
      name = label === 'nbFolders' ? 'Nombre de dossiers' : 'Âge véhicule (m)'
    } else if (label === 'hourlyRate' || label === 'missionVisite') {
      name = label === 'hourlyRate' ? 'Tarif horaire (€ HT)' : 'Délai mission-visite (j)'
    } else if (label === 'visitDepot' || label === 'missionDepot') {
      name = label === 'visitDepot' ? 'Délai visite-dépôt (j)' : 'Délai mission-dépôt (j)'
    } else if (label === 'piecesCost' || label === 'nbHours') {
      name = label === 'piecesCost' ? 'Coût pièces (€ HT)' : 'Nombre heures (h)'
    } else if (label === 'nbHoursPaint' || label === 'averageCost') {
      name = label === 'nbHoursPaint' ? 'Nombre heures peinture (h)' : 'Coût réparation (€ HT)'
    } else if (label === 'cost0_750' || label === 'cost750_1500') {
      name = label === 'cost0_750' ? 'Dossiers (Coût réparation 0/750 € HT) (%)' : 'Dossiers (Coût réparation 750/1500 € HT) (%)'
    } else if (label === 'cost1500_2300' || label === 'cost2300_4600') {
      name = label === 'cost1500_2300' ? 'Dossiers (Coût réparation 1500/2300 € HT) (%)' : 'Dossiers (Coût réparation 2300/4600 € HT) (%)'
    } else if (label === 'cost4600_plus' || label === 'RP') {
      name = label === 'cost4600_plus' ? 'Dossiers (Coût réparation >=4600 € HT) (%)' : 'RP (%)'
    } else if (label === 'PRE' || label === 'tauxEAD') {
      name = label === 'PRE' ? 'PRE (%)' : 'Tx d\'EAD (%)'
    } else if (label === 'tauxCancelAndReplace' || label === 'tauxInvoiceValidation') {
      name = label === 'tauxCancelAndReplace' ? 'Tx Annule et Remplace (%)' : 'Tx Validation Facture (%)'
    } else if (label === 'passageTerrainRate' || label === 'VGERate') {
      name = label === 'passageTerrainRate' ? 'Tx Passage Terrain (%)' : 'Tx VGE (%)'
    } 
    return name
  }

  

  renderRow = (key) => {
    const PercentageList = ['RP (%)', 'PRE (%)', 'Tx d\'EAD (%)', 'Tx Annule et Remplace (%)', 'Tx Validation Facture (%)', 'Tx Passage Terrain (%)', 'Tx VGE (%)']
    return (
      <Tr className={'nowrap'} key={key}>
        <Th>{this.getName(key)}</Th>
        <Td>{this.props.garage['n-1'] && PercentageList.includes(this.getName(key)) && (this.props.garage['n-1'][key] || this.props.garage['n-1'][key] === 0) ? this.props.garage['n-1'][key] + '%' : this.props.garage['n-1'] ? this.props.garage['n-1'][key] : null}</Td>
        <Td>{PercentageList.includes(this.getName(key)) && (this.props.garage[key] || this.props.garage[key] === 0) ?  this.props.garage[key] + '%': this.props.garage[key]}</Td>
        <Td spaceRight><Status 
          className={this.color(this.props.garage['n/n-1'][key] ? this.props.garage['n/n-1'][key] : 0, key) + ' nowrap'} 
          text={`${
            this.props.garage['n/n-1'][key] 
            ? this.props.garage['n/n-1'][key]
            : 0
            } %`} /></Td>
        {this.props.garage.monthsData.map((month, idx) => {
          return (
            <Td key={idx} spaceLeft={idx === 0 ? true : false}>{PercentageList.includes(this.getName(key)) && (month[`${key}ByMonth`] || month[`${key}ByMonth`] === 0) ? month[`${key}ByMonth`] + '%': month[`${key}ByMonth`]}</Td>
          )
        })}
      </Tr>
    )
  }


  renderTableContent = () => {
    let result = []
    for (const [key] of Object.entries(this.props.garage)) {
      if (key !== 'id' && key !== 'name' && key !== 'data' && key !== 'n-1' && key !== 'n/n-1' && key !== 'monthsData') {
        let row = this.renderRow(key)
        result.push(row)
      }
    }
    return result
  }

  color(value, key) {
    const inversedColorList = ['averageCost', 'hourlyRate', 'visitDepot' , 'missionVisite', 'missionDepot', 'piecesCost', 'nbHours', 'nbHoursPaint', 'cost1500_2300', 'cost2300_4600', 'cost4600_plus']
    if (value === 0) {
      return "bg-blue-light black w-100";
    }
    if (inversedColorList.includes(key)) {
      if (value > 0) {
        return "bg-red-light black w-100";
      } else if (value < 0) {
        return "bg-green-light black w-100";
      }
    } else {
      if (value > 0) {
       return "bg-green-light black w-100";
     } else if (value < 0) {
       return "bg-red-light black w-100";
     }
    }
  }

  render() {
    let data = this.renderTableContent()
    return (
      <FadeIn>
        <Table 
          className="mb-10"
          empty={
            this.props.garage
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr className={'nowrap'}>
              <Th hide>{this.props.garage.name}</Th>
              <Th radiusTopLeft>N - 1</Th>
              <Th>N</Th>
              <Th spaceRight>N / N - 1</Th>
              <Th spaceLeft>Janvier</Th>
              <Th>Février</Th>
              <Th>Mars</Th>
              <Th>Avril</Th>
              <Th>Mai</Th>
              <Th>Juin</Th>
              <Th>Juillet</Th>
              <Th>Août</Th>
              <Th>Septembre</Th>
              <Th>Octobre</Th>
              <Th>Novembre</Th>
              <Th>Décembre</Th>

            </Tr>
          </Thead>
          <Tbody>
            {data}
          </Tbody>
        </Table>
      </FadeIn>
    )
  }
}

export default TableReparator
