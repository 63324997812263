import React from "react";
import {
    Modal,
    FormInput,
    FormSelect,
    FormCheckbox,
    Button,
} from "ui-kit-ck-consultant";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";

export default class ModalUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Form entries
            name: "",
            email: "",
            companyId: 1,
            admin: false,
            // Form data
            companiesSelect: [],
            isError: false
        }
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.getCompanies();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.id) {
            this.setState({
                name: this.props.name,
                email: this.props.email,
                companyId: this.props.companyId ? this.props.companyId : "DEFAULT",
                admin: this.props.admin,
                isError: false
            });
        } else if (prevProps.show !== this.props.show && !this.props.id) {
            this.setState({
                name: "",
                email: "",
                companyId: this.state.companiesSelect[1].value,
                admin: false,
                isError: false,
            });
        }
    }

    getCompanies = () => {
        axiosConfig("admin/general/get-all-companies").then(res => {
            if (res.data.success === true) {
                let companiesSelect = res.data.data.map(element => {
                    return { value: Number(element.id), text: element.name }
                });
                this.setState({ companiesSelect: [ { value: "DEFAULT", text: "Administrateur" }, ...companiesSelect] });
            } else {
                this.setState({ companiesSelect: [] });
            }
        });
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            if (name === "companyId" && value === "DEFAULT") {
                this.setState({ admin: true });
            }
        });
    }

    handleCheckboxChange = event => {
        const { name, checked } = event.target;
        this.setState({ [name]: checked });
    }

    handleAddUserClick = () => {
        if (this.state.name && this.state.email) {
            axiosConfig({
                url: "admin/user/add-user",
                data: {
                    companyId: this.context.companyId 
                        ? this.context.companyId 
                        : (this.state.companyId !== "DEFAULT" 
                            ? this.state.companyId
                            : null),
                    name: this.state.name,
                    email: this.state.email,
                    admin: this.state.admin
                }
            }).then(res => {
                if (res.data.success) {
                    alert("L'utilisateur a bien été ajouté");
                    this.props.onClose();
                } else {
                    alert("Erreur lors de la sauvegarde");
                    this.props.onClose();
                }
            });
        } else {
            this.setState({ isError: true });
        }
    }

    handleEditUserClick = () => {
        if (this.state.name && this.state.email) {
            axiosConfig({
                url: "admin/user/edit-user",
                data: {
                    id: this.props.id,
                    companyId: this.context.companyId 
                        ? this.context.companyId 
                        : (this.state.companyId !== "DEFAULT" 
                            ? this.state.companyId
                            : null),
                    name: this.state.name,
                    email: this.state.email,
                    admin: this.state.admin
                }
            }).then(res => {
                if (res.data.success) {
                    alert("L'utilisateur a bien été modifié");
                    this.props.onClose();
                } else {
                    alert("Erreur lors de la sauvegarde");
                    this.props.onClose();
                }
            });
        } else {
            this.setState({ isError: true });
        }
      }

    render () {
        return (
            <Modal
                title={this.props.isEdit ? `Modifier ${this.props.name}` : "Ajouter un utilisateur"}
                show={this.props.show}
                onClose={this.props.onClose}
            >
                {this.context.companyId === null ? (
                    <FormSelect
                        className="mb-20"
                        name="companyId"
                        value={this.state.companyId}
                        options={this.state.companiesSelect} 
                        title="Compagnie"
                        onChange={this.handleInputChange}
                        ignore={true}
                    />
                ) : null}
                <FormInput
                    className="mb-20"
                    type="text"
                    name="name"
                    value={this.state.name}
                    title="Nom"
                    onChange={this.handleInputChange}
                    error={this.state.isError === true && !this.state.name ? "Veuillez remplir ce champ" : ""}
                />
                <FormInput
                    className="mb-20"
                    type="email"
                    name="email"
                    value={this.state.email}
                    title="Adresse mail"
                    onChange={this.handleInputChange}
                    error={this.state.isError === true && !this.state.email ? "Veuillez remplir ce champ" : ""}
                />
                <FormCheckbox
                    className="mb-20"
                    name="admin"
                    checked={this.state.admin}
                    text="Administrateur"
                    onChange={this.handleCheckboxChange}
                />
                <Button
                    className="mb-4"
                    text={this.props.isEdit ? "Modifier" : "Ajouter"}
                    onClick={this.props.isEdit ? this.handleEditUserClick : this.handleAddUserClick}
                />
          </Modal>
        )
    }
}