import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Status, Table, Tbody, Td, Th, Thead, Tr } from "ui-kit-ck-consultant";

import { months } from "../../../utils/general";

export class TableReparable extends Component {
  getLabels = () => {
    return [
      {
        id: "nbFolders",
        name: "Nombre de dossiers",
      },
      {
        id: "ageVehicleGlobal",
        name: "Âge véhicule (m)",
      },
      {
        id: "hourlyRateGlobal",
        name: "Tarif horaire (€ HT)",
      },
      {
        id: "missionVisitGlobal",
        name: "Délai mission-visite (j)",
      },
      {
        id: "visitDepotGlobal",
        name: "Délai visite-dépôt (j)",
      },
      {
        id: "missionDepotGlobal",
        name: "Délai mission-dépôt (j)",
      },
      {
        id: "piecesCostGlobal",
        name: "Coût pièces (€ HT)",
      },
      {
        id: "totalHourGlobal",
        name: "Nombre heures (h)",
      },
      {
        id: "totalHourPaintGlobal",
        name: "Nombre heures peinture (h)",
      },
      {
        id: "averageCost",
        name: "Coût réparation (€ HT)",
      },
      {
        id: "cost750Global",
        name: "Dossiers (Coût réparation 0/750 € HT) (%)",
      },
      {
        id: "cost1500Global",
        name: "Dossiers (Coût réparation 750/1500 € HT) (%)",
      },
      {
        id: "cost2300Global",
        name: "Dossiers (Coût réparation 1500/2300 € HT) (%)",
      },
      {
        id: "cost4600Global",
        name: "Dossiers (Coût réparation 2300/4600 € HT) (%)",
      },
      {
        id: "cost4600PlusGlobal",
        name: "Dossiers (Coût réparation >= 4600 € HT) (%)",
      },
      {
        id: "RP_RATE",
        name: "RP (%)",
      },
      {
        id: "PRE_RATE",
        name: "PRE (%)",
      },
      {
        id: "totalEAD",
        name: "Taux RDD (%)",
      },
      {
        id: "averageCostEAD",
        name: "Coût moyen RDD (€)",
      },
      {
        id: "passageTerrainRate",
        name: "Taux Passage Terrain (%)",
      },
      {
        id: "VGERate",
        name: "Taux VGE (%)",
      },
      {
        id: "partRate",
        name: "Taux de pièces réparées",
      },
      {
        id: "percentageR",
        name: "Taux de réparation (%)",
      },
      {
        id: "percentageRP",
        name: "Taux de réparation pièces plastiques (%)",
      },
      {
        id: "percentageWR",
        name: "Taux de réparation des jantes (%)",
      },
      {
        id: "hourT1Global",
        name: "Heure T1",
      },
      {
        id: "hourT2Global",
        name: "Heure T2",
      },
    ];
  };

  getEvolution = (data1, data2, name) => {
    const inversedColorList = [
      "Tarif horaire (€ HT)",
      "Délai mission-visite (j)",
      "Délai visite-dépôt (j)",
      "Délai mission-dépôt (j)",
      "Coût pièces (€ HT)",
      "Nombre heures (h)",
      "Nombre heures peinture (h)",
      "Coût réparation (€ HT)",
      "Dossiers (Coût réparation 1500/2300 € HT) (%)",
      "Dossiers (Coût réparation 2300/4600 € HT) (%)",
      "Dossiers (Coût réparation >= 4600 € HT) (%)",
      "Taux Passage Terrain (%)",
      "Taux VGE (%)",
      "Coût moyen EAD/SELFCARE (€)",
      "Heure T1",
      "Heure T2",
    ];

    if (data1 && data2) {
      let value = Math.round((data2 / data1) * 100 - 100);
      if (inversedColorList.includes(name)) {
        if (value > 0) {
          return (
            <Status
              className="bg-red-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else if (value < 0) {
          return (
            <Status
              className="bg-green-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else {
          return <Status className="bg-blue-light black w-100" text="0 %" />;
        }
      } else {
        if (value > 0) {
          return (
            <Status
              className="bg-green-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else if (value < 0) {
          return (
            <Status
              className="bg-red-light black w-100 nowrap"
              text={`${value} %`}
            />
          );
        } else {
          return <Status className="bg-blue-light black w-100" text="0 %" />;
        }
      }
    } else {
      return <Status className="bg-blue-light black w-100" />;
    }
  };

  render() {
    return (
      <FadeIn className={"overflow-x-auto"}>
        {this.props.data ? (
          <Table>
            <Thead>
              <Tr>
                <Th hide></Th>
                <Th radiusTopLeft>N-1</Th>
                <Th>N</Th>
                <Th spaceRight>N/N-1</Th>
                {months.map((month, idx) => (
                  <Th key={idx} spaceLeft={idx === 0}>
                    {month}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {this.getLabels().map((row, idx) => {
                return (
                  <Tr key={idx}>
                    <Th radiusTopLeft={idx === 0}>{row.name}</Th>
                    <Td>
                      {this.props.data[0][row.id]
                        ? this.props.data[0][row.id]
                        : null}
                    </Td>
                    <Td>
                      {this.props.data[1][row.id]
                        ? this.props.data[1][row.id]
                        : null}
                    </Td>
                    <Td spaceRight>
                      {this.getEvolution(
                        this.props.data[0][row.id],
                        this.props.data[1][row.id],
                        row.name
                      )}
                    </Td>
                    <Td spaceLeft>
                      {this.props.data[2][row.id]
                        ? this.props.data[2][row.id]
                        : null}
                    </Td>
                    <Td>
                      {this.props.data[3] ? this.props.data[3][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[4] ? this.props.data[4][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[5] ? this.props.data[5][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[6] ? this.props.data[6][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[7] ? this.props.data[7][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[8] ? this.props.data[8][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[9] ? this.props.data[9][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[10] ? this.props.data[10][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[11] ? this.props.data[11][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[12] ? this.props.data[12][row.id] : null}
                    </Td>
                    <Td>
                      {this.props.data[13] ? this.props.data[13][row.id] : null}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : null}
      </FadeIn>
    );
  }
}

export default TableReparable;
