import React from "react";

import DataTable from "./DataTable";
import ToggledCard from "../../general/ToggledCard";

export default class MissionsCard extends React.Component {
    render() {
        return (
            <ToggledCard 
                title={this.props.tableType === 1 ? `${this.props.data.departmentZip} - ${this.props.data.department}` : this.props.data.title} 
                isOpen={this.props.isOpen}
            >                        
                {/* TABLE */}
                <DataTable 
                    data={this.props.data}
                    tableType={this.props.tableType}
                    onEditAgency={this.props.onEditAgency}
                    onEditGroup={this.props.onEditGroup}
                    onDeleteAgency={this.props.onDeleteAgency}
                />
            </ToggledCard>
        )
    }
}