import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";

import AuthContext from "./context/AuthContext";

import axiosConfig from "./utils/axiosConfig";

import ReactGA from "react-ga4";
ReactGA.initialize("G-39WWE523LZ");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      connected: null,
      id: null,
      companyId: null,
      name: null,
      admin: null,
      interfaceColor: null,
      access: {
        clickable: {
          glass: false,
          statistics: false,
          audit: false,
          anomaly: false,
          missions: false,
        },
        displayed: {
          glass: false,
          statistics: false,
          audit: false,
          anomaly: false,
          missions: false,
        },
      },
      picture: null,
    };
  }

  componentDidMount() {
    this.checkAuth();
    this.intervalToken = setInterval(() => {
      this.checkAuth();
    }, 30 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  checkAuth = () => {
    if (!document.location.href.includes("signout")) {
      axiosConfig("/auth/refresh-token").then((res) => {
        if (res.data.success === true) {
          const oldState = this.state;
          const newState = {
            isReady: true,
            connected: true,
            id: res.data.data.id,
            companyId: res.data.data.companyId,
            name: res.data.data.name,
            admin: res.data.data.admin,
            interfaceColor: res.data.data.interfaceColor,
            access: res.data.data.access,
            picture: res.data.data.picture,
          };
          if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
            this.setState(newState);
          }
        } else {
          const oldState = this.state;
          const newState = {
            isReady: true,
            connected: false,
            id: null,
            companyId: null,
            name: null,
            admin: null,
            interfaceColor: null,
            access: {
              clickable: {
                glass: false,
                statistics: false,
                audit: false,
                anomaly: false,
                missions: false,
              },
              displayed: {
                glass: false,
                statistics: false,
                audit: false,
                anomaly: false,
                missions: false,
              },
            },
            picture: null,
          };
          if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
            this.setState(newState);
          }
        }
      });
    } else {
      this.setState({ connected: false });
    }
  };

  render() {
    if (!this.state.isReady) {
      return <></>;
    }

    return (
      <AuthContext.Provider
        value={{
          id: this.state.id,
          companyId: this.state.companyId,
          name: this.state.name,
          admin: this.state.admin,
          picture: this.state.picture,
          access: this.state.access,
          interfaceColor: this.state.interfaceColor,
        }}
      >
        <style>
          {`
          *{
            ${
              this.state.interfaceColor
                ? `--primary-color:${this.state.interfaceColor.primary} !important;`
                : ""
            }
            ${
              this.state.interfaceColor
                ? `--secondary-color:${this.state.interfaceColor.secondary} !important;`
                : ""
            }
          }
          `}
        </style>
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => {
                    return (
                      <route.layout
                        {...props}
                        connected={this.state.connected}
                        token={this.state.token}
                        companyId={this.state.companyId}
                        admin={this.state.admin}
                        access={this.state.access}
                      >
                        <route.component {...props} />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </Switch>
        </Router>
      </AuthContext.Provider>
    );
  }
}
