import React from "react";

import { initialMap } from "./mapSvgData";

import "./map-svg.css";

export default class MapSvg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: []
        };
    }

    componentDidMount() {
        this.buildMap();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data 
            || prevProps.groupToDisplay !== this.props.groupToDisplay) {
                this.buildMap();
        }
    }

    buildMap = () => {
        let newMap = {};

        initialMap.forEach(zone => {
            newMap = {...newMap,
                [zone.department]: {
                    path: zone.path,
                    circles: zone.circles,
                    departmentName: "",
                    groups: [],
                    disabled: true
                }
            }
        });

        this.props.data.forEach(zone => {
            if (!this.props.groupToDisplay) {
                newMap[zone.departmentZip] = {
                    ...newMap[zone.departmentZip],
                    departmentName: zone.department,
                    groups: [...zone.data],
                    disabled: false
                }
            } else {
                let newGroups = zone.data.filter(group => (
                    group.group === this.props.groupToDisplay
                ));
                newMap[zone.departmentZip] = {
                    ...newMap[zone.departmentZip],
                    departmentName: zone.department,
                    groups: newGroups,
                    disabled: newGroups.length > 0 ? false : true
                }
            } 
        });

        let mapTable = [];
        for (let departmentZip in newMap) {
            mapTable.push({...newMap[departmentZip], departmentZip: departmentZip});
        }
        this.setState({map: mapTable});
    }


    render() {  
        return (
            <svg
                style={{ maxWidth: "100%", maxHeight: "70vh", marginBottom: "30px" }}
                viewBox="0 0 702.227 684.61"
            >
                <g className="map">
                    {/* DEPARTMENTS */}
                    {this.state.map.map(zone => (
                        <g 
                            key={zone.departmentZip} 
                            id={zone.departmentZip}
                            onMouseOver={() => zone.disabled ? null : this.props.onZoneHovering(zone)}
                            onMouseOut={() => zone.disabled ? null : this.props.onZoneHovering(null)}
                        >
                            <path
                                className={`dep ${zone.disabled && "disabled"}`}
                                d={zone.path}
                            />
                            {zone.groups.map((group, idx) => (
                                idx < 11 ?
                                <circle 
                                    key={idx}
                                    className={`group ${group.data && group.data.length > 0 ? "" : "faded"}`}
                                    cx={zone.circles[idx].cx} 
                                    cy={zone.circles[idx].cy} 
                                    r="8"
                                    fill={group.groupColor}
                                    stroke={group.groupColor}
                                    onMouseOver={() => this.props.onAgencyHovering(group)}
                                    onMouseOut={() => this.props.onAgencyHovering(null)}
                                /> : null
                            ))}
                        </g>
                    ))}
                </g>
            </svg>
        );
    }
}